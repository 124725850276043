import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BottomErrMsg, LoadingBox, Popup } from "component/basic/popup";
import { Header, HeaderRightBtn, PageSizing } from "component/elements/header";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MypageSection } from "component/mypage/basic";
import { InputItemBox, TextAreaItem } from "component/basic/formItems";
import { TextItem } from "component/app/items";
import { SubLine } from "component/basic/tap";
import { phoneChk } from "js/function";

const ProfileEdit = (props) => {
    const navigate = useNavigate();

    const [popupData,setPopupData] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const [name,setName] = useState("");
    const [nickName,setNickName] = useState("");
    const [phone,setPhone] = useState("");
    const [addr,setAddr] = useState("");
    const [email,setEmail] = useState("");

    const [phoneErr,setPhoneErr] =  useState(null);

    const [phoneCetified,setPhoneCetified] =  useState("");
    const [phoneCertifyErr,setPhoneCertifyErr] =  useState(null);
    const [phoneTimerCount,setPhoneTimerCount] =  useState("3:00");
    const [phoneCetifiedChk,setPhoneCetifiedChk] =  useState(false);
    const [phoneCetifiedComChk,setPhoneCetifiedComChk] =  useState(false);
    const [phoneCetifiedInputComChk,setPhoneCetifiedInputComChk] =  useState(false);

    const [nameExisting,setNameExisting] = useState("");
    const [nickNameExisting,setNickNameExisting] = useState("");
    const [phoneExisting,setPhoneExisting] = useState("");

    const [btnChk,setBtnChk] = useState(true);

    const [saveCount,setSaveCount] = useState(0);

    const myApi = useGet({
        url:`/mypage?saveCount=${saveCount}`,
        loginType:"login"
    });
    const myData = myApi?.data?.user_info;

    //휴대폰 인증
    const phoneSval = useRef(null);
    function phoneTimerStart(){
        //타이머
        setPhoneTimerCount("3:00");
        setPhoneCertifyErr("");
        
        if (phoneSval.current !== null) {
            clearInterval(phoneSval.current);
            phoneSval.current = null;
        }
        
        phoneTimerChk(2.99);

        function phoneTimerChk(duration) {
            let timerNumber = duration * 60;
            let m, s;
            phoneSval.current = setInterval(function() {
                m = parseInt(timerNumber / 60 % 60, 10);
                s = parseInt(timerNumber % 60, 10);
                s = s < 10 ? "0" + s : s;

                setPhoneTimerCount(m + ":" + s);

                if (--timerNumber < 0) {
                    timerNumber = 0;
                    clearInterval(phoneSval.current);
                    phoneSval.current = null;
                    setPhoneCertifyErr("The authentication time has expired.");

                    setPhoneCetifiedComChk(true);
                    setPhoneCetified("");
                }
            }, 1000);
        }
    }

    function phoneCetifiedStert(){
        setLoadingBoxOpen(true);

        let formData = new FormData();
        formData.append("phone", `+${phone.replace(/\+/g,"")}`);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/phone/certification`,
            success: (data) => {
                phoneTimerStart();
                setPhoneCetified("");
                setPhoneCetifiedChk(true);

                setLoadingBoxOpen(false);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }

                setLoadingBoxOpen(false);
            }
        })
    }

    function phoneCetifiedEnd(){
        setLoadingBoxOpen(true);

        let formData = new FormData();
        formData.append("phone", `+${phone.replace(/\+/g,"")}`);
        formData.append("code", phoneCetified);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/phone/verification`,
            success: (data) => {
                setPhoneCetifiedComChk(true);
                setPhoneCetifiedInputComChk(true);

                setPhoneTimerCount("3:00");
                setPhoneCertifyErr("");

                clearInterval(phoneSval.current);
                phoneSval.current = null;

                setLoadingBoxOpen(false);
            },
            err: (data) => {
                if(data.alert){
                    if(data.alert == "Please enter a different Mobile Phone Number."){
                        setPhoneCetified("");
                        setPhoneCetifiedChk(false);
                        setPhoneTimerCount("3:00");
                        setPhoneCertifyErr("");

                        clearInterval(phoneSval.current);
                        phoneSval.current = null;

                        setPopupData({
                            addClass:null,
                            title:"This Mobile Phone Number has already been registered.",
                            text:"Please enter a different Mobile Phone Number.",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"Confirm",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }else if(data.alert == "An account is already registered with the Mobile Phone Number you entered."){
                        setPopupData({
                            addClass:null,
                            title:"This Mobile Phone Number has already been registered.",
                            text:data.alert,
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"Confirm",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }else if(data.alert){
                        setPhoneCertifyErr(data.alert);
                    }
                }
                setLoadingBoxOpen(false);
            }
        })
    }

    //정보 변경
    function editProfile(){
        let formData = new FormData();
        formData.append("name", name);
        formData.append("nickname", nickName);
        formData.append("phone", `+${phone.replace(/\+/g,"")}`);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: "login",
            url: "/mypage/modify",
            success: (data) => {
                setBottomMsgData({
                    addClass : "",
                    text : "Your information has been updated.",
                    chk : bottomMsgData.chk + 1
                });
                setBtnChk(false);
                setSaveCount(saveCount + 1);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //버튼 유효성, 정보 변경체크
    function btnChkFunc(){
        if((phone.replace(/[^0-9]/gi,"") !== phoneExisting.replace(/[^0-9]/gi,"") || nickName !== nickNameExisting || name !== nameExisting) && phoneChk(phone) && nickName !== "" && name !== "" && (phone.replace(/[^0-9]/gi,"") !== phoneExisting.replace(/[^0-9]/gi,"") ? phoneCetifiedComChk : true)){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }

    //정보 세팅
    useEffect(() => {
        setName(myData ? myData?.name : "");
        setNickName(myData ? myData?.nickname : "");
        setPhone(myData ? myData?.phone : "");
        setAddr(myData ? `${myData?.address_detail||""}${myData?.address_detail ? ", " : ""}${myData?.address_subdistrict||""}${myData?.address_subdistrict ? ", " : ""}${myData?.address_district||""}${myData?.address_district ? ", " : ""}${myData?.address_city||""}${myData?.address_city ? ", " : ""}${myData?.address_province||""}` : "");
        setEmail(myData ? myData?.email : "");

        setNickNameExisting(myData ? myData?.nickname : "")
        setPhoneExisting(myData ? myData?.phone : "")
        setNameExisting(myData ? myData?.name : "")
        
        btnChkFunc()
    }, [myData]);

    useEffect(() => {
        if(phone !== "" || phone){
            if(phoneChk(phone)){
                setPhoneErr(null);
            }else{
                setPhoneErr(`Please enter '62' followed by your phone number, excluding the initial '0' (zero), and without "-"`);
            }
        }else{
            setPhoneErr(null);
        }
    }, [phone]);

    //버튼 유효성 체크
    useEffect(() => {
        btnChkFunc()
    }, [phone,nickName,name,phoneCetifiedComChk]);

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="My Information"
                centerTitle={true}
            >
                <HeaderRightBtn
                    addClass={btnChk ? "btnProfileEdit" : "btnProfileEdit active"}
                    disabled={btnChk}
                    func={() => {editProfile()}}
                >
                    {btnChk ? "Modify" : "Complete"}
                </HeaderRightBtn>
            </Header>
            <MypageSection>
                <div className="profileEditBox">
                    <TextItem title="Date of Birth" contents={myData?.birthday||"-"}/>
                    <TextItem title="Genetic Gender" contents={myData?.gender||"-"}/>
                </div>
                <SubLine addClass="pageFull" margin="20px 0"/>
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Name" 
                    placeholder="Name"
                    value={name}
                    max={255}
                    regexp={null}
                    func={(value)=>{setName(value)}}
                />
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Nickname"
                    placeholder=""
                    value={nickName}
                    max={14}
                    regexp={null}
                    func={(value)=>{setNickName(value)}}
                    caption="Enter Nickname (max 14 chars)"
                />

                <InputItemBox
                    inputType={null}
                    addClass="inputSubCaptionItem"
                    inputName="Mobile Phone Number"
                    placeholder="6201234567890"
                    value={phone}
                    readOnly={phoneCetifiedInputComChk}
                    max={16}
                    regexp={"number"}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    errMsg={phoneErr}
                    func={(value)=>{setPhone(value);setPhoneCetifiedChk(false);setPhoneCetifiedComChk(false);setPhoneCetified("")}}
                    btnAddClass={(phoneChk(phone) && phone.replace(/[^0-9]/gi,"") !== phoneExisting.replace(/[^0-9]/gi,"")) ? `pBg`: ""}
                    btnDisabled={!(phoneChk(phone) && phone.replace(/[^0-9]/gi,"") !== phoneExisting.replace(/[^0-9]/gi,""))}
                    clickEv={()=>{
                        if(phoneCetifiedComChk && phoneCetified !== ""){
                            setPhoneCetifiedChk(false);setPhoneCetifiedComChk(false);setPhoneCetifiedInputComChk(false);setPhoneCetified("");
                        }else{
                            phoneCetifiedStert();setPhoneCetifiedComChk(false);setPhoneCetified("");
                        }
                    }}
                    btnName={`${phoneCetifiedComChk && phoneCetified !== "" ? "Change" : phoneCetifiedChk ? "Resend" : "Verify"}`}
                >
                    <p className="inputSubCaption_text">+</p>
                </InputItemBox>
                {phoneCetifiedChk ? 
                    <InputItemBox
                        inputType={null}
                        addClass="cetifiedInput"
                        inputName="Verify"
                        placeholder=""
                        value={phoneCetified}
                        max={6}
                        readOnly={phoneCetifiedComChk}
                        cetified={phoneTimerCount}
                        regexp={"number"}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        func={(value)=>{setPhoneCetified(value)}}
                        btnAddClass={`${phoneCetified.length == 6 && !phoneCetifiedComChk ? "pBg" : ""}`}
                        btnDisabled={phoneCetifiedComChk || phoneCetified.length !== 6}
                        clickEv={()=>{phoneCetifiedEnd()}}
                        btnName={phoneCetifiedComChk ? "Verified" : "Verify"}
                        errMsg={phoneCertifyErr}
                        caption="Enter Verification Code."
                    />
                :""}

                <InputItemBox
                    inputType={null}
                    addClass={myData?.join_type == "basic" ? "notReadOnlyColor" : `profileTypeInput profileType_${myData?.join_type}`}
                    inputName="Email Address"
                    placeholder="example@email.com"
                    value={email}
                    max={null}
                    regexp={null}
                    readOnly={true}
                    func={(value)=>{setEmail(value)}}
                    btnAddClass={""}
                    btnDisabled={false}
                    clickInput={(e)=>{if(myData?.join_type == "basic")navigate("/mypage/emailEdit")}}
                    clickEv={()=>{if(myData?.join_type == "basic")navigate("/mypage/emailEdit")}}
                    btnName={myData?.join_type == "basic" ? "Verified" : null}
                />
                <TextAreaItem
                    addClass="minSize"
                    inputName="Default Shipping Address"
                    placeholder="Default Shipping Address"
                    max={null}
                    maxChk={false}
                    value={addr}
                    func={(e)=>setAddr(e)}
                    readOnly={true}
                    clickInput={(e)=>{navigate("/mypage/addr")}}
                    btnAddClass="pBg"
                    btnDisabled={false}
                    clickEv={()=>{navigate("/mypage/addr")}}
                    btnName="Search"
                />
            </MypageSection>
            <BottomErrMsg
                addClass={bottomMsgData.addClass ? bottomMsgData.addClass : ""}
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default ProfileEdit;