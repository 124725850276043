import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { PageErrBox } from "component/app/pageErr";
import { BtnBox, BtnItem } from "component/basic/btns";
import { LoadingBox, Popup } from "component/basic/popup";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const OrderSuccess = () => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;

    const [popupData,setPopupData] =  useState(null);
    
    //결제 성공 시
    useEffect(() => {
        let formData = new FormData();
            
        formData.append("id", id);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/order`,
            loginType: "login",
            success: (data) => {
                let orderData = data?.data?.order_info;

                if(!localStorage.getItem("token") && orderData && orderData?.is_default_address == 1){
                    localStorage.setItem("orderData",JSON.stringify({
                        address_name:orderData?.address_name||"",
                        address_phone:orderData?.address_phone||"",
                        province_id:orderData?.address_province_id||"",
                        address_province:orderData?.address_province||"",
                        address_detail:orderData?.address_detail||"",
                        city_id:orderData?.address_city_id||"",
                        address_city:orderData?.address_city||"",
                        district_id:orderData?.address_district_id||"",
                        address_district:orderData?.address_district||"",
                        sub_district_id:orderData?.address_subdistrict_id||"",
                        address_subdistrict:orderData?.address_subdistrict||"",
                        tariff_code:orderData?.tariff_code||""
                    }))
                }
                
                navigate(`/order/success/${data?.data?.order_id}`);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:false,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{
                            navigate("/")
                        },
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }, []);

    return (
        <div className="pageSizing">
            <PageErrBox
                icon={true}
                iconUrl="/assets/images/icon/chkOut.svg"
                title="Payment is in progress."
                text={null}
            />
            <Popup
                data={popupData}
            />
        </div>
    );
};

export default OrderSuccess;