import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { basicImgSet, nightTimeChk } from "js/function";
import { Popup } from "component/basic/popup";
import { PageSection } from "component/app/items";
import { BtnBox, BtnItem } from "component/basic/btns";
import { InputItemBox } from "component/basic/formItems";
import { MemberInputSection, MemberPage } from "component/member/items";

const BabyEdit = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;
    
    const babyApi = useGet({
        url:`/family/baby/detail?barcode_id=${id}`,
        loginType:"login"
    });
    const babyData = babyApi?.data?.baby;

    const [nickname,setNickname] = useState("");
    const [btnChk,setBtnChk] = useState(true);
    const [popupData,setPopupData] =  useState(null);

    //닉네임 변경
    function editBaby(){
        let formData = new FormData();
        formData.append("id", id);
        formData.append("nickname", nickname);
        
        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: "login",
            url: `/family/baby/modify`,
            success: (data) => {
                navigate(-1);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //정보 세팅
    useEffect(() => {
        if(babyData){
            setNickname(babyData?.nickname ? babyData?.nickname : "");
        }
    }, [babyData]);

    //버튼 유효성
    useEffect(() => {
        if(nickname !== ""){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [nickname]);

    return (
        <PageSizing>
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="Modify Baby Nickname"
                centerTitle={true}
            />
            <PageSection>
                <MemberPage addClass="notTop">
                    <MemberInputSection>
                        <div className="babyProfileBox">
                            <div className="babyProfile bgImgItem" style={{backgroundImage:`url('${nightTimeChk() ? basicImgSet(babyData?.character_image_night||null) : basicImgSet(babyData?.character_image||null)}')`}}/>
                        </div>
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="Child's Nickname"
                            placeholder="Child's Nickname"
                            value={nickname}
                            max={null}
                            regexp={null}
                            func={(value)=>{setNickname(value)}}
                        />
                    </MemberInputSection>
                    <BtnBox
                        boxType="fixed"
                        addClass=""
                    >
                        <BtnItem
                            addClass=""
                            contents={"Complete"}
                            disabled={btnChk}
                            func={() => {editBaby()}}
                        />
                    </BtnBox>
                </MemberPage>
            </PageSection>
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default BabyEdit;