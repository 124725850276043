import * as fatchSet from "../api/api";

//핸드폰번호 포멧
function PhoneSetting(str) {
    str = str.replace(/[^0-9]/g, '');
    var tmp = '';
    if (str.length < 4) {
        return str;
    } else if (str.length < 7) {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3);
        return tmp;
    } else if (str.length < 11) {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 3);
        tmp += '-';
        tmp += str.substr(6);
        return tmp;
    } else {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 4);
        tmp += '-';
        tmp += str.substr(7);
        return tmp;
    }

    return str;
}

//년월일 입력 포멧
function birthSetting(str) {
	str = str.replace(/[^0-9]/g, '');
	var tmp = '';
	if (str.length < 5) {
		return str.slice(0, 10);
	} else if (str.length < 7) {
		tmp += str.substr(0, 4);
		tmp += '-';
		tmp += str.substr(4);

		return tmp.slice(0, 10);
	} else {
		tmp += str.substr(0, 4);
		tmp += '-';
		tmp += str.substr(4, 2);
		tmp += '-';
		tmp += str.substr(6);

		return tmp.slice(0, 10);
	}
}

//날짜 표기형식 세팅
function setDateTime(item,type,unit){
	let setDate = "";
	if(item){
		let basicDate = String(item).indexOf("-") > 0 || String(item).indexOf(".") > 0 ? String(item).replace(/-\./g,"/") : item;
		let date = new Date(basicDate),
			yy,
			mm,
			dd,
			th,
			tm,
			ts;

		yy = date.getFullYear();
		mm = date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
		dd = date.getDate() <= 9 ? "0" + date.getDate() : date.getDate();
		th = date.getHours() <= 9 ? "0" + date.getHours() : date.getHours();
		tm = date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes();
		ts = date.getSeconds() <= 9 ? "0" + date.getSeconds() : date.getSeconds();

		if(type == "yymmdd"){
			if(unit == "ymd"){
				setDate = yy + "Year " + mm + "Month " + dd + "Day";
			}else if(unit == "hymd"){
				setDate = yy + "-" + mm + "-" + dd;
			}else {
				setDate = yy + "." + mm + "." + dd;
			}
		}else if(type == "yymmddhhtt"){
			if(unit == "ymd"){
				setDate = yy + "Year " + mm + "Month " + dd + "Day " + th + ":" + tm;
			}else if(unit == "hymd"){
				setDate = yy + "-" + mm + "-" + dd + " " + th + ":" + tm;
			}else if(unit == "jakarta"){
				setDate = dd + "/" + mm + "/" + yy + "/" + th + ":" + tm;
			}else{
				setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm;
			}
		}else if(type == "mmdd"){
			if(unit == "ymd"){
				setDate = mm + "Month " + dd + "Day";
			}else if(unit == "hymd"){
				setDate = mm + "-" + dd;
			}else if(unit == "slash"){
				setDate = mm + "/" + dd;
			}else{
				setDate = mm + "." + dd;
			}
		}else if(type == "yymmddhhttss"){
			if(unit == "ymd"){
				setDate = yy + "Year " + mm + "Month " + dd + "Day " + th + ":" + tm + ":" + ts;
			}else if(unit == "hymd"){
				setDate = yy + "-" + mm + "-" + dd + " " + th + ":" + tm + ":" + ts;
			}else{
				setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm + ":" + ts;
			}
		}else if(type == "hhttss"){
			setDate = th + ":" + tm + ":" + ts;
		}else if(type == "hhtt"){
			setDate = th + ":" + tm;
		}else{
			setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm + ":" + ts;
		}
	}
	return setDate;
}

//날짜 요일 세팅
function setDateWeek(item){
    const weekArr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
	if(item){
		let basicDate = String(item).indexOf("-") > 0 || String(item).indexOf(".") > 0 ? String(item).replace(/-\./g,"/") : item;
		let date = new Date(basicDate)
		let setWeek = weekArr[date.getDay()];

		return setWeek;
	}else{
		return "";
	}
}

//날짜 차이
function getDateGap(start,end,type){
	if(start){
		let startDate = new Date(start.replace(/\.-/g,"/")),
			endDate = new Date(end.replace(/\.-/g,"/"));

		let dateGap = endDate.getTime() - startDate.getTime();
		let timeGap = new Date(0, 0, 0, 0, 0, 0, endDate - startDate);

		let setDay = Math.floor(dateGap / (1000 * 60 * 60 * 24));   
		let setH = timeGap.getHours();
		let setM  = timeGap.getMinutes();

		let setting = type == "day" ? setDay + 1 : `${setDay + 1}Day ${setH}Hour ${setM}Minute`;

		return setting;
	}else{
		return 8;
	}
}

function getMonthGap(start, end){
	const startDate = new Date(start.replace(/\.-/g,"/"));
	const endDate = end ? new Date(end.replace(/\.-/g,"/")) : new Date();
	
	const diffDate = startDate.getTime() - endDate.getTime();
	
	return Math.floor(Math.abs(diffDate / (1000 * 60 * 60 * 24 * 30)));
}

//나이 계산
function ageSetting(birth){
	let today = new Date();
	let birthDate = birth ? birth.length > 4 ? new Date(birth.replace(/\.-/g,"/")) : new Date(birth, 1, 1) : "";
	let age = birth ? today.getFullYear() - birthDate.getFullYear() + 1 : null;

	return age;
}

//url 파라미터
function getParam(sname) {
	var params = window.location.search.substr(window.location.search.indexOf("?") + 1);
	var sval = "";
	params = params.split("&");
	for (var i = 0; i < params.length; i++) {
		let temp = params[i].split("=");
		if ([temp[0]] == sname) {
			sval = temp[1];
		}
	}
	return sval;
}

function getParamText(sname,url) {
	var urlSet = new URL(url);
	var params = urlSet.search.substr(urlSet.search.indexOf("?") + 1);
	var sval = "";
	params = params.split("&");
	for (var i = 0; i < params.length; i++) {
		let temp = params[i].split("=");
		if ([temp[0]] == sname) {
			sval = temp[1];
		}
	}
	return sval;
}

//url 파라미터 구글 로그인용
function getParam_google(sname) {
	var params = window.location.search.substr(window.location.search.indexOf("?") + 1);
	var sval = "";
	params = window.location.href.split("#")[1].split("&");
	for (var i = 0; i < params.length; i++) {
		let temp = params[i].split("=");
		if ([temp[0]] == sname) {
			sval = temp[1];
		}
	}
	return sval;
}

//스크롤 하단 체크
function bottomChk(){
    let scrollTop = document.documentElement.scrollTop,
		innerHeight = window.innerHeight,
		scrollHeight = document.querySelectorAll("body")[0].scrollHeight;

    if (Math.ceil(scrollTop + innerHeight) + 1 >= scrollHeight) {
        return true;
    } else {
        return false;
    }
}

//토글 슬라이드
function slideUp(target, duration){
	target.style.transitionProperty = "height, margin, padding";
	target.style.transitionDuration = duration + "ms";
	target.style.boxSizing = "border-box";
	target.style.height = target.offsetHeight + "px";
	target.style.overflow = "hidden";
	window.setTimeout(() => {
		target.style.height = 0;
		target.style.paddingTop = 0;
		target.style.paddingBottom = 0;
		target.style.marginTop = 0;
		target.style.marginBottom = 0;
	}, 10);
	window.setTimeout(() => {
		target.style.display = "none";
		target.style.removeProperty("height");
		target.style.removeProperty("padding-top");
		target.style.removeProperty("padding-bottom");
		target.style.removeProperty("margin-top");
		target.style.removeProperty("margin-bottom");
		target.style.removeProperty("overflow");
		target.style.removeProperty("transition-duration");
		target.style.removeProperty("transition-property");
	}, duration);
}
function slideDown(target, duration, type){
	target.style.removeProperty("display");
	let display = window.getComputedStyle(target).display;

	if (display === "none") display = type == "flex" ? "flex" : "block";

	target.style.display = display;
	let height = target.offsetHeight;
	target.style.overflow = "hidden";
	target.style.height = 0;
	target.style.paddingTop = 0;
	target.style.paddingBottom = 0;
	target.style.marginTop = 0;
	target.style.marginBottom = 0;
	target.style.height = target.offsetHeight + "px";
	target.style.boxSizing = "border-box";
	target.style.transitionProperty = "height, margin, padding";
	target.style.transitionDuration = duration + "ms";
	target.style.height = height + "px";
	target.style.removeProperty("padding-top");
	target.style.removeProperty("padding-bottom");
	target.style.removeProperty("margin-top");
	target.style.removeProperty("margin-bottom");
	window.setTimeout(() => {
		target.style.removeProperty("height");
		target.style.removeProperty("overflow");
		target.style.removeProperty("transition-duration");
		target.style.removeProperty("transition-property");
	}, duration);
}
function slideToggle(target, type){
  if (window.getComputedStyle(target).display === "none") {
    return slideDown(target, 300, type);
  } else {
    return slideUp(target, 300);
  }
}

//스크롤 이동
function moveScroll(item,addTop){
	let scrollItem = document.querySelectorAll(item)[0].offsetTop;
	window.scrollTo({left: 0, top: addTop ? scrollItem - addTop : scrollItem, behavior: "smooth"});
}

//스크롤 제한(팝업용)
function bodyScroll_limit(){
	document.querySelectorAll("body")[0].classList.add("active");
}
function bodyScroll_clear(){
	document.querySelectorAll("body")[0].classList.remove("active");
}

//천단위 콤마 포멧
function comFormat(number){
	let data = Number(number);

	if (data == 0) return 0;
	var reg = /(^[+-]?\d+)(\d{3})/;
	var n = (data + '');
	while (reg.test(n)) n = n.replace(reg, '$1' + '.' + '$2');
	return n;
}

//dday 체크
function dDay(data){
	const now = new Date();
	const use_date = new Date(data.replace(/-/g,"/"));

	const setDate = use_date - now;

	const day = Math.floor(setDate / (1000 * 60 * 60 * 24));

	return day;
}

//평일,주말 체크
function getWeekCount(start,end) {
    let startDate = new Date(start.replace(/-/g,"/")),
     	endDate = new Date(end.replace(/-/g,"/"));

    let weekdayCount = 0,
		weekendCount = 0;

	while(true) {
		let setStartDate = startDate;				
		if(setStartDate.getTime() > endDate.getTime()) {
			return [weekdayCount,weekendCount];
		} else {
			let tmp = setStartDate.getDay();
			if(tmp == 0 || tmp == 6) {//주말에 금요일 제외 필요하면 tmp == 5 추가
				weekendCount++;
			} else {
				weekdayCount++;         
			}
			setStartDate.setDate(startDate.getDate() + 1); 
		}
	}
}

//스타일 추출
function getStyle(el, prop, type){
	let style;
    if(el.currentStyle){
        style = el.currentStyle[prop];
    }else if(window.getComputedStyle){
        style = type == "number" ? window.getComputedStyle(el,null)[prop].replace(/[a-zA-Z#?!@$ %^&*-]/gi,"") : window.getComputedStyle(el,null)[prop];
    }
    return style;
}

//이미지 없을 시 기본 이미지 리턴
function basicImgSet(img){
	if(img){
		return img;
	}else{
		return "/assets/images/img/basic_profile.svg";
	}
}

//케미 항목 아이콘 리턴
function chemi_cateIcon(name){
	let imgName = name == "Skin" ? "chemi_icon_0"
                : name == "Hair" ? "chemi_icon_1"
                : name == "Minerals" ? "chemi_icon_2"
                : name == "Bone health" ? "chemi_icon_3"
                : name == "Blood Circulation" ? "chemi_icon_4"
                : name == "Vitamins" ? "chemi_icon_5"
                : name == "Eye Health" ? "chemi_icon_6"
                : name == "Antioxidants" ? "chemi_icon_7"
                : name == "fatness" ? "chemi_icon_8"
                : name == "Lifestyle Habits" ? "chemi_icon_9"
                : name == "Exercise" ? "chemi_icon_10"
                : name == "Metabolic Capacity" ? "chemi_icon_11"
                : name == "Dependency" ? "chemi_icon_12"
                : name == "Sleep" ? "chemi_icon_13"
                : name == "Eating" ? "chemi_icon_14"
                : "chemi_icon_15";
	return imgName;
}

//리포트 상태 리턴
function stateType(text){
	let type = null;
	if(text == "Warning" || text == "Low"){
		type = 0;
	}else if(text == "Normal" || text == "Medium"){
		type = 1;
	}else if(text == "Safe" || text == "High"){
		type = 2;
	}else{
		type = null;
	}

	return type;
}

//현재시간
function nightTimeChk(){
	let nowDate = new Date();
	let nowTime = nowDate.getHours();

	if(nowTime >= 19 || nowTime <= 6){
		return true;
	}else{
		return false;
	}
}

//시간 선택 옵션 세팅
function timeArr(timeStart,timeEnd,timeUnit){
	let timeSet = Number(timeStart);
	let timeArr = [];

	do {
		let h = String(timeSet).substring(0,String(timeSet).length <= 3 ? 1 : 2),
			m = String(timeSet).substring(String(timeSet).length <= 3 ? 1 : 2,String(timeSet).length <= 3 ? 3 : 4);
		
		if(m >= 60){
			m = m - 60;
			h = Number(h) + 1;
		}

		if(Number(m) < 10){
			m = `0${Number(m)}`;
		}

		if(Number(h) < 10){
			h = `0${Number(h)}`;
		}

		timeArr.push(`${h}:${m}`);
		timeSet = Number(`${h}${m}`) + timeUnit;
	} while (timeSet <= Number(timeEnd));

	return timeArr;
}

//whatsapp 열기
function whatsappOpen(){
	let aTag = document.createElement("a");
    document.body.appendChild(aTag);
	aTag.setAttribute("href", "https://wa.me/62081190030367");
	aTag.setAttribute("target", "_blank");
    aTag.click();
    document.body.removeChild(aTag);
}

//배송비 파라미터
function productData_param(settingData,type){
	let settingText = "";
	settingData.forEach(function(item,i){
		let textSub = `&product_id[]=${type == "basic" ? item.id : item.product_id}${item.option_id ? `&option_id[]=${item.option_id}` : ""}&qty[]=${type == "basic" ? item.qty||1 : item.cart_qty||1}`;
		settingText = settingText + textSub;
	});

	return settingText;
}

//마우스 좌우 클릭 스크롤
function scrollx_mouse(item){
    let isMove = false;
	let isCover = "";
    let startPoint = "";

    item.addEventListener("mousedown",function(e){
		let cover = `<div class="scrollCover"></div>`;
		e.preventDefault();
		isMove = true;
		startPoint = e.pageX + item.scrollLeft;
		isCover = setTimeout(() => {
			item.insertAdjacentHTML("beforeend",cover);
		}, 100);
	});
	item.addEventListener("mousemove",function(e){
		if(isMove){
			const { scrollWidth, clientWidth, scrollLeft } = item;

			item.scrollLeft = startPoint - e.pageX;
			if(item.querySelector(".scrollCover")){
				item.querySelector(".scrollCover").style.left = `${item.scrollLeft}px`;
			}

			if(item.scrollLeft <= 0){
				startPoint = e.pageX;
			}else if(scrollWidth <= clientWidth + scrollLeft){
				startPoint = e.pageX + scrollLeft;
			}
		}
	});
	item.addEventListener("mouseup",function(e){
		isMove = false;
		clearTimeout(isCover);
		item.querySelector(".scrollCover") && item.querySelector(".scrollCover").remove();
	});
	item.addEventListener("mouseleave",function(e){
		isMove = false;
		clearTimeout(isCover);
		item.querySelector(".scrollCover") && item.querySelector(".scrollCover").remove();
	});
}

//연락처 유효성
function phoneChk(number){
	let numberSet = false;
	if(number){
		if((number.substring(0,2) == 62 || number.substring(0,3) == "+62" || number.substring(0,2) == 82 || number.substring(0,3) == "+82") && number.length >= 10){
			numberSet = true;
		}else{
			numberSet = false;
		}
	}

	return numberSet;
}

export {PhoneSetting, birthSetting, bottomChk, slideUp, slideDown, slideToggle, ageSetting, getParam, getParamText, getParam_google, comFormat, dDay, setDateTime, moveScroll, getDateGap, getMonthGap, setDateWeek, getStyle, getWeekCount, bodyScroll_limit, bodyScroll_clear, basicImgSet, chemi_cateIcon,stateType,nightTimeChk,timeArr,whatsappOpen,productData_param, scrollx_mouse, phoneChk};