import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlarmItem } from "component/mypage/alarm";
import { NotItems } from "component/basic/notItems";
import { BgSection } from "component/app/items";

const Alarm = (props) => {
    const navigate = useNavigate();

    const alarmApi = useGet({
        url:"/mypage/alarm",
        loginType:"login"
    });
    const alarmData = alarmApi?.data?.alarms;

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="Notifications"
                centerTitle={true}
            />
            <BgSection>
                {
                alarmData && alarmData?.length > 0 ?
                    alarmData.map((item,i)=>(
                        <AlarmItem
                            data={item}
                            key={i}
                        />
                    ))
                : <NotItems
                    addClass="pageType"
                    img="/assets/images/alarm/notItem.svg"
                    text="No notifications received."
                    />
                }
            </BgSection>
        </PageSizing>
    );
};

export default Alarm;