import React, { useState } from "react";
import { useEffect } from "react";
import * as fatchSet from "../../api/api";
import { useLocation, useNavigate, useNavigationType, useParams } from "react-router-dom";
import { getParam, getParam_google } from "js/function";
import { LoadingBox, Popup } from "component/basic/popup";

const Social = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const type = param.type;

    const [loadingType,setLoadingType] =  useState(true);
    const [popupData,setPopupData] =  useState(null);

    //소셜 로그인
    const LoginNext = () => {
        let formData = new FormData();
        if(type == "google"){
            formData.append("token", getParam_google("access_token"));
        }
        if(type == "apple"){
            formData.append("code", getParam("code"));
        }
        if(type == "facebook"){
            formData.append("token", getParam_google("access_token"));
        }

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/${type}/login`,
            success: (data) => {
                if(data.data.type == "login"){
                    localStorage.setItem("token",data.data.token.access_token);
                    localStorage.setItem("refresh_token",data.data.token.refresh_token);

                    props.logIn();

                    if(sessionStorage.getItem("setUrl")){
                        let reUrl = sessionStorage.getItem("setUrl");
                        sessionStorage.removeItem("setUrl");
                        navigate(reUrl);
                    }else{
                        navigate(`/main`);
                    }
                }else{
                    sessionStorage.setItem("token",data.data.token.access_token);
                    sessionStorage.setItem("refresh_token",data.data.token.refresh_token);

                    navigate(`/member/terms/${type == "apple" ? "apple" : "sns"}`);
                }
            },
            err: (data) => {
                if(data.alert||data.data){
                    setLoadingType(false);
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert||"",
                        closeType:false,
                        closeFunc:(e) => {navigate(-1)},
                        btnFunc0:(e)=>{navigate(-1)},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    useEffect(() => {
        let getToken = type == "google" ? getParam_google("access_token")
        : type == "apple" ? getParam("code")
        : type == "facebook" ? getParam_google("access_token") : null;

        if(getToken){
            if(window.sessionStorage.getItem("tokenChk")){
                if(window.sessionStorage.getItem("tokenChk") == getToken){
                    window.sessionStorage.removeItem("tokenChk")
                    navigate(-2);
                }else{
                    LoginNext();
                }
            }else{
                window.sessionStorage.setItem("tokenChk", getToken);
                LoginNext();
            }
        }else{
            navigate(-2);
        }
    }, []);

    return (
        <div className="pageSizing">
            <LoadingBox openType={loadingType}/>
            <Popup
                data={popupData}
            />
        </div>
    );
};

export default Social;