import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CardItem, ReportDetailSubTitleBox, ReportDetailtextBox } from "component/report/items";
import html2canvas from "html2canvas";

const CardDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;
    const type = param.type;
    const barcode = param.barcode == null ? "" : param.barcode;
    
    const cardApi = useGet({
        url:`/report/card?barcode_id=${barcode}&card_id=${id}&type=${type}`,
        loginType: localStorage.getItem("token") ? "login" : null
    });
    const cardData = cardApi?.data?.card_info;
    const otherCardData = cardApi?.data?.other_card;

    //카드 저장
    function imageSave(url,name){
        let aTag = document.createElement("a");
        document.body.appendChild(aTag);
        aTag.href = url;
        aTag.download = name;
        aTag.click();
        document.body.removeChild(aTag);
    }

    function capture(){
        html2canvas(document.querySelectorAll(".captureBox")[0],{allowTaint: true,useCORS: true}).then(canvas=>{
            imageSave(canvas.toDataURL("image/png"),`my_card_image_${Math.floor(Math.random() * 101)}.png`)
        })
    }

    useEffect(() => {
        
    }, []);

    return (
        <PageSizing addClass="">
            <Header
                addClass="notBg"
                pageClose={true}
                closeFunc={() => {navigate("/report")}}
                headTitle=""
                centerTitle={true}
            />
            <div className="cardImg_section">
                <div className="cardImg bgImgItem" style={{backgroundImage:`url('${cardData && cardData[`${type}_card_image`]}')`}}>
                    <button type="button" className="card_capture" onClick={()=>{capture()}}>
                        <img src="/assets/images/basic/download.svg"/>
                    </button>
                </div>
            </div>
            <div className="cardInfo_section">
                <h1 className="cardInfo_name">{cardData && cardData[`${type}_card_name`]}</h1>
                <p className="cardInfo_text notranslate">{cardData ? props.language == "en" ? cardData[`${type}_card_explanation`] : cardData[`${type}_card_explanation_id`] : ""}</p>
                <ReportDetailtextBox text={cardData ? props.language == "en" ? cardData[`${type}_card_detail`] : cardData[`${type}_card_detail_id`] : ""}/>
            </div>
            {/* 이미지 저장용 */}
            <div className="captureBox cardCaptureBox">
                <img src={cardData && cardData[`${type}_card_image`]}/>
            </div>
            {/* 이미지 저장용 끝 */}
            {otherCardData && otherCardData.length > 0 ? 
            <div>
            <ReportDetailSubTitleBox title={`See More ${type == "best" ? "Best" : "Worst"} Power Card`} />
            <div className="otherCard">
                {otherCardData?.map((item,i)=>(
                    <CardItem
                        key={i}
                        img={item[`${type}_card_image`]}
                        text={item[`${type}_card_name`]}
                        func={()=>{navigate(`/report/cardDetail/${barcode}/${item.id}/${type}`)}}
                    />
                ))}
            </div>
            </div>
            :""}
        </PageSizing>
    );
};

export default CardDetail;