import { CustomSelect } from "component/basic/formItems";
import { getParam } from "js/function";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//footer
function Footer(data){
    let navigate = useNavigate();

    const [setChk,setSetChk] = useState(false);

    const languageList = [
        {
          id:"en",
          language: "English"
        },
        {
          id:"id",
          language: "Bahasa Indonesia"
        }
    ];

    useEffect(()=>{
        setSetChk(!setChk);
        
        if(getParam("terms") == "privacy"){
            data.popupOpen(2,"Privacy Policy")
        }else if(getParam("terms") == "service"){
            data.popupOpen(1,"Terms of Service")
        }
    },[])

    return (
        <div className="footer">
            <div className="footerSection">
                <img src="/assets/images/img/logo_g.svg"/>
                <p className="footerText notranslate"><i>Company Name : GENOSCAPE PTE. LTD.</i><br/>
                <i>Company Address : 38 BEACH ROAD #17-12, SOUTH 
                BEACH TOWER, SINGAPORE</i><br/>
                <i>Contact : (Email) </i><a href="mailto:hello@genoscape.io">hello@genoscape.io</a></p>
                <CustomSelect
                    inputName="Country and Language Settings"
                    placeholder="Country and Language Settings"
                    value={data.language}
                    notLanguageCh={true}
                    func={(e) => {data.languageCh(e)}}
                    valKey="id"
                    nameKey="language"
                    setChk={setChk}
                    options={languageList}
                    boxAddClass={data.language}
                />
                <div className="footerArea">
                    <div className="footerSnsBox">
                        <a href="https://sg.linkedin.com/company/genoscape" target="_blank" className="footerSnsItem">
                            <img src="/assets/images/sns/footer_sns_0.svg"/>
                        </a>
                        <a href="https://www.instagram.com/babydna.official/" target="_blank" className="footerSnsItem">
                            <img src="/assets/images/sns/footer_sns_1.svg"/>
                        </a>
                    </div>
                    <div className="footerMenuBox">
                        <button type="button" className="footerMenuItem notranslate" onClick={()=>{data.popupOpen(1,"Terms of Service")}}>Terms of Service</button>
                        <span></span>
                        <button type="button" className="footerMenuItem notranslate" onClick={()=>{data.popupOpen(2,"Privacy Policy")}}>Privacy Policy</button>
                        <span></span>
                        <button type="button" className="footerMenuItem notranslate" onClick={()=>{data.popupOpen(4,"Cookie Policy")}}>Cookie Policy</button>
                    </div>
                    <button type="button"  className="footer_copyrightTitle notranslate" onClick={()=>navigate("/collaboration")}>Collaboration Inquiry</button>
                    <p className="footer_copyright notranslate">COPYRIGHT © GENOSCAPE PTE. LTD. All rights reserved</p>
                </div>
            </div>
        </div>
    );
}
export {Footer}