import { BtnItem, MoreBtnItem } from "component/basic/btns";
import { chemi_cateIcon, getMonthGap, logout, scrollx_mouse, setDateTime, stateType } from "js/function";
import { useRef } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

//리포트 section
function ReportInfoSection(data){
    return (
        <div className="reportInfoSection" style={{backgroundColor:data.color}}>
            {data.children}
        </div>
    );
}

//리포트 타이틀
function ReportTitleBox(data){
    return (
        <div className="reportTitleBox">
            <h1 className="reportTitle">{data.title}</h1>
        </div>
    );
}

//리포트 가족 목록
function FamilyList(data){
    return (
        <button type="button" className={`familyItem ${data.val == data.id ? "sel" : ""} ${data.activeChk ? "active" : ""}`} onClick={()=>{data.func()}}>
            <div className="familyItem_img" style={{backgroundImage:`url('${data.img}')`}}>
                {data.caption ? <img src={data.caption}/> : ""}
            </div>
            <p className={`familyItem_name ${data.notranslate ? "notranslate" : ""} ${data.nameType ? data.nameType : ""}`} dangerouslySetInnerHTML={{__html:data.name}}/>
        </button>
    );
}

//리포트 상단 상세 정보 퍼센트
function ReportPercentItem(data){
    return (
        <div className={`reportPercentItemBox ${data.gradeType == 0 ? "rColor" : data.gradeType == 1 ? "yColor" : data.gradeType == 2 ? "gColor" : ""}`}>
            <div className="reportPercentItem_info">
                <p className="reportPercentItem_name notranslate">{data.name}</p>
                <p className="reportItemCaption reportPercentItem_caption notranslate"><span>{data.grade}</span></p>
            </div>
            <div className="reportPercentItem_bg">
                <span className="reportPercentItem" style={{width:`${data.percent}%`}}></span>
            </div>
        </div>
    );
}

//리포트 상단 상세 정보
function UserReport(data){
    const [reportOpen,setReportOpen] = useState(false);

    return (
        <div className={`userReportArea ${data.addClass ? data.addClass : ""}`}>
            <div className="userReport_titleBox">
                {data.sampleType ? 
                <p className="userReport_sampleName">Preview</p>
                : <h1 className="userReport_name notranslate" dangerouslySetInnerHTML={{__html:data.name}}/>}
                {data.downloadType || data.sampleType ? 
                "" : 
                <button type="button" className="btn_download" onClick={()=>{data.download()}}>
                    <img src="/assets/images/basic/download.svg"/>
                    <i>Save Character</i>
                </button>
                }
            </div>
            <div className="userReport_img">
                <img src={data.img}/>
            </div>
            <div className={`userReportList ${data.downloadType || reportOpen ? "open" : ""}`}>
                {data.characterData && data.characterData.length > 0 ? 
                    <div>
                        {data.characterData?.map((item,i)=>(
                            <div key={i} className="reportPercentItemArea">
                            {data.downloadType || reportOpen || (!reportOpen && i <= 2) ? 
                                <ReportPercentItem
                                    gradeType={item.gradeType}
                                    name={data.language == "en" ? item.name : item.name_id}
                                    grade={data.language == "en" ? item.grade : item.grade_id}
                                    percent={item.percent}
                                />
                            :""}
                            </div>
                        ))}
                    </div>
                :""}
                {data.downloadType ? 
                    "" : 
                    <MoreBtnItem
                        addClass={reportOpen ? "bColor on" : "bColor"}
                        margin="16px auto 0"
                        img="/assets/images/basic/more_icon_b.svg"
                        contents={reportOpen ? "Fold" : "See More"}
                        func={()=>{setReportOpen(!reportOpen)}}
                    />
                }
            </div>
        </div>
    );
}

//리포트 card
function CardSection(data){
    return (
        <div className="cardSection">
            {data.children}
        </div>
    );
}

function CardList(data){
    const scrollItem = useRef(null);

    useEffect(()=>{
        scrollx_mouse(scrollItem.current)
    },[])
    
    return (
        <div className="cardList scrollCoverBox" ref={scrollItem}>
            {data.children}
        </div>
    );
}

function CardItem(data){
    return (
        <button type="button" className={`cardItem ${data.addClass ? data.addClass : ""}`} onClick={()=>{data.func()}}>
            <div className="cardItem_img" style={{backgroundImage:`url('${data.img}')`}}/>
            <p className="cardItem_text">{data.text}</p>
        </button>
    );
}

//리포트 상세 항목
function ReportDataList(data){
    return (
        <div className="reportDataList">
            {data.children}
        </div>
    );
}

function ReportDataListItem(data){
    return (
        <button type="button" className={`reportDataListItem ${data.gradeType == 0 ? "rColor" : data.gradeType == 1 ? "yColor" : data.gradeType == 2 ? "gColor" : "normalColor"}`} onClick={()=>{data.func()}}>
            <div className="reportDataListItem_info">
                <img src={`/assets/images/report/cate_icon_${
                    data.type == "Exercise" ? 0 :
                    data.type == "Health management" ? 1 :
                    data.type == "Hair/Skin" ? 2 :
                    data.type == "Dietary habits" ? 3 :
                    data.type == "Characteristic" ? 4 :
                    data.type == "Nutrient" ? 5 :
                    ""
                }.svg`}/>
                <h1 className="reportDataListItem_name notranslate">{data.language == "en" ? data.name : data.name_id}</h1>
            </div>
            <p className="reportItemCaption reportDataListItemCaption">
                <span>
                {data.grade == "Morning person" ? <img src="/assets/images/img/grade_day.svg"/> : data.grade == "Night person" ? <img src="/assets/images/img/grade_night.svg"/> : ""}<i className="notranslate">{data.language == "en" ? data.grade : data.grade_id}</i>
                </span>
            </p>
        </button>
    );
}

function ReportDataSection(data){
    return (
        <div className="reportDataSection">
            {data.children}
        </div>
    );
}

function ReportDetailSubTitleBox(data){
    return (
        <div className="reportDetailSubTitleBox">
            <h1 className="reportDetailSubTitle" dangerouslySetInnerHTML={{__html:data.title}}></h1>
        </div>
    );
}

function ReportDetailtextBox(data){
    return (
        <p className="reportDetailtext notranslate">{data.text}</p>
    );
}

function ReportDetailTitleBox(data){
    return (
        <p className="reportDetailTitle">{data.text}</p>
    );
}

function ReportDetailInfoBox(data){
    return (
        <div>
        <div className="reportDetailTypeTitleBox">
            <img src={`/assets/images/report/cate_icon_${
                data.type == "Exercise" ? 0 :
                data.type == "Health management" ? 1 :
                data.type == "Hair/Skin" ? 2 :
                data.type == "Dietary habits" ? 3 :
                data.type == "Characteristic" ? 4 :
                data.type == "Nutrient" ? 5 :
                ""
            }.svg`}/>
            <h1 className="reportDetailTypeTitle notranslate">{data.name}</h1>
        </div>
        <div className="reportDetailInfoBox">
            <div className="reportDetailInfo_imgBox">
                <img src="/assets/images/report/detailIcon.svg"/>
                {data.gradeType == 0 || data.gradeType == 1 || data.gradeType == 2 ? <img src={`/assets/images/report/detail_subIcon_${data.gradeType}.svg`} className="subIcon"/> : ""}
            </div>
            <p className="reportDetailInfoText">
                {data.gradeType == 0 ? 
                    <span>
                        <b className="notranslate reportDetailInfoTextName">{data.nickName}</b> is <br/>
                        <b className={`notranslate ${data.gradeType == 0 ? "rColor" : data.gradeType == 1 ? "yColor" : data.gradeType == 2 ? "gColor" : ""}`}> [{data.grade}]</b>
                        <b>, needs consistent management.</b>
                    </span>
                :""}
                {data.gradeType == 1 ? 
                    <span>
                        <b className="notranslate reportDetailInfoTextName">{data.nickName}</b> is <br/>
                        <b className={`notranslate ${data.gradeType == 0 ? "rColor" : data.gradeType == 1 ? "yColor" : data.gradeType == 2 ? "gColor" : ""}`}> [{data.grade}]</b>
                        <b>, so taking care of it is beneficial!</b>
                    </span>
                :""}
                {data.gradeType == 2 ? 
                    <span>
                        <b className="notranslate reportDetailInfoTextName">{data.nickName}</b> is <br/>
                        <b className={`notranslate ${data.gradeType == 0 ? "rColor" : data.gradeType == 1 ? "yColor" : data.gradeType == 2 ? "gColor" : ""}`}> [{data.grade}]</b>
                        <b>, but it can vary depending on the environment or habits!</b>
                    </span>
                :""}
                {data.gradeType !== 0 && data.gradeType !== 1 && data.gradeType !== 2 ? 
                    <span>
                        <b className="notranslate reportDetailInfoTextName">{data.nickName}</b> is 
                        <b className={`notranslate ${data.gradeType == 0 ? "rColor" : data.gradeType == 1 ? "yColor" : data.gradeType == 2 ? "gColor" : ""}`}> [{data.grade}]</b>
                        <b>!</b>
                    </span>
                :""}
            </p>
        </div>
        {data.tag && data.tag.length > 0 ?
        <div className="reportDetailTagBox">
            {data.tag.map((item,i)=>(
                item !== "" && item ? <p className="reportDetailTag notranslate" key={i}>{item}</p> : ""
            ))}
        </div>
        :""}
        </div>
    );
}

//케미 골라보기 항목
function ChemiItem(data){
    return (
        <button type="button" className="chemiItem" onClick={()=>{data.func()}}>
            <div className="chemiItemInfo">
                <div className="chemiItem_categoryBox">
                    <img src={`/assets/images/report/chemi_icon/${chemi_cateIcon(data.category_name)}.svg`}/>
                    <i className="notranslate">{data.language == "en" ? data.category_name : data.category_name_id}</i>
                </div>
                <p className="chemiItemInfo_text notranslate">{data.language == "en" ? data.cami : data.cami_id}</p>
            </div>
            <div className={`chemiItem_icon ${data.type == 1 ? "hi" : "lo"}`}>
                <img src={`/assets/images/report/${data.type == 1 ? "chemi_hi" : "chemi_lo"}.svg`}/>
                <span dangerouslySetInnerHTML={{__html:data.type == 1 ? "Matches<br/>well" : "Doesn't<br/>match"}}/>
            </div>
        </button>
    );
}

//케미 비교 대상 항목
function ChemiCharacterItem(data){
    return (
        <button type="button" className={`chemi_characterItem ${data.addClass}`} onClick={()=>{data.func()}}>
            <div className="chemi_characterImg bgImgItem" style={{backgroundImage:`url('${data.img}')`}}>
                <img src={data.icon}/>
            </div>
            <p className="chemi_characterName notranslate">{data.name}</p>
        </button>
    );
}

//케미 골라보기 탭
function ChemiListTap(data){
    return (
        <button type="button" className={`chemiListTap ${data.id == 1 ? "hi" : "lo"} ${data.val == data.id ? "active" : ""}`} onClick={()=>{data.func(data.id)}}>
            <img src={`/assets/images/report/${
                data.id == 1 && data.val == data.id ? "chemi_hi_on"
                : data.id == 1 ? "chemi_hi_off"
                : data.id == 0 && data.val == data.id ? "chemi_lo_on"
                : "chemi_lo_off"}.svg`}/>
            <span dangerouslySetInnerHTML={{__html:data.categoryName}}/>
        </button>
    );
}

//케미 결과 박스
function ChemiImgBox(data){
    return (
        <div className="chemi_imgBox">
            {data.children}
        </div>
    );
}

//케미 골라보기 상세 일치 여부
function ChemiDetailListItem(data){
    return (
        <button type="button" className={`chemiDetailListItem ${data.addClass ? data.addClass : ""}`} onClick={()=>{data.func()}}>
            <div className="chemiDetailListItem_cateBox">
                <h2 className="chemiDetailListItem_cate notranslate">{data.language == "en" ? data.name : data.name_id}</h2>
                <div className="chemiDetailListItem_cateIconBox">
                    <img src={`/assets/images/report/${data.my_grade == data.target_grade ? "chemi_hi" : "chemi_lo"}.svg`}/>
                    <span>{data.my_grade == data.target_grade ? "Match" : "Mismatch"}</span>
                </div>
            </div>
            <div className="chemiDetailListItem_typeBox">
                <div className={`chemiDetailListItem_typeItem ${stateType(data.my_grade) == 0 ? "rColor" : stateType(data.my_grade) == 1 ? "yColor" : stateType(data.my_grade) == 2 ? "gColor" : ""}`}>
                    <p className="chemiDetailListItem_nickName">Me</p>
                    <p className="chemiDetailListItem_type reportItemCaption notranslate"><span>{data.language == "en" ? data.my_grade : data.my_grade_id}</span></p>
                </div>
                <div className={`chemiDetailListItem_typeItem ${stateType(data.target_grade) == 0 ? "rColor" : stateType(data.target_grade) == 1 ? "yColor" : stateType(data.target_grade) == 2 ? "gColor" : ""}`}>
                    <p className="chemiDetailListItem_nickName">{data.nickName}</p>
                    <p className="chemiDetailListItem_type reportItemCaption notranslate"><span>{data.language == "en" ? data.target_grade : data.target_grade_id}</span></p>
                </div>
            </div>
        </button>
    );
}

function FamilyManagBox(data){
    return (
        <div className="familyManagArea">
            {data.children}
        </div>
    );
}

function FamilyManagTitleBox(data){
    return (
        <div>
            <div className="familyManag_titleBox">
                <h1 className="familyManag_title" dangerouslySetInnerHTML={{__html:data.title}}/>
                {data.btn ? <button type="button" className="familyManag_titleBtn" onClick={()=>{data.func()}} dangerouslySetInnerHTML={{__html:data.btn}}/>: ""}
            </div>
            {data.subText ? <p className="familyManag_titleCaption">{data.subText}</p> : ""}
        </div>
    );
}

function FamilyManagItemBox(data){
    return (
        <div className="familyManagItemBox">
            {data.children}
        </div>
    );
}

function FamilyManagItem(data){
    return (
        <div className={`familyManagItem ${data.type == "notBarcode" ? "notBarcode" : ""}`}>
            <div className="familyManagItem_info">
                <div className="familyManagItem_img bgImgItem" style={{backgroundImage:`url('${data.character_image}')`}}/>
                <div className="familyManagItem_nameBox">
                    {data.nicknameType ? 
                        <button type="button" className={`familyManagItem_name ${data.notranslate ? "notranslate" : ""}`} onClick={()=>{data.nicknameFunc()}}>{data.nickname}</button>
                        :
                        <h2 className={`familyManagItem_name ${data.notranslate ? "notranslate" : ""}`}>{data.nickname}</h2>
                    }
                    {data.age || data.age == 0 ? <p className="familyManagItem_age">{data.age == 0 && data.birthday ? getMonthGap(data.birthday) : data.age} {data.age == 0 && data.birthday ? "Months" : "Years"} old</p> : ""}
                </div>
            </div>
            {data.btn ? 
            <div className="familyManagItem_btnBox">
                {data.type == "notBarcode" ? 
                <div>
                <BtnItem
                    addClass="line"
                    contents={data.btn}
                    disabled={false}
                    func={() => {data.func()}}
                />
                {data.btn2 ? <BtnItem
                    addClass=""
                    contents={data.btn2}
                    disabled={false}
                    func={() => {data.func2()}}
                />: ""}
                </div>
                :
                <div>
                <button type="button" className="familyManagItem_btn" onClick={()=>{data.func()}} dangerouslySetInnerHTML={{__html:data.btn}}/>
                {data.btn2 ? <button type="button" className="familyManagItem_btn" onClick={()=>{data.func2()}} dangerouslySetInnerHTML={{__html:data.btn2}}/>: ""}
                </div>
                }
            </div>
            : ""}
        </div>
    );
}

function SpouseAddInfoArea(data){
    return (
        <div className="spouseAddInfoArea">
            <h1 className="spouseAddInfo_title" style={data.titleCenter ? {textAlign:"center"} : null} dangerouslySetInnerHTML={{__html:data.title}}></h1>
            <div className="spouseAddInfo_infoBox">
                <div className="spouseAddInfo">
                    <h2 className="spouseAddInfo_name">{data?.name ? data?.name : ""}</h2>
                    <span></span>
                    <p className="spouseAddInfo_nickName">{data?.nickname ? data?.nickname : ""}</p>
                </div>
                <p className="spouseAddInfo_number">({data?.phone ? data?.phone : ""})</p>
            </div>
            {data.text ? <p className="spouseAddInfo_text" dangerouslySetInnerHTML={{__html:data.text}}></p> : ""}
        </div>
    );
}

function InspectionStatusItem(data){
    return (
        <div className={`inspectionStatusItem ${data.addClass ? data.addClass : ""}`}>
            <img src={`/assets/images/img/${data.addClass == "active" ? "status_on" : "status_off"}.svg`}/>
            <div className="inspectionStatusItemTextBox">
                <h2 className="inspectionStatusItem_name">{data.name}</h2>
                {data.date ? <p className="inspectionStatusItem_date">{setDateTime(data.date,"yymmdd","hymd")}</p> : ""}
            </div>
        </div>
    );
}

export {ReportInfoSection,ReportTitleBox,FamilyList,UserReport,ReportPercentItem,CardSection,CardList,CardItem,ReportDataList,ReportDataListItem,ReportDataSection,ReportDetailSubTitleBox,ReportDetailtextBox,ReportDetailTitleBox,ReportDetailInfoBox,ChemiItem,ChemiCharacterItem,ChemiListTap,ChemiImgBox,ChemiDetailListItem,FamilyManagBox,FamilyManagTitleBox,FamilyManagItem,FamilyManagItemBox,SpouseAddInfoArea,InspectionStatusItem};