import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BulkLink, ProductSelBox, ProductSelItem, ProductSelPriceBox } from "component/product/list";
import { BtnBox, BtnItem } from "component/basic/btns";
import { BottomErrMsg, Popup, ToastBasicContents, ToastPopup } from "component/basic/popup";
import { OptionSelect } from "component/basic/formItems";
import { useRef } from "react";
import { ProductItem, ProductItemBox, ProductItemTitle } from "component/product/detail";
import { SubLine } from "component/basic/tap";

const ShopDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;
    const type = param.type;
    const historyType = window?.history?.state?.idx == 0 ? window?.history?.state?.idx : null;

    const [btnChk,setBtnChk] = useState(true);
    const [toastPopupData,setToastPopupData] =  useState(null);
    const [toastSubPopupData,setToastSubPopupData] =  useState(null);
    const [popupData,setPopupData] =  useState(null);

    const [productPrice,setProductPrice] = useState(0);
    // const [deliveryPrice,setDeliveryPrice] = useState(0);
    const [totalPrice,setTotalPrice] = useState(0);

    const [selProduct,setSelProduct] = useState([]);

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const [dataUpdate,setDataUpdate] = useState(0);

    const sharingInput = useRef(null);

    const productApi = useGet({
        url:`/product/detail?id=${id}&update=${dataUpdate}`,
        loginType:"login"
    });
    const productData = productApi?.data?.product;

    // const deliveryApi = useGet({
    //     url:`/product/delivery`,
    //     loginType:"login"
    // });
    // const deliveryData = deliveryApi?.data?.delivery_price;

    //옵션 있는 상품 선택
    function optionSel(optionData){
        let productSelDataSet = [...selProduct];
        let productPriceSet = 0;
        productSelDataSet.push({
            id:productData?.id,
            qty:1,
            product_name:productData?.product_name,
            product_name_id:productData?.product_name_id,
            price:productData?.sale_price ? productData?.sale_price : productData?.price,
            productTotalPrict:productData?.sale_price ? (Number(productData?.sale_price) + Number(optionData.option_price)) * 1 : (Number(productData?.price) + Number(optionData.option_price)) * 1,
            thumb_image:productData?.thumb_image,
            option_name:optionData.option_name,
            option_id:optionData.id,
            option_price:optionData.option_price,
            option_qty:optionData.qty,
        })
        setSelProduct(productSelDataSet);
        productSelDataSet.forEach(function(item,i){
            productPriceSet = productPriceSet + item.productTotalPrict;
            if(productSelDataSet.length - 1 == i){
                setProductPrice(productPriceSet)
            }
        });
    }

    //옵션 없는 상품 선택
    function orderOption(){
        if(!productData?.options || productData?.options.length == 0){
            let productSelDataSet = [{
                id:productData?.id,
                qty:1,
                product_name:productData?.product_name,
                product_name_id:productData?.product_name_id,
                price:productData?.sale_price ? productData?.sale_price : productData?.price,
                productTotalPrict:productData?.sale_price ? productData?.sale_price : productData?.price,
                thumb_image:productData?.thumb_image
            }]
            setSelProduct(productSelDataSet);
            setProductPrice(productData?.sale_price ? productData?.sale_price : productData?.price)
        }else{
            setProductPrice(0)
        }

        setToastPopupData({
            addClass:"",
            title: productData?.options && productData?.options.length > 0 ? "Select Options" : "Select Quantity",
            closeType:true,
            closeFuncChk:true,
            closeFunc:(e) => {setSelProduct([]);setProductPrice(0)},
            btn0:"Add to Cart",
            btn0Type:"line",
            btn1:"Buy Now",
            btn1Type:"",
            btnFunc0:(e)=>{
                addCart()
            },
            btnFunc1:()=>{
                addOrder()
            },
        });
    }

    //바로 주문
    function addOrder(){
        if(selProduct.length == 0){
            setBottomMsgData({
                addClass : "",
                text : "Please select options first.",
                chk : bottomMsgData.chk + 1
            });
        }else{
            let nowUrl = new URL(window.location.href);
            sessionStorage.setItem("setUrl",nowUrl.pathname);
            navigate(localStorage.getItem("token") ? `/order/basic` : `/welCome`,{state: {type:"basic",productData: selProduct}});
        }
    }

    //장바구니 추가
    function addCart(){
        if(selProduct.length == 0){
            setBottomMsgData({
                addClass : "",
                text : "Please select options first.",
                chk : bottomMsgData.chk + 1
            });
        }else{
            let formData = new FormData();

            if(!localStorage.getItem("token")){
                formData.append("nonmember_key", localStorage.getItem("nonmemberKey"));
            }

            selProduct.forEach(function(item,i){
                formData.append("product_id[]", item.id);
                formData.append("option_id[]", item.option_id||"");
                formData.append("qty[]", item.qty||1);
            });

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/product/cart`,
                loginType: "login",
                success: (data) => {
                    setSelProduct([]);
                    setProductPrice(0);
                    setDataUpdate(dataUpdate + 1);
                    setToastSubPopupData({
                        addClass:"",
                        title: null,
                        basicTitle:"Added to Cart!",
                        basicText:"Selected item has been added to your cart.",
                        closeType:true,
                        btn0:"View Cart",
                        btn0Type:"line",
                        btn1:"Continue Shopping",
                        btn1Type:"",
                        btnFunc0:(e)=>{
                            navigate("/cart")
                        },
                        btnFunc1:()=>{
                            
                        },
                    });
                },
                err: (data) => {
                    if(data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.data||"",
                            text:data.alert,
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"Confirm",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                }
            })
        }
    }
    
    //공유
    function sharing(){
        sharingInput.current.value = `${window.location.protocol}//${window.location.host}/shop/detail/${id}/1`;
        sharingInput.current.select();
        document.execCommand('copy');
        sharingInput.current.blur();
        setBottomMsgData({
            addClass : "",
            text : "URL has been copied. Go share it with others!",
            chk : bottomMsgData.chk + 1
        });
    }

    useEffect(() => {
        if(productData){
            if((productData?.options
                && productData?.options?.length > 0
                && productData?.options?.filter((el)=>el.qty !== 0).length == 0)
                || productData?.qty <= 0){
                setBtnChk(true)
            }else{
                setBtnChk(false)
            }
        }
    }, [productApi]);

    useEffect(() => {
        if(toastPopupData && selProduct.length > 0){
            let popupDataSet = {...toastPopupData}
            popupDataSet.btnFunc0 = (e)=>{addCart()}
            popupDataSet.btnFunc1 = (e)=>{addOrder()}
            setToastPopupData(popupDataSet)
        }
    }, [selProduct]);

    // useEffect(() => {
    //     if(deliveryData){
    //         setDeliveryPrice(Number(deliveryData))
    //     }
    // }, [deliveryData]);

    useEffect(() => {
        // setTotalPrice(productPrice + deliveryPrice)
        setTotalPrice(productPrice)
    }, [productPrice]);

    useEffect(() => {
        
    }, []);

    return (
        <PageSizing addClass="bottomBtn headerSet">
            <Header
                pageClose={true}
                closeFunc={() => {
                    if(type == 0 && historyType !== 0){
                        navigate(-1);
                    }else{
                        navigate("/main");
                    }
                }}
                headTitle=""
                centerTitle={true}
            >
                <BulkLink addClass="btnHeaderLeft"/>
            </Header>
            <div className="productDetail">
                <ProductItemBox>
                    <ProductItem
                        img={productData?.thumb_image}
                        name={props.language == "en" ? productData?.product_name : productData?.product_name_id}
                        options={null}
                        priceClass="pColor"
                        sale_price={productData?.sale_price ? productData?.sale_price : null}
                        price={productData?.price}
                    />
                </ProductItemBox>
                <SubLine addClass="" margin="0"/>
                <ProductItemBox>
                    <ProductItemTitle
                        title="Details"
                    />
                    <div className="productDetailData editerBox notranslate" dangerouslySetInnerHTML={{__html:props.language == "en" ? productData?.contents : productData?.contents_id}}></div>
                </ProductItemBox>
            </div>
            <BtnBox
                boxType="fixed"
                addClass="col2"
            >
                <button type="button" onClick={()=>{sharing()}} className="minPageBtn btnSharing">
                    <img src="/assets/images/icon/sharing.svg"/>
                    <input type="text" ref={sharingInput} readOnly={true} className="sharingInput"/>
                </button>
                <BtnItem
                    addClass=""
                    contents={btnChk ? "Sold Out" : "Add to Cart"}
                    disabled={btnChk}
                    func={() => {orderOption()}}
                />
            </BtnBox>
            <ToastPopup
                data={toastPopupData}
            >
                <ProductSelBox>
                    {productData ? productData?.options && productData?.options.length > 0 ? 
                    <div>
                        <OptionSelect
                            inputName=""
                            placeholder="Select Options"
                            selData={selProduct}
                            func={(e) => {optionSel(e)}}
                            options={productData?.options}
                            boxAddClass={"productOptionItemBox"}
                        />
                        {
                            selProduct.length > 0 ? 
                            <div>
                            {selProduct?.map((item, i) => (
                                <ProductSelItem
                                    key={i}
                                    del={true}
                                    delFunc={()=>{
                                        let productSelDataSet = [...selProduct];
                                        let delProductData = productSelDataSet?.filter((dataArr)=>{return dataArr.option_id !== item.option_id});
                                        let productPriceSet = 0;

                                        if(delProductData.length == 0){
                                            setSelProduct(delProductData);
                                            setProductPrice(0);
                                        }else{
                                            delProductData.forEach(function(productItem,idx){
                                                productPriceSet = productPriceSet + productItem.productTotalPrict;

                                                if(delProductData.length - 1 == idx){
                                                    setSelProduct(delProductData);
                                                    setProductPrice(productPriceSet);
                                                }
                                            });
                                        }
                                    }}
                                    id={item.option_id}
                                    name={item.option_name}
                                    notranslate={false}
                                    price={productData?.sale_price ? Number(productData?.sale_price) + item.option_price : Number(productData?.price) + item.option_price}
                                    countVal={item.qty ? item.qty : 1}
                                    qty={item.option_qty}
                                    countFunc={(val)=>{
                                        let productSelDataSet = [...selProduct];
                                        let productPriceSet = 0;
                                        productSelDataSet.forEach(function(productItem,idx){
                                            if(item.option_id == productItem.option_id){
                                                productSelDataSet[idx].qty = val;
                                                productSelDataSet[idx].productTotalPrict = productData?.sale_price ? (Number(productData?.sale_price) + Number(productItem.option_price)) * val : (Number(productData?.price) + Number(productItem.option_price)) * val;
                                            }

                                            productPriceSet = productPriceSet + productItem.productTotalPrict;

                                            if(productSelDataSet.length - 1 == idx){
                                                setSelProduct(productSelDataSet);
                                                setProductPrice(productPriceSet);
                                            }
                                        });
                                    }}
                                    excessFunc={()=>{
                                        setBottomMsgData({
                                            addClass : "",
                                            text : "Exceeded available quantity.",
                                            chk : bottomMsgData.chk + 1
                                        });
                                    }}
                                    excessFunc_max={()=>{
                                        setBottomMsgData({
                                            addClass : "",
                                            text : "Please inquire separately for bulk purchases!",
                                            chk : bottomMsgData.chk + 1
                                        });
                                    }}
                                />
                            ))}
                            </div>
                            :<p className="notOptionText">Please select options.</p>
                        }
                    </div>
                    : 
                    <ProductSelItem
                        del={false}
                        delFunc={()=>{}}
                        id={productData?.id}
                        name={props.language == "en" ? productData?.product_name : productData?.product_name_id}
                        notranslate={true}
                        price={productData?.sale_price ? productData?.sale_price : productData?.price}
                        countVal={selProduct[0]?.qty ? selProduct[0]?.qty : 1}
                        qty={productData?.qty}
                        countFunc={(val)=>{
                            let productSelDataSet = [{
                                id:productData?.id,
                                qty:val,
                                product_name:productData?.product_name,
                                product_name_id:productData?.product_name_id,
                                price:productData?.sale_price ? productData?.sale_price : productData?.price,
                                productTotalPrict:productData?.sale_price ? Number(productData?.sale_price) * val : Number(productData?.price) * val,
                                thumb_image:productData?.thumb_image
                            }]
                            setSelProduct(productSelDataSet);
                            setProductPrice(productData?.sale_price ? Number(productData?.sale_price) * val : Number(productData?.price) * val);
                        }}
                        excessFunc={()=>{
                            setBottomMsgData({
                                addClass : "",
                                text : "Exceeded available quantity.",
                                chk : bottomMsgData.chk + 1
                            });
                        }}
                        excessFunc_max={()=>{
                            setBottomMsgData({
                                addClass : "",
                                text : "Please inquire separately for bulk purchases!",
                                chk : bottomMsgData.chk + 1
                            });
                        }}
                    /> : ""}
                    <ProductSelPriceBox
                        productPrice={productPrice}
                        // deliveryPrice={deliveryPrice}
                        TotalPrice={totalPrice}
                    />
                </ProductSelBox>
            </ToastPopup>

            <ToastPopup data={toastSubPopupData}>
                <ToastBasicContents
                    title={toastSubPopupData?.basicTitle ? toastSubPopupData?.basicTitle : ""}
                    text={toastSubPopupData?.basicText ? toastSubPopupData?.basicText : ""}
                />
            </ToastPopup>

            <BottomErrMsg
                addClass={bottomMsgData.addClass ? bottomMsgData.addClass : ""}
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />

            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default ShopDetail;