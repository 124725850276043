import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { MenuHeader, PageSizing } from "component/elements/header";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BulkLink, ProductSelPriceData } from "component/product/list";
import { BtnBox, BtnItem } from "component/basic/btns";
import { BottomErrMsg, Popup } from "component/basic/popup";
import { ChkBox } from "component/basic/formItems";
import { ProductItemBox, ProductItemTitle } from "component/product/detail";
import { SubLine } from "component/basic/tap";
import { CartListItem } from "component/product/cart";
import { comFormat } from "js/function";

const Cart = (props) => {
    const navigate = useNavigate();

    const productApi = useGet({
        url:`/product/cart?nonmember_key=${localStorage.getItem("nonmemberKey")}`,
        loginType:"login"
    });
    const productData = productApi?.data?.cart?.cart_products;

    // const deliveryApi = useGet({
    //     url:`/product/delivery`,
    //     loginType:"login"
    // });
    // const deliveryData = deliveryApi?.data?.delivery_price;

    const [btnChk,setBtnChk] = useState(true);

    const [productPrice,setProductPrice] = useState(0);
    // const [deliveryPrice,setDeliveryPrice] = useState(0);
    const [totalPrice,setTotalPrice] = useState(0);

    const [basicProduct,setBasicProduct] = useState([]);

    const [selProduct,setSelProduct] = useState([]);

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [popupData,setPopupData] =  useState(null);

    const cartQtyTimeout = useRef(null);

    // 체크박스 선택
    const handleSingleCheck = (checked, data) => {
         if (checked) {
            setSelProduct(prev => [...prev, data]);
        } else {
            setSelProduct(selProduct.filter((el) => el.id !== data.id));
        }
    };

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if(checked) {
            setSelProduct([...basicProduct]);
        } else {
            setSelProduct([]);
        }
    }

    //상품 삭제
    function cartDel(id){
        fatchSet.FatchApi({
            type:"DELETE",
            url: `/product/cart/delete?id=${id}${localStorage.getItem("token") ? "" : `&nonmember_key=${localStorage.getItem("nonmemberKey")}`}`,
            loginType: "login",
            success: (data) => {
                window.location.reload(true);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //상품 수량 변경
    function cartQtyCh(id,val){
        clearTimeout(cartQtyTimeout.current);

        cartQtyTimeout.current = setTimeout(() => {
            let formData = new FormData();
            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/product/cart/qty?id=${id}&qty=${val}${localStorage.getItem("token") ? "" : `&nonmember_key=${localStorage.getItem("nonmemberKey")}`}`,
                loginType: "login",
                success: (data) => {
                    
                },
                err: (data) => {
                    if(data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.data||"",
                            text:data.alert,
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"Confirm",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                }
            })
        }, 1000);
    }

    //상품 구매
    function addOrder(){
        let nowUrl = new URL(window.location.href);
        sessionStorage.setItem("setUrl",nowUrl.pathname);
        navigate(localStorage.getItem("token") ? `/order/cart` : `/welCome`,{state: {type:"cart",productData: selProduct}});
    }

    useEffect(() => {
        if(productData){
            setBasicProduct(productData);
        }
    }, [productApi]);

    useEffect(() => {
        let productPriceSet = 0;

        if(selProduct?.length > 0){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }

        if(selProduct.length > 0){
            selProduct.forEach(function(item,i){
                productPriceSet = productPriceSet + (((item.sale_price ? Number(item.sale_price) : Number(item.price)) + (item.option_price ? Number(item.option_price) : 0)) * Number(item.cart_qty));
                if(selProduct.length - 1 == i){
                    setProductPrice(productPriceSet);
                }
            });
        }else{
            setProductPrice(0);
        }
    }, [selProduct]);

    // useEffect(() => {
    //     if(deliveryData){
    //         setDeliveryPrice(Number(deliveryData))
    //     }
    // }, [deliveryData]);

    useEffect(() => {
        // setTotalPrice(productPrice + deliveryPrice)
        setTotalPrice(productPrice)
    // }, [productPrice,deliveryPrice]);
    }, [productPrice]);

    return (
        <PageSizing addClass="bottomBtn headerSet">
            <MenuHeader
                languageCh={(type)=>{props.languageCh(type)}}
                language={props.language}
            />
            <ProductItemBox>
                <ProductItemTitle
                    title="My Cart"
                >
                    {basicProduct && basicProduct?.length > 0 ? "" : <BulkLink addClass=""/>}
                </ProductItemTitle>
                {basicProduct && basicProduct?.length > 0 ? 
                <div>
                    <div className="cartList_head">
                        <ChkBox
                            addClass="allChkItem_min"
                            func={(e) => handleAllCheck(e.target.checked)}
                            checkedType={selProduct?.length === basicProduct?.length ? true : false}
                            label={`Select All (${selProduct ? selProduct?.length : 0}/${basicProduct ? basicProduct?.length : 0})`}
                        />
                        <BulkLink addClass=""/>
                    </div>
                    <SubLine addClass="pageFull" margin="10px 0 0"/>
                    <div className="cartList">
                        {basicProduct?.map((item, i) => (
                            <CartListItem
                                key={i}
                                chkFunc={(e)=>{handleSingleCheck(e.target.checked,item)}}
                                // checkedType={}

                                img={item.thumb_image}
                                id={item.id}
                                name={props.language == "en" ? item.product_name : item.product_name_id}
                                option_name={item.option_name}
                                option_qty={item.cart_qty}
                                delFunc={()=>{cartDel(item.id)}}
                                price={(((item.sale_price ? Number(item.sale_price) : Number(item.price))) + (item.option_price ? Number(item.option_price) : 0)) * Number(item.cart_qty)}
                              
                                checkedType={selProduct.filter((el) => el.id == item.id).length > 0}
                                countVal={item.cart_qty}
                                qty={item.product_qty}
                                optionQty={item.option_qty}
                                countFunc={(val)=>{
                                    let productBasicDataSet = [...basicProduct];
                                    productBasicDataSet.forEach(function(productItem,idx){
                                        if(item.id == productItem.id){
                                            productBasicDataSet[idx].cart_qty = val;
                                        }

                                        if(productBasicDataSet.length - 1 == idx){
                                            setBasicProduct(productBasicDataSet);
                                        }
                                    });

                                    let productSelDataSet = [...selProduct];
                                    productSelDataSet.forEach(function(productItem,idx){
                                        if(item.id == productItem.id){
                                            productSelDataSet[idx].cart_qty = val;
                                        }

                                        if(productSelDataSet.length - 1 == idx){
                                            setSelProduct(productSelDataSet);
                                        }
                                    });

                                    cartQtyCh(item.id,val);
                                }}
                                excessFunc={()=>{
                                    setBottomMsgData({
                                        addClass : "",
                                        text : "Exceeded available quantity.",
                                        chk : bottomMsgData.chk + 1
                                    });
                                }}
                                excessFunc_max={()=>{
                                    setBottomMsgData({
                                        addClass : "",
                                        text : "Please inquire separately for bulk purchases!",
                                        chk : bottomMsgData.chk + 1
                                    });
                                }}
                            />
                        ))}
                    </div>
                    <div className="cartPriceBox orderPriceBox">
                        <ProductSelPriceData name="Total Price" val={`Rp${comFormat(totalPrice)}`} notranslate={true} addClass="total"/>
                        {/* <ProductSelPriceData name="Product Price" val={`Rp${comFormat(productPrice)}`} notranslate={true} addClass=""/> */}
                        {/* <ProductSelPriceData name="Shipping Fee" val={`Rp${comFormat(deliveryPrice)}`} notranslate={true} addClass=""/> */}
                    </div>
                </div>
                :
                <div className="notCartList">
                    <img src="/assets/images/img/notCart.svg"/>
                    <i>There are no items in the cart.</i>
                </div>
                }
            </ProductItemBox>
            <BtnBox
                boxType="fixed"
                addClass=""
            >
                <BtnItem
                    addClass=""
                    contents={"Buy Now"}
                    disabled={btnChk}
                    func={() => {addOrder()}}
                />
            </BtnBox>

            <BottomErrMsg
                addClass={bottomMsgData.addClass ? bottomMsgData.addClass : ""}
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />

            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default Cart;