import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem, BtnSubBox } from "component/basic/btns";
import { ChkBox, InputBoxOnly, InputItemBox } from "component/basic/formItems";
import { Popup, ToastPopup } from "component/basic/popup";
import { HeaderTap, InputTap } from "component/basic/tap";
import { Header, PageSizing } from "component/elements/header";
import { MemberInputSection, MemberPage, MemberTitle } from "component/member/items";
import { SpouseAddInfoArea } from "component/report/items";
import { phoneChk } from "js/function";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AddSpouse = (props) => {
    const navigate = useNavigate();

    const [type,setType] =  useState("email");

    const [joinType,setJoinType] =  useState("email");
    const [email,setEmail] =  useState("");
    const [phone,setPhone] =  useState("");
    const [emailErr,setEmailErr] =  useState(null);
    const [chkVal,setChkVal] =  useState(false);

    const [phoneErr,setPhoneErr] =  useState(null);

    const [spouseData,setSpouseData] =  useState({});

    const [btnChk,setBtnChk] =  useState("");

    const [toastPopupData,setToastPopupData] =  useState(null);
    const [errPopupData,setErrPopupData] =  useState(null);

    useEffect(() => {
        if(type == "email"){
            if(email !== "" && chkVal){
                let emailRegExp = /[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}/;
                
                if(emailRegExp.test(email)){
                    setBtnChk(false);
                    setEmailErr(null);
                }else{
                    setBtnChk(true);
                    setEmailErr("Please enter a valid email address.");
                }
            }else{
                setBtnChk(true);
            }
        }
    }, [email,chkVal]);

    useEffect(() => {
        if(phone !== "" || phone){
            if(phoneChk(phone)){
                setPhoneErr(null);
            }else{
                setPhoneErr(`Please enter '62' followed by your phone number, excluding the initial '0' (zero), and without "-"`);
            }
        }else{
            setPhoneErr(null);
        }
    }, [phone]);

    useEffect(() => {
        if(type == "phone"){
            if(phoneChk(phone) && chkVal){
                setBtnChk(false);
            }else{
                setBtnChk(true);
            }
        }
    }, [phone,chkVal]);

    function tapCh(id){
        setBtnChk(true);

        setEmail("");
        setPhone("");
        setEmailErr(null);
        setJoinType("email");

        setType(id);
    }

    //배우자 추가 팝업
    function addSpouseOpen(){
        setSpouseData({});

        let formData = new FormData();
        formData.append("type", type);
        if(type == "email"){
            formData.append("email", email);
            formData.append("provider", joinType);
        }else{
            formData.append("phone", `+${phone.replace(/\+/g,"")}`);
        }

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: "login",
            url: "/family/check",
            success: (data) => {
                setSpouseData({
                    name : data.data.name,
                    nickname : data.data.nickname,
                    phone : data.data.phone
                });
                setToastPopupData({
                    addClass:null,
                    title:null,
                    closeType:true,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{
                        addSpouse();
                    },
                    btn0:"Yes, that's correct",
                    btn0Type:"",
                    btn1:"",
                    btn1Type:"",
                    btnFunc1:()=>{}
                });
            },
            err: (data) => {
                if(data.alert){
                    setErrPopupData({
                        addClass:null,
                        title:type == "email" ? "Unable to find the email address." : "Unable to find the phone number",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //배우자 추가
    function addSpouse(){
        let formData = new FormData();
        formData.append("type", type);
        if(type == "email"){
            formData.append("email", email);
            formData.append("provider", joinType);
        }else{
            formData.append("phone", `+${phone.replace(/\+/g,"")}`);
        }

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: "login",
            url: "/family/register",
            success: (data) => {
                setErrPopupData({
                    addClass:null,
                    title:"Invitation to compare genetic chemistry has been sent",
                    text:"If the other person accepts it, he/she will be registered as your partner.",
                    closeType:false,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{navigate("/report")},
                    btn0:"Confirm",
                    btn0Type:"",
                    btn1:"",
                    btn1Type:"",
                    btnFunc1:()=>{}
                });
            },
            err: (data) => {
                if(data.alert){
                    setErrPopupData({
                        addClass:null,
                        title:"This account has already been linked",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="Add Partner"
                centerTitle={true}
            />
            <HeaderTap
                data={[
                    {
                        id:"email",
                        category:"E-mail"
                    },
                    {
                        id:"phone",
                        category:"Mobile Phone"
                    }
                ]}
                val={type}
                func={(id) => {tapCh(id)}}
            />
            <MemberPage addClass="headerTapPage bottomChkBtnPage">
                <MemberTitle
                    contents={`<i>Please enter the </i><span>email</span><i> or </i><span>phone number</span><i> which the person you would like to add as partner has provided during sign up.</i>`}
                />
                <MemberInputSection>
                    {type == "email" ? 
                    <div>
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="Email Address"
                            placeholder="example@gmail.com"
                            value={email}
                            max={255}
                            regexp={null}
                            errMsg={emailErr}
                            func={(value)=>{setEmail(value)}}
                            boxAddClass="marginUp"
                        />
                        <InputBoxOnly addClass="marginUp" inputName="how did your partner sign up?">
                            <InputTap
                                data={[
                                    {
                                        id:"email",
                                        name:"Email"
                                    },
                                    {
                                        id:"google",
                                        name:"Google"
                                    },
                                    {
                                        id:"apple",
                                        name:"Apple"
                                    },
                                    {
                                        id:"facebook",
                                        name:"Facebook"
                                    }
                                ]}
                                val={joinType}
                                func={(id) => {setJoinType(id)}}
                            />
                        </InputBoxOnly>
                    </div>    
                        :
                        <InputItemBox
                            inputType={null}
                            addClass="inputSubCaptionItem"
                            inputName="Mobile Phone Number"
                            placeholder="6201234567890"
                            value={phone}
                            max={16}
                            regexp={"number"}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            errMsg={phoneErr}
                            func={(value)=>{setPhone(value)}}
                        ><p className="inputSubCaption_text">+</p></InputItemBox>
                    }
                </MemberInputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <ChkBox
                        addClass=""
                        func={(e) => setChkVal(e.target.checked)}
                        label={"[Required] Collection and Usage of Personal Information"}
                        checkedType={chkVal}
                        chkFunc={(e) => props.rightOpenFunc(26,"Collection and Usage of Personal Information")}
                    />
                    <BtnSubBox addClass="">
                        <BtnItem
                            addClass=""
                            contents="Confirm"
                            disabled={btnChk}
                            func={() => {addSpouseOpen()}}
                        />
                    </BtnSubBox>
                </BtnBox>
            </MemberPage>
            <Popup
                data={errPopupData}
            />
            <ToastPopup
                data={toastPopupData}
            >
                <SpouseAddInfoArea
                    title="Is this the partner you're trying to register?"
                    name={spouseData?.name ? spouseData?.name : ""}
                    nickname={spouseData?.nickname ? spouseData?.nickname : ""}
                    phone={spouseData?.phone ? spouseData?.phone : ""}
                    text={null}
                />
            </ToastPopup>
        </PageSizing>
    );
};

export default AddSpouse;