import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { LoadingBox, Popup, RightPopup } from "component/basic/popup";
import { Header, PageSizing } from "component/elements/header";
import { MemberInputSection, MemberPage, MemberTitle } from "component/member/items";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Certified = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const type = param.type;
    const marketingType = param.marketingType;

    const [errPopupData,setErrPopupData] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);

    const [reference, setReference] = useState(null);
    const [btnChk, setBtnChk] = useState(true);
    const [apiUrl, setApiUrl] = useState(null);

    const [gender, setGender] = useState(null);
    const [birthday, setBirthday] = useState(null);

    const [certifiedPopup, setCertifiedPopup] = useState(false);

    const certifyData = useGet({
        url:apiUrl,
    });

    const [verificationUrl, setVerificationUrl] = useState("");

    const certifiedInterval = useRef(null);

    //본인인증 시작
    function certifiedStert(){
        setLoadingBoxOpen(true);

        setApiUrl("/certify");

        setTimeout(() => {
            setApiUrl(null);
        }, 2000);
    }

    //본인인증 완료
    function certifiedChkFunc(id){
        let formData = new FormData();
        formData.append("reference", id);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/certify/info",
            success: (data) => {
                setBtnChk(false);

                setGender(data.data?.document?.gender);
                setBirthday(data.data?.document?.dob.replace(/-/g,""));
                
                clearInterval(certifiedInterval.current);
                certifiedInterval.current = null;

                setCertifiedPopup(false);
            },
            err: (data) => {
                
            }
        })
    }

    const joinStert = () => {
        if(type == "email" || type == "sns" || type == "apple"){
            navigate(`/member/join/${type}/${marketingType}/${gender}/${birthday}`);
        }else{
            navigate(`/`);
        }
    }

    //본인인증 세팅
    useEffect(() => {
        if(certifyData?.reference && !reference){
            setReference(certifyData?.reference);

            setVerificationUrl(certifyData?.verification_url);

            setTimeout(() => {
                setCertifiedPopup(true);

                if(certifiedInterval.current !== null) {
                    clearInterval(certifiedInterval.current);
                    certifiedInterval.current = null;
                }

                setTimeout(() => {
                    certifiedInterval.current = setInterval(function() {
                        certifiedChkFunc(certifyData?.reference);
                    }, 3000);
                }, 200);
            }, 100);
        }
        setTimeout(() => {
            setLoadingBoxOpen(false);
        }, 2000);
    }, [certifyData]);

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="Identity Verification"
                centerTitle={true}
            />
            <MemberPage>
                <MemberTitle
                    contents={btnChk ? "<i>We will proceed with </i><span>identity verification</span><br/><i>to confirm your registration.</i>" : null}
                />
                <MemberInputSection addClass="certifiedContents">
                    {btnChk ? 
                        <BtnItem
                            addClass={"line"}
                            contents={"Start Identity Verification"}
                            disabled={false}
                            func={() => {
                                certifiedStert()
                            }}
                        />
                    : ""}
                    {!btnChk ? 
                        <div className="certifiedComBox">
                            <img src="/assets/images/main/return_icon.svg"/>
                            <h1 className="certifiedComTitle"><span>Identity verification</span><i> has been completed.</i></h1>
                        </div>
                    :""}
                </MemberInputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={type == "email" || type == "sns" || type == "apple" ? "Next" : "Complete"}
                        disabled={btnChk}
                        func={() => {joinStert()}}
                    />
                </BtnBox>
            </MemberPage>
            <RightPopup
                topTitle={null}
                addClass={certifiedPopup ? "active" : ""}
                closePopup={() => {
                    setCertifiedPopup(false)
                    setTimeout(() => {
                        setApiUrl(null);
                        setReference(null);
                        setVerificationUrl("");
                    }, 200);
                }}
                openType={certifiedPopup}
            >
                <iframe width="100%" height="100%" src={verificationUrl} frameBorder="0" allow="autoplay; camera; microphone"></iframe>
            </RightPopup>
            <Popup
                data={errPopupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default Certified;