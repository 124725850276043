import useGet from "api/useGet";
import { BtnItem } from "component/basic/btns";
import { InputTap } from "component/basic/tap";
import { MenuItem, MenuTitle, SideMenuBox, SideMenuBoxItem } from "component/menu/menuItems";
import { basicImgSet, bodyScroll_clear, bodyScroll_limit, nightTimeChk } from "js/function";
import { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Header(headerData){
    let navigate = useNavigate();

    const [shKey,setShKey] = useState("");
    const [info,setInfo] = useState(false);

    function shSet(e){
        setShKey(e.target.value);
        if(e.keyCode == 13 || e.key == "Enter"){
            headerData.shFunc(shKey);
        }
    }
    return (
        <div className="header">
            <div className={`headerSection ${headerData.addClass ? headerData.addClass : ""}`}>
                {!headerData.pageBack || headerData.pageBack === "" ? "" : <button type="button" className="btnBack btnHeaderLeft" onClick={(e) => headerData.func(e)}><img src="/assets/images/basic/back.svg"/></button>}
                {headerData.headTitle ? <p className={`headTitle ${headerData.centerTitle ? "centerTitle" : ""} ${headerData.notranslate_title ? "notranslate" : ""}`} dangerouslySetInnerHTML={{__html:headerData.headTitle}}/> : ""}
                {headerData.rightBtns ? 
                    <div className="headerInfoBox btnHeaderRight">
                        {headerData.rightBtnsArr.map((item, i) => (
                            <div key={i}>
                                {item.type !== "img" ?
                                <button type="button" className={`btnHeaderRightItem ${item.addClass ? item.addClass : ""}`} onClick={(e) => {item.func()}} dangerouslySetInnerHTML={{__html:item.contents}}></button>
                                :
                                <button type="button" className={`btnHeaderRightItem ${item.addClass ? item.addClass : ""}`} onClick={(e) => {item.func()}}><img src={item.contents}/></button>
                                }
                            </div>
                        ))}
                    </div>
                    : ""
                }
                {headerData.shLink ? <button type="button" className="btnSh btnHeaderRight" onClick={(e) => navigate("/sh")}><img src="/assets/images/basic/sh.svg"/></button> : ""}
                {headerData.sharing ? <button type="button" className="btnSharing btnHeaderRight" onClick={(e) => headerData.sharingFunc(e)}><img src="/assets/images/basic/sharing_min.svg"/></button> : ""}
                {
                    headerData.shBox ? 
                    <div className="header_shBox">
                        <input type="text" className="header_sh" onInput={(e) => setShKey(e.target.value)} onPaste={(e) => setShKey(e.target.value)} onChange={(e) => setShKey(e.target.value)} onKeyUp={(e) => shSet(e)} placeholder=""/>
                        <button type="button" onClick={(e) => headerData.shFunc(shKey)} className="btn_header_sh"><img src="/assets/images/basic/sh.svg"/></button>
                    </div>
                    :""
                }
                {headerData.homeBtn ? <button type="button" className="btnHome btnHeaderRight" onClick={(e) => {navigate("/")}}><img src="/assets/images/icon/home_icon.svg"/></button> : ""}
                {headerData.pageClose ? <button type="button" className="btnClose btnHeaderRight" onClick={(e) => {headerData.closeFunc(e)}}><img src="/assets/images/basic/close.svg"/></button> : ""}
                {headerData.setting ? <button type="button" className="btnSetting btnHeaderRight" onClick={(e) => navigate("/mypage/setting")}><img src="/assets/images/basic/setting.svg"/></button> : ""}
                {headerData.rightLinkType ? <button type="button" className="btnSetting btnHeaderRightLink btnHeaderRight" onClick={(e) => headerData.rightLink()}>{headerData.rightLinkText}</button> : ""}
                {headerData.info ? <div className="headerInfoBox btnHeaderRight">
                        <button type="button" className="btn_headerInfo" onClick={(e) => setInfo(!info)}>
                        <img src="/assets/images/basic/info.svg"/>
                        </button>
                        <p className="btn_headerInfoText" style={{display:`${info ? "block" : "none"}`}}><span dangerouslySetInnerHTML={{__html:headerData.info}}></span></p>
                    </div> : ""}
                {headerData.children}
            </div>
        </div>
    );
}

//header btn form
function HeaderRightBtn(data){
    return (
        <button type="button" className={`btnHeaderRight ${data.addClass ? data.addClass : ""}`} disabled={data.disabled} onClick={(e) => data.func()}>{data.children}</button>
    );
}

//사이드메뉴 header
function MenuHeader(headerData){
    let navigate = useNavigate();
    const locationType = useLocation();
    const [menuOpen,setMenuOpen] = useState(false);

    const [upDate,setUpDate] = useState(0);

    const myApi = useGet({
        url: localStorage.getItem("token") ? `/menu?update=${upDate}` : null,
        loginType:"login"
    });
    const myData = myApi ? myApi : null;

    const productApi = useGet({
        url: localStorage.getItem("token") ? null : `/product/cart?update=${upDate}&nonmember_key=${localStorage.getItem("nonmemberKey")}`,
        loginType:"login"
    });
    const productData = productApi?.data?.cart?.cart_products||null;

    function closePopup(){
        bodyScroll_clear();
        setMenuOpen(false);
    }

    useEffect(() => {
        if(headerData?.update > 0){
            setUpDate(upDate+1);
        }
    }, [headerData?.update]);

    useEffect(() => {
        if(menuOpen){
            bodyScroll_limit();
        }else{
            bodyScroll_clear();
        }
    }, [menuOpen]);

    return (
        <div className="header">
            <div className={`headerSection menuHeaderSection ${headerData.addClass ? headerData.addClass : ""}`}>
                <div className="headerSectionLeft">
                    <button type="button" onClick={()=>setMenuOpen(!menuOpen)} className={`btnMenuOpen ${menuOpen ? "active" : ""}`}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    <button type="button" onClick={()=>{if(locationType?.pathname == "/" || locationType?.pathname == "/main"){
                        window.location.reload(true);
                    }else{
                        navigate("/main")
                    }}} className="btnHome">
                        <img src="/assets/images/img/logo.svg"/>
                    </button>
                </div>
                <div className="headerSectionRight">
                    {localStorage.getItem("token") ? 
                        <button type="button" onClick={()=>{closePopup();navigate("/mypage/alarm")}} className={`btn_headerRight ${myData?.data?.alarm_count && myData?.data?.alarm_count > 0 ? "active" : "" }`}>
                            <img src="/assets/images/icon/alarm.svg"/>
                        </button>
                    :
                        <button type="button" onClick={()=>{closePopup();navigate("/welCome")}} className="btn_headerRight">
                            <img src="/assets/images/icon/login.svg"/>
                        </button>
                    }
                    <button type="button" onClick={()=>{closePopup();navigate(`${localStorage.getItem("token") ? myData?.data?.cart_count && myData?.data?.cart_count > 0 ? "/cart" : "/shop" : productData?.length > 0 ? "/cart" : "/shop"}`)}} className={`btn_headerRight ${localStorage.getItem("token") ? myData?.data?.cart_count && myData?.data?.cart_count > 0 ? "active" : "" : productData?.length > 0 ? "active" : ""}`}>
                        <img src="/assets/images/icon/cart.svg"/>
                    </button>
                </div>
            </div>
            <div className={`sideMenu ${menuOpen ? "active" : ""}`}>
                <div className="sideMenu_section">
                    <SideMenuBox>
                        {localStorage.getItem("token") ? 
                        <div>
                            <div className="sideMenu_profileArea" onClick={()=>{closePopup();navigate("/mypage/profileEdit")}}>
                                <div className="sideMenu_profileBox">
                                    <div className="sideMenu_profileImg" style={{backgroundImage:`url('${
                                        nightTimeChk() ? basicImgSet(myData?.data?.user_info?.profileImg_night||null)
                                        : basicImgSet(myData?.data?.user_info?.profileImg||null)
                                    }')`}}></div>
                                    <div className="sideMenu_profileInfoBox">
                                        <h2 className="sideMenu_profile_name notranslate">{myData?.data?.user_info?.name}</h2>
                                        <p className="sideMenu_profile_email notranslate">{myData?.data?.user_info?.email}</p>
                                    </div>
                                </div>
                                <img src="/assets/images/basic/move_g.svg"/>
                            </div>
                            <BtnItem
                                addClass=""
                                contents="Register Barcode"
                                disabled={false}
                                func={() => {
                                    closePopup();
                                    navigate("/addBarcode");
                                }}
                            />
                        </div>
                        :
                        <div>
                            <h1 className="sideMenuLogin_title">Log in and check your genetic test results!</h1>
                            <BtnItem
                                addClass=""
                                contents="Sign in"
                                disabled={false}
                                func={() => {
                                    closePopup();
                                    let nowUrl = new URL(window.location.href);
                                    sessionStorage.setItem("setUrl",nowUrl.pathname);
                                    navigate("/welCome");
                                }}
                            />
                        </div>
                        }
                    </SideMenuBox>
                    <div className="sideMenu_mainLink">
                        <SideMenuBox
                            func={()=>{closePopup();navigate("/report")}}
                        >
                            <SideMenuBoxItem
                                src="/assets/images/icon/report.svg"
                                text="REPORTS"
                            />
                        </SideMenuBox>
                        <SideMenuBox
                            func={()=>{closePopup();navigate("/shop")}}
                        >
                            <SideMenuBoxItem
                                src="/assets/images/icon/shop.svg"
                                text="SHOP"
                            />
                        </SideMenuBox>
                    </div>
                    <MenuItem
                        addClass=""
                        menuItemName="Order History"
                        nameIcon={null}
                        disabled={false}
                        func={()=>{
                            closePopup();
                            if(localStorage.getItem("token")){
                                navigate("/mypage/order")
                            }else{
                                navigate("/inquiry")
                            }
                        }}
                        subItemText={null}
                        subItemTextAddClass=""
                        icon="move_g"
                        toggleBtn={false}
                    />
                    <MenuItem
                        addClass=""
                        menuItemName="Add Family"
                        nameIcon={null}
                        disabled={false}
                        func={()=>{closePopup();navigate("/report/family")}}
                        subItemText={null}
                        subItemTextAddClass=""
                        icon="move_g"
                        toggleBtn={false}
                    />
                    <MenuItem
                        addClass=""
                        menuItemName="Customer Service"
                        nameIcon={null}
                        disabled={false}
                        func={()=>{closePopup();navigate("/cs/faq")}}
                        subItemText={null}
                        subItemTextAddClass=""
                        icon="move_g"
                        toggleBtn={false}
                    />
                    <MenuItem
                        addClass=""
                        menuItemName="Bulk Purchase Inquiry"
                        nameIcon={null}
                        disabled={false}
                        func={()=>{closePopup();navigate("/cs/bulk")}}
                        subItemText={null}
                        subItemTextAddClass=""
                        icon="move_g"
                        toggleBtn={false}
                    />
                    <MenuItem
                        addClass=""
                        menuItemName="Settings"
                        nameIcon={null}
                        disabled={false}
                        func={()=>{closePopup();navigate("/mypage/setting")}}
                        subItemText={null}
                        subItemTextAddClass=""
                        icon="move_g"
                        toggleBtn={false}
                    />
                    <div className="sideMenu_tapBox">
                        <MenuTitle text="Language Settings"/>
                        <InputTap
                            notranslate={true}
                            data={[
                                {
                                    id:"en",
                                    name:"English"
                                },
                                {
                                    id:"id",
                                    name:"Bahasa indonesia"
                                }
                            ]}
                            val={headerData.language}
                            func={(id) => {headerData.languageCh(id)}}
                        />
                    </div>
                </div>
                {myData?.data?.barcodes && myData?.data?.barcodes?.length > 0 && localStorage.getItem("token") ? 
                <div className="sideMenu_userList">
                    <MenuTitle text="Check Lifestyle Habits"/>
                    {myData?.data?.barcodes.map((item,i)=>(
                        <MenuItem
                            addClass=""
                            menuItemName={item.name}
                            notranslate={true}
                            nameIcon={item.user_type == 1 ? "Me" : null}
                            disabled={false}
                            func={()=>{
                                if(item.status !== "Not Applicable" && item.status !== "Complete"){
                                    closePopup();
                                    navigate(`/survey/${item.id}/${item.user_type == 1 ? "me" : "baby"}/event`)
                                }
                            }}
                            subItemText={item.status}
                            subItemTextAddClass={item.status == "Complete" ? "gColor" : item.status == "Not completed" || item.status == "Not Applicable" ? "" : "bColor"}
                            icon={item.status !== "Not Applicable" && item.status !== "Complete" ? "move_g" : false}
                            toggleBtn={false}
                            key={item.id}
                            cursorType={item.status !== "Not Applicable" && item.status !== "Complete" ? "pointer" : "auto"}
                        />
                    ))}
                </div>
                :""}
            </div>
        </div>
    );
}

//페이지 최소 사이즈 세팅
function PageSizing(data){
    return (
        <div className={`pageSizing ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

export {Header,PageSizing,MenuHeader,HeaderRightBtn};