import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { MenuHeader, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper/modules';
import { ProductListBox, ProductListItem, ProductSelBox, ProductSelItem, ProductSelPriceBox } from "component/product/list";
import { BtnBox, BtnItem } from "component/basic/btns";
import { BottomErrMsg, Popup, ToastBasicContents, ToastPopup } from "component/basic/popup";
import { OptionSelect } from "component/basic/formItems";

const ShopList = (props) => {
    const navigate = useNavigate();

    const [id,setId] = useState("");
    const [qty,setQty] = useState("");
    const [slideIndex,setSlideIndex] = useState(0);
    const [btnChk,setBtnChk] = useState(true);
    const [toastPopupData,setToastPopupData] =  useState(null);
    const [toastSubPopupData,setToastSubPopupData] =  useState(null);
    const [popupData,setPopupData] =  useState(null);

    const [productPrice,setProductPrice] = useState(0);
    // const [deliveryPrice,setDeliveryPrice] = useState(0);
    const [totalPrice,setTotalPrice] = useState(0);

    const [selProduct,setSelProduct] = useState([]);

    const [headerUpdate,setHeaderUpdate] = useState(0);
    const [dataUpdate,setDataUpdate] = useState(0);

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const productApi = useGet({
        url:`/product?update=${dataUpdate}`,
        loginType:"login"
    });
    const productData = productApi?.data?.products;

    // const deliveryApi = useGet({
    //     url:`/product/delivery`,
    //     loginType:"login"
    // });
    // const deliveryData = deliveryApi?.data?.delivery_price;

    //옵션 있는 상품 선택
    function optionSel(optionData){
        let productSelDataSet = [...selProduct];
        let productPriceSet = 0;
        productSelDataSet.push({
            id:productData[slideIndex]?.id,
            qty:1,
            product_name:productData[slideIndex]?.product_name,
            product_name_id:productData[slideIndex]?.product_name_id,
            price:productData[slideIndex]?.sale_price ? productData[slideIndex]?.sale_price : productData[slideIndex]?.price,
            productTotalPrict:productData[slideIndex]?.sale_price ? (Number(productData[slideIndex]?.sale_price) + Number(optionData.option_price)) * 1 : (Number(productData[slideIndex]?.price) + Number(optionData.option_price)) * 1,
            thumb_image:productData[slideIndex]?.thumb_image,
            option_name:optionData.option_name,
            option_id:optionData.id,
            option_price:optionData.option_price,
            option_qty:optionData.qty,
        })
        setSelProduct(productSelDataSet);
        productSelDataSet.forEach(function(item,i){
            productPriceSet = productPriceSet + item.productTotalPrict;
            if(productSelDataSet.length - 1 == i){
                setProductPrice(productPriceSet)
            }
        });
    }

    //옵션 없는 상품 선택
    function orderOption(){
        if(!productData[slideIndex]?.options || productData[slideIndex]?.options.length == 0){
            let productSelDataSet = [{
                id:productData[slideIndex]?.id,
                qty:1,
                product_name:productData[slideIndex]?.product_name,
                product_name_id:productData[slideIndex]?.product_name_id,
                price:productData[slideIndex]?.sale_price ? productData[slideIndex]?.sale_price : productData[slideIndex]?.price,
                productTotalPrict:productData[slideIndex]?.sale_price ? productData[slideIndex]?.sale_price : productData[slideIndex]?.price,
                thumb_image:productData[slideIndex]?.thumb_image
            }]
            setSelProduct(productSelDataSet);
            setProductPrice(productData[slideIndex]?.sale_price ? productData[slideIndex]?.sale_price : productData[slideIndex]?.price)
        }else{
            setProductPrice(0)
        }

        setToastPopupData({
            addClass:"",
            title: productData[slideIndex]?.options && productData[slideIndex]?.options.length > 0 ? "Select Options" : "Select Quantity",
            closeType:true,
            closeFuncChk:true,
            closeFunc:(e) => {setSelProduct([]);setProductPrice(0)},
            btn0:"Add to Cart",
            btn0Type:"line",
            btn1:"Buy Now",
            btn1Type:"",
            btnFunc0:(e)=>{
                addCart()
            },
            btnFunc1:()=>{
                addOrder()
            },
        });
    }

    //바로 주문
    function addOrder(){
        if(selProduct.length == 0){
            setBottomMsgData({
                addClass : "",
                text : "Please select options first.",
                chk : bottomMsgData.chk + 1
            });
        }else{
            let nowUrl = new URL(window.location.href);
            sessionStorage.setItem("setUrl",nowUrl.pathname);
            navigate(localStorage.getItem("token") ? `/order/basic` : `/welCome`,{state: {type:"basic",productData: selProduct}});
        }
    }

    //장바구니 추가
    function addCart(){
        if(selProduct.length == 0){
            setBottomMsgData({
                addClass : "",
                text : "Please select options first.",
                chk : bottomMsgData.chk + 1
            });
        }else{
            let formData = new FormData();

            if(!localStorage.getItem("token")){
                formData.append("nonmember_key", localStorage.getItem("nonmemberKey"));
            }

            selProduct.forEach(function(item,i){
                formData.append("product_id[]", item.id);
                formData.append("option_id[]", item.option_id||"");
                formData.append("qty[]", item.qty||1);
            });

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/product/cart`,
                loginType: "login",
                success: (data) => {
                    setHeaderUpdate(headerUpdate + 1);
                    setDataUpdate(dataUpdate + 1);
                    setSelProduct([]);
                    setProductPrice(0);
                    setToastSubPopupData({
                        addClass:"",
                        title: null,
                        basicTitle:"Added to Cart!",
                        basicText:"Selected item has been added to your cart.",
                        closeType:true,
                        btn0:"View Cart",
                        btn0Type:"line",
                        btn1:"Continue Shopping",
                        btn1Type:"",
                        btnFunc0:(e)=>{
                            navigate("/cart")
                        },
                        btnFunc1:()=>{

                        },
                    });
                },
                err: (data) => {
                    if(data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.data||"",
                            text:data.alert,
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"Confirm",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                }
            })
        }
    }

    useEffect(() => {
        if((productData && productData[slideIndex]?.options 
            && productData[slideIndex]?.options?.length > 0 
            && productData[slideIndex]?.options?.filter((el)=>el.qty !== 0).length == 0) 
            || qty <= 0){
            setBtnChk(true)
        }else{
            setBtnChk(false)
        }
    }, [qty]);

    useEffect(() => {
        if(toastPopupData && selProduct.length > 0){
            let popupDataSet = {...toastPopupData}
            popupDataSet.btnFunc0 = (e)=>{addCart()}
            popupDataSet.btnFunc1 = (e)=>{addOrder()}
            setToastPopupData(popupDataSet)
        }
    }, [selProduct]);

    useEffect(() => {
        if(productData && productData.length > 0){
            setId(productData[slideIndex]?.id);
            setQty(productData[slideIndex]?.qty);
        }
    }, [productData]);

    useEffect(() => {
        if(productData && productData.length > 0){
            setId(productData[slideIndex]?.id);
            setQty(productData[slideIndex]?.qty);
        }
    }, [slideIndex]);

    // useEffect(() => {
    //     if(deliveryData){
    //         setDeliveryPrice(Number(deliveryData))
    //     }
    // }, [deliveryData]);

    useEffect(() => {
        // setTotalPrice(productPrice + deliveryPrice)
        setTotalPrice(productPrice)
    }, [productPrice]);

    return (
        <PageSizing addClass="gyBg bottomBtn">
            <MenuHeader
                languageCh={(type)=>{props.languageCh(type)}}
                language={props.language}
                update={headerUpdate}
            />
            <div className="productList">
                <Swiper
                    spaceBetween={10}
                    slidesPerView={1}
                    pagination={true}
                    loop={true}
                    onRealIndexChange={(e) => {setSlideIndex(e.realIndex||0);setSelProduct([]);setProductPrice(0)}}
                    modules={[Pagination]}
                >
                    {productData && productData?.map((item, i) => (
                        <SwiperSlide key={i}>
                            <ProductListBox addClass="btnMinLinkBox" bulkLink={true} func={() => {
                                sessionStorage.setItem("referrer",true);
                                navigate(`/shop/detail/${item.id}/0`);
                            }}>
                                <ProductListItem
                                    thumb_image={item.thumb_image}
                                    product_name={props.language == "en" ? item.product_name : item.product_name_id}
                                    description={props.language == "en" ? item.description : item.description_id}
                                    price={item.price}
                                    sale_price={item.sale_price}
                                />
                            </ProductListBox>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <BtnBox
                boxType="fixed"
                addClass="notBg"
            >
                <BtnItem
                    addClass=""
                    contents={btnChk ? "Sold Out" : "Add to Cart"}
                    disabled={btnChk}
                    func={() => {orderOption()}}
                />
            </BtnBox>
            <ToastPopup
                data={toastPopupData}
            >
                <ProductSelBox>
                    {productData ? productData[slideIndex]?.options && productData[slideIndex]?.options.length > 0 ? 
                    <div>
                        <OptionSelect
                            inputName=""
                            placeholder="Select Options"
                            selData={selProduct}
                            func={(e) => {optionSel(e)}}
                            options={productData[slideIndex]?.options}
                            boxAddClass={"productOptionItemBox"}
                        />
                        {
                            selProduct.length > 0 ? 
                            <div>
                            {selProduct?.map((item, i) => (
                                <ProductSelItem
                                    key={i}
                                    del={true}
                                    delFunc={()=>{
                                        let productSelDataSet = [...selProduct];
                                        let delProductData = productSelDataSet?.filter((dataArr)=>{return dataArr.option_id !== item.option_id});
                                        let productPriceSet = 0;

                                        if(delProductData.length == 0){
                                            setSelProduct(delProductData);
                                            setProductPrice(0);
                                        }else{
                                            delProductData.forEach(function(productItem,idx){
                                                productPriceSet = productPriceSet + productItem.productTotalPrict;

                                                if(delProductData.length - 1 == idx){
                                                    setSelProduct(delProductData);
                                                    setProductPrice(productPriceSet);
                                                }
                                            });
                                        }
                                    }}
                                    id={item.option_id}
                                    name={item.option_name}
                                    notranslate={false}
                                    price={productData[slideIndex]?.sale_price ? Number(productData[slideIndex]?.sale_price) + item.option_price : Number(productData[slideIndex]?.price) + item.option_price}
                                    countVal={item.qty ? item.qty : 1}
                                    qty={item.option_qty}
                                    countFunc={(val)=>{
                                        let productSelDataSet = [...selProduct];
                                        let productPriceSet = 0;
                                        productSelDataSet.forEach(function(productItem,idx){
                                            if(item.option_id == productItem.option_id){
                                                productSelDataSet[idx].qty = val;
                                                productSelDataSet[idx].productTotalPrict = productData[slideIndex]?.sale_price ? (Number(productData[slideIndex]?.sale_price) + Number(productItem.option_price)) * val : (Number(productData[slideIndex]?.price) + Number(productItem.option_price)) * val;
                                            }

                                            productPriceSet = productPriceSet + productItem.productTotalPrict;

                                            if(productSelDataSet.length - 1 == idx){
                                                setSelProduct(productSelDataSet);
                                                setProductPrice(productPriceSet);
                                            }
                                        });
                                    }}
                                    excessFunc={()=>{
                                        setBottomMsgData({
                                            addClass : "",
                                            text : "Exceeded available quantity.",
                                            chk : bottomMsgData.chk + 1
                                        });
                                    }}
                                    excessFunc_max={()=>{
                                        setBottomMsgData({
                                            addClass : "",
                                            text : "Please inquire separately for bulk purchases!",
                                            chk : bottomMsgData.chk + 1
                                        });
                                    }}
                                />
                            ))}
                            </div>
                            :<p className="notOptionText">Please select options.</p>
                        }
                    </div>
                    : 
                    <ProductSelItem
                        del={false}
                        delFunc={()=>{}}
                        id={productData[slideIndex]?.id}
                        name={props.language == "en" ? productData[slideIndex]?.product_name : productData[slideIndex]?.product_name_id}
                        notranslate={true}
                        price={productData[slideIndex]?.sale_price ? productData[slideIndex]?.sale_price : productData[slideIndex]?.price}
                        countVal={selProduct[0]?.qty ? selProduct[0]?.qty : 1}
                        qty={productData[slideIndex]?.qty}
                        countFunc={(val)=>{
                            let productSelDataSet = [{
                                id:productData[slideIndex]?.id,
                                qty:val,
                                product_name:productData[slideIndex]?.product_name,
                                product_name_id:productData[slideIndex]?.product_name_id,
                                price:productData[slideIndex]?.sale_price ? productData[slideIndex]?.sale_price : productData[slideIndex]?.price,
                                productTotalPrict:productData[slideIndex]?.sale_price ? Number(productData[slideIndex]?.sale_price) * val : Number(productData[slideIndex]?.price) * val,
                                thumb_image:productData[slideIndex]?.thumb_image
                            }]
                            setSelProduct(productSelDataSet);
                            setProductPrice(productData[slideIndex]?.sale_price ? Number(productData[slideIndex]?.sale_price) * val : Number(productData[slideIndex]?.price) * val);
                        }}
                        excessFunc={()=>{
                            setBottomMsgData({
                                addClass : "",
                                text : "Exceeded available quantity.",
                                chk : bottomMsgData.chk + 1
                            });
                        }}
                        excessFunc_max={()=>{
                            setBottomMsgData({
                                addClass : "",
                                text : "Please inquire separately for bulk purchases!",
                                chk : bottomMsgData.chk + 1
                            });
                        }}
                    /> : ""}
                    <ProductSelPriceBox
                        productPrice={productPrice}
                        // deliveryPrice={deliveryPrice}
                        TotalPrice={totalPrice}
                    />
                </ProductSelBox>
            </ToastPopup>

            <ToastPopup data={toastSubPopupData} closeType={true}>
                <ToastBasicContents
                    title={toastSubPopupData?.basicTitle ? toastSubPopupData?.basicTitle : ""}
                    text={toastSubPopupData?.basicText ? toastSubPopupData?.basicText : ""}
                />
            </ToastPopup>

            <BottomErrMsg
                addClass={bottomMsgData.addClass ? bottomMsgData.addClass : ""}
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />

            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default ShopList;