import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { InputItemBox } from "component/basic/formItems";
import { Popup } from "component/basic/popup";
import { Header, PageSizing } from "component/elements/header";
import { LoginSubLink, MemberInputSection, MemberPage } from "component/member/items";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Login = (props) => {
    const navigate = useNavigate();

    const [id,setId] =  useState("");
    const [pw,setPw] =  useState("");

    const [errPopupData,setErrPopupData] =  useState(null);
    const [loginErr,setLoginErr] =  useState(null);

    //에러 제거
    useEffect(() => {
        setLoginErr(null);
    }, [id,pw]);

    //로그인
    function loginStart(){
        let formData = new FormData();
        formData.append("email", id);
        formData.append("password", pw);
        formData.append("nonmember_key", localStorage.getItem("nonmemberKey"));

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/login",
            success: (data) => {
                localStorage.setItem("token",data.data.token.access_token);
                localStorage.setItem("refresh_token",data.data.token.refresh_token);

                localStorage.removeItem("nonmemberKey");

                props.logIn();

                if(sessionStorage.getItem("setUrl")){
                    let reUrl = sessionStorage.getItem("setUrl");
                    sessionStorage.removeItem("setUrl");
                    navigate(reUrl);
                }else{
                    navigate(`/main`);
                }
            },
            err: (data) => {
                if(data.alert){
                    setLoginErr(data.alert);
                }
            }
        })
    }

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="Log in by email"
                centerTitle={true}
            />
            <MemberPage addClass="bottomContents">
                <div className="logoBox">
                    <img src="/assets/images/img/logo.svg"/>
                </div>
                <MemberInputSection>
                    <InputItemBox
                        inputType={null}
                        addClass={loginErr ? "err" : ""}
                        inputName="ID"
                        placeholder="example@gmail.com"
                        value={id}
                        max={null}
                        regexp={null}
                        func={(value)=>{setId(value)}}
                    />
                    <InputItemBox
                        inputType="password"
                        addClass=""
                        inputName="Password"
                        placeholder="********"
                        value={pw}
                        max={null}
                        regexp={null}
                        errMsg={loginErr}
                        func={(value)=>{setPw(value)}}
                        caption="8 to 20 characters containing a combination of English letters, numbers, and special characters"
                    />
                    <div className="loginSubLinkBox">
                        <LoginSubLink func={()=>{navigate("/member/find/email")}} contents="Password Recovery"/>
                        <span></span>
                        <LoginSubLink func={()=>{navigate("/member/terms/email")}} contents="Create an account with email"/>
                    </div>
                </MemberInputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents="Login"
                        disabled={false}
                        func={() => {loginStart()}}
                    />
                </BtnBox>
            </MemberPage>
            <Popup
                data={errPopupData}
            />
        </PageSizing>
    );
};

export default Login;