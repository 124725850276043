import { BtnItem } from "component/basic/btns";
import { Header, PageSizing } from "component/elements/header";
import { LoginSubLink, MemberInputSection, MemberPage } from "component/member/items";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryStringify from "qs-stringify";
import { BottomErrMsg, Popup } from "component/basic/popup";

const WelCome = () => {
    const navigate = useNavigate();
    const state = useLocation();
    const type = state?.state?.type||null;
    const productData = state?.state?.productData||null;

    const [errPopupData,setErrPopupData] =  useState(null);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    //애플
    function appleLoginStart(){
        // window.location.href = `https://appleid.apple.com/auth/authorize?client_id=${process.env.REACT_APP_APPLE_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_APPLE_REDIRECT_URL}&scope=email&response_mode=form_post`;
    }

    //구글
    const googleLoginStart = () => {    
        const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
        const REDIRECT_URL = process.env.REACT_APP_GOOGLE_REDIRECT_URL
        const AUTHORIZE_URI = 'https://accounts.google.com/o/oauth2/v2/auth'
    
        const queryStr = queryStringify({
            client_id: CLIENT_ID,
            redirect_uri: REDIRECT_URL,
            response_type: 'token',
            prompt: 'select_account',
            scope:'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
        })
    
        window.location.href = AUTHORIZE_URI + '?' + queryStr
    }

    //페이스북
    function fbLoginStart(){
        window.location.href = `https://www.facebook.com/dialog/oauth/?scope=public_profile,email&client_id=${process.env.REACT_APP_FB_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_FB_REDIRECT_URL}&response_type=token`;
    }

    useEffect(() => {
        window.AppleID.auth.init({
            clientId : `${process.env.REACT_APP_APPLE_CLIENT_ID}`,
            scope : 'email',
            redirectURI : `${process.env.REACT_APP_APPLE_REDIRECT_URL}`,
            state : '',
            nonce : '',
            usePopup : true
        });
    }, []);

    useEffect(() => {
        document.addEventListener('AppleIDSignInOnSuccess', (data) => {
            window.location.href = `${process.env.REACT_APP_APPLE_REDIRECT_URL}?code=${data?.detail?.authorization?.code}`
        });
        document.addEventListener('AppleIDSignInOnFailure', (event) => {console.log(event.detail.error)});
        return () => {
            document.removeEventListener('AppleIDSignInOnSuccess', (data) => {
                window.location.href = `${process.env.REACT_APP_APPLE_REDIRECT_URL}?code=${data?.detail?.authorization?.code}`
            });
            document.addEventListener('AppleIDSignInOnFailure', (event) => {console.log(event.detail.error)});
        }
    }, []);

    return (
        <PageSizing addClass="headerPage fixedPage">
            <Header 
                addClass="notBg"
                rightBtns={true}
                rightBtnsArr={[
                    {
                        type:"img",
                        contents:"/assets/images/basic/close_w.svg",
                        addClass:"",
                        func:() => {navigate(-1)}
                    }
                ]}
            />
            <MemberPage addClass="bottomContents gBg notBtn">
                <div className="logoBox">
                    <img src="/assets/images/img/logo_w.svg"/>
                </div>
                <MemberInputSection>
                    <BtnItem
                        addClass={`apple snsBtn`}
                        contents="<span>Sign in with Apple</span><p id='appleid-signin' class='appleSnsButton'></p>"
                        disabled={false}
                        func={() => {appleLoginStart()}}
                    />
                    <BtnItem
                        addClass={`google snsBtn`}
                        contents="Sign in with Google"
                        disabled={false}
                        func={() => {googleLoginStart()}}
                    />
                    <BtnItem
                        addClass={`fb snsBtn`}
                        contents="Sign in with Facebook"
                        disabled={false}
                        func={() => {fbLoginStart()}}
                    />
                    <BtnItem
                        addClass={"nonBtn"}
                        contents="Sign in with Email"
                        disabled={false}
                        func={() => {navigate("/member/login/0")}}
                    />
                    {productData ? 
                    <div className="loginSubLinkBox welCome_orderBox">
                        <LoginSubLink func={()=>{navigate(`/order/${type}`,{state: {type:type,productData: productData}})}} contents="Non-member Purchase"/>
                        <span></span>
                        <LoginSubLink func={()=>{navigate("/inquiry")}} contents="Non-member Order Inquiry"/>
                    </div>:""}
                </MemberInputSection>
            </MemberPage>
            <BottomErrMsg
                addClass={bottomMsgData.addClass ? bottomMsgData.addClass : ""}
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </PageSizing>
    );
};

export default WelCome;