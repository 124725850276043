import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BtnBox, BtnItem } from "component/basic/btns";

const SurveyCom = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        
    }, []);

    return (
        <PageSizing addClass="flexCenter bottomBtn">
            <img src="/assets/images/img/survey_com_icon.svg" className="surveyCom_img"/>
            <p className="surveyCom_text">All surveys have been completed!</p>
            <BtnBox
                boxType="fixed"
                addClass=""
            >
                <BtnItem
                    addClass=""
                    contents={"Confirm"}
                    disabled={false}
                    func={() => {navigate("/")}}
                />
            </BtnBox>
        </PageSizing>
    );
};

export default SurveyCom;