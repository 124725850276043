//버튼 상단 텍스트
function BtnText(data){
    return (
        <p className={`btnTextType ${data.addClass ? data.addClass : ""}`} dangerouslySetInnerHTML={{__html:data.text}}></p>
    );
}

//버튼 박스
function BtnBox(data){
    return (
        <div className={`${data.boxType == "fixed" ? "pageBtn_box" : "btn_box"} ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

//서브 버튼 박스(버튼 상단 별도 컨텐츠 있을 경우)
function BtnSubBox(data){
    return (
        <div className={`btn_subBox ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

//버튼
function BtnItem(data){
    return (
        <button type="button" id={data.tagId ? data.tagId : ""} className={`pageBtn ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.contents}}></button>
    );
}

//버튼(링크)
function BtnItemLink(data){
    return (
        <a className={`pageBtn ${data.addClass ? data.addClass : ""}`} href={data.href} dangerouslySetInnerHTML={{__html:data.contents}}></a>
    );
}

//토클버튼
function ToggleBtn(data){
    return (
        <div className="toggleBox">
            <input type="checkbox" onChange={(e)=>{data.chkFunc(e.target.checked)}} id={data.id} checked={data.checkedType} className="toggleChk"/>
            <label htmlFor={data.id}>
                <span></span>
            </label>
        </div>
    );
}

//텍스트 버튼
function TextBtnItem(data){
    return (
        <>
            {data.type == "link" ?
            <a href={data.link} target="_blank" className={`textBtn ${data.addClass ? data.addClass : ""}`} disabled={data.disabled ? data.disabled : false}>
                <p className="textBtn_contents" dangerouslySetInnerHTML={{__html:data.contents}}/>
                <img src="/assets/images/basic/move.svg"/>
            </a>
            :
            <button type="button" className={`textBtn ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false}>
                <p className="textBtn_contents" dangerouslySetInnerHTML={{__html:data.contents}}/>
                <img src="/assets/images/basic/move.svg"/>
            </button>
            }
        </>
        
    );
}

//텍스트 버튼 v2
function MoreBtnItem(data){
    return (
        <div className="moreBtnBox" style={{margin:data.margin}}>
            <button type="button" className={`moreBtn ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false}>
                <p className="moreBtn_contents" dangerouslySetInnerHTML={{__html:data.contents}}/>
                <img src={data.img ? data.img : "/assets/images/basic/more_icon.svg"}/>
            </button>
        </div>
    );
}

//정렬 버튼
function AlignBtnItem(data){
    return (
        <button type="button" className={`alignBtn ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false}>
            <p className="alignBtn_contents" dangerouslySetInnerHTML={{__html:data.contents}}/>
            <img src={data.img}/>
        </button>
    );
}

//작은 버튼
function MinBtnBtnItem(data){
    return (
        <button type="button" className={`minBtn ${data.addClass ? data.addClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false}>
            <p className="minBtn_contents" dangerouslySetInnerHTML={{__html:data.contents}}/>
        </button>
    );
}

export {BtnBox,BtnSubBox,BtnItem,BtnItemLink,BtnText, ToggleBtn,TextBtnItem,MoreBtnItem,AlignBtnItem,MinBtnBtnItem};