import { MinBtnBtnItem } from "component/basic/btns";
import { OrderAddrAddr, OrderAddrInfo, ProductItem } from "component/product/detail";
import { ProductSelPriceData } from "component/product/list";
import { comFormat, whatsappOpen } from "js/function";
import { useNavigate } from "react-router-dom";

//반송 페이지 타이틀
function ReturnTitleBox(data){
    return (
        <div className={`returnTitleBox ${data.addClass ? data.addClass : ""}`}>
            {data.icon ? <img src={`/assets/images/main/return_icon.svg`}/> : ""}
            <h1 className="returnTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
        </div>
    ); 
}

//반송 페이지 컨텐츠
function ReturnContentsTitle(data){
    return (
        <h1 className="returnContentsTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
    ); 
}

function ReturnContentsBox(data){
    return (
        <div className={`returnContentsBox ${data.addClass ? data.addClass : ""}`}>
            {data.title ? 
            <div className="returnContentsTitleBox">
                <ReturnContentsTitle title={data.title}/>
                {data.func ? <MinBtnBtnItem
                    contents="Edit"
                    func={()=>{data.func()}}
                />:""}
            </div>
            :""}
            {data.product ? 
                <div className="returnProductInfo">
                    <ProductItem
                        img={data.product?.thumb_image}
                        name={data.product?.product_name}
                        options={data.product?.option_name ? `${data.product?.option_name} / ${data.product?.qty ? comFormat(data.product?.qty) : 1}EA` : `${data.product?.qty ? comFormat(data.product?.qty) : 1}EA`}
                        priceClass=""
                        sale_price={null}
                        price={null}
                    />
                </div>
            :""}
            <div className="returnContentsItem">
                {data.children}
            </div>
        </div>
    ); 
}

//반송 안내
function ReturnTextItemBox(data){
    return (
        <div className={`returnTextItemBox ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    ); 
}

function ReturnTextItem(data){
    return (
        <div className={`returnTextItem ${data.addClass ? data.addClass : ""}`}>
            <img src={`/assets/images/main/return_text_icon.svg`}/>
            <div className="returnTextBox">
                <h1 className="returnText" dangerouslySetInnerHTML={{__html:data.text}}/>
                {data.caption ? <p className="returnTextCaption" dangerouslySetInnerHTML={{__html:data.caption}}/> : ""}
            </div>
        </div>
    ); 
}

//반송 주소 확인
function ReturnAddrItem(data){
    return (
        <div>
            <h1 className="returnAdd_name">{data.editName}</h1>
            <h2 className="returnAdd_phone">{data.editPhone}</h2>
            <p className="returnAdd_addr">{data.editAddrDetail}, {data.subdistrict?.text}, {data.district?.text}, {data.city?.text}, {data.province?.text}</p>
        </div>
    ); 
}

//반송 완료
function ReturnSuccessItem(data){
    const navigate = useNavigate();

    return (
        <div>
            <ReturnContentsBox>
                <h1 className="returnSuccess_title"><i>The registered return information cannot be modified. If you entered incorrect information, please contact</i><i className="notranslate"> </i><button type="button" onClick={()=>{whatsappOpen()}}>customer service.</button></h1>
            </ReturnContentsBox>
            <ReturnContentsBox title={"Return Information"}>
                <OrderAddrInfo info={data.info}/>
                <OrderAddrAddr addr={data.addr}/>
            </ReturnContentsBox>
            <div className="returnSuccess_info">
                <ProductSelPriceData name="Quantity" val={data.qty} notranslate={true} addClass=""/>
                <ProductSelPriceData name="Return Date" val={data.date} notranslate={true} addClass=""/>
                {data.parcelServiceName ? <ProductSelPriceData name="Courier Used" val={data.parcelServiceName} notranslate={true} addClass=""/> : ""}
                {data.transportNumber ? <ProductSelPriceData name="Tracking Number" val={data.transportNumber} notranslate={true} addClass=""/> : ""}
            </div>
        </div>
    ); 
}

export {ReturnTitleBox,ReturnContentsBox,ReturnContentsTitle,ReturnTextItemBox,ReturnTextItem,ReturnAddrItem,ReturnSuccessItem};