import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InputTap } from "component/basic/tap";
import { ageSetting } from "js/function";
import { Popup, SelPopup, ToastBasicContents, ToastPopup } from "component/basic/popup";
import { PageSection } from "component/app/items";
import { BtnBox, BtnItem } from "component/basic/btns";
import { InputBoxOnly, InputItemBox } from "component/basic/formItems";
import { MemberInputSection, MemberPage } from "component/member/items";
import { SurveyCover, SurveyItem, SurveySection, SurveyTitleBox } from "component/report/survey";
import countryData from "../../data/data.json";

const Survey = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;
    const type = param.type;
    const surveyType = param.surveyType;
    
    const basicSurveyApi = useGet({
        url:`/survey/view?barcode_id=${id}`,
        loginType:"login"
    });
    const basicSurveyData = basicSurveyApi?.data?.user_survey;
    const basicSurveyInfo = basicSurveyApi?.data?.user_info;
    
    const eventSurveyApi = useGet({
        url:`/survey/add/view?barcode_id=${id}`,
        loginType:"login"
    });
    const eventSurveyData = eventSurveyApi?.data?.user_survey;

    const [pageType,setPageType] = useState(surveyType);
    const [page,setPage] = useState(0);
    const [nextPageNum,setNextPageNum] = useState(0);
    const [skipPageNum,setSkipPageNum] = useState(null);

    const [btnChk,setBtnChk] = useState(true);
    
    const [basicInfo,setBasicInfo] = useState({
        birth:new Date().getFullYear(),
        gender:"M",
        nickname:"",
        name:""
    });
    const [basicSurvey,setBasicSurvey] = useState([]);
    const [eventSurvey,setEventSurvey] = useState([]);

    const [yearSetting,setYearSetting] = useState([]);
    
    const [popupData,setPopupData] =  useState(null);
    const [selPopupData,setSelPopupData] =  useState(null);
    const [toastSubPopupData,setToastSubPopupData] =  useState(null);

    function basicInfoSetting(key,val){
        let basicInfoSet = {...basicInfo};
        basicInfoSet[key] = val;
        setBasicInfo(basicInfoSet);
    }

    //설문 제출
    function surveySendApi(formData,url,returnType,saveType){
        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: "login",
            url: url,
            success: (data) => {
                if(returnType == "basic"){
                    setBtnChk(false);
                    if(saveType == 1){
                        navigate("/main");
                    }else if(ageSetting(basicInfo.birth) < 3){
                        navigate("/survey/success");
                    }else{
                        setPageType("event");
                        setPage(0);
                        setNextPageNum(0);
                    }
                }else{
                    if(data.alert == "Please Conduct the basic survey first."){
                        setPopupData({
                            addClass:null,
                            title:"",
                            text:"Please Conduct the basic survey first.",
                            closeType:false,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{navigate("/main")},
                            btn0:"Confirm",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }else if(saveType == 1){
                        navigate("/main");
                    }else{
                        navigate("/survey/success");
                    }
                }
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{
                            if(data.alert == "Please Conduct the basic survey first."){
                                navigate("/main");
                            }
                        },
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //기본 설문
    function basicSurveySend(saveType){
        let formData = new FormData();
        formData.append("barcode_id", id);
        formData.append("birth", basicInfo?.birth);
        formData.append("gender", basicInfo?.gender);

        if(type !== "me"){
            formData.append("baby_nickname", basicInfo?.nickname);
            formData.append("baby_name", basicInfo?.name);
        }

        formData.append("is_save", saveType);

        if(basicSurvey.length > 0){
            basicSurvey.forEach(function(item,i){
                formData.append(`survey[${item.id}]`, item.value||"");

                if(basicSurvey.length - 1 == i){
                    surveySendApi(formData,"/survey","basic",saveType);
                }
            });
        }else{
            surveySendApi(formData,"/survey","basic",saveType);
        }
    }

    //이벤트 설문
    function eventSurveySend(saveType){
        let formData = new FormData();
        formData.append("barcode_id", id);
        formData.append("is_save", saveType);

        if(eventSurvey.length > 0){
            eventSurvey.forEach(function(item,i){
                formData.append(`add_survey[${item.id}]`, item.value||"");

                if(eventSurvey.length - 1 == i){
                    surveySendApi(formData,"/survey/add","event",saveType);
                }
            });
        }else{
            surveySendApi(formData,"/survey/add","event",saveType);
        }
    }

    //설문 버튼 유효성 체크
    function btnDisabledChk(){
        let nowDataSet = pageType == "basic" ? basicSurvey?.filter((el)=>el.pageId == page)[0] : eventSurvey?.filter((el)=>el.pageId == page)[0];
        if(nowDataSet){
            if(nowDataSet?.type == "basic"){
                if(nowDataSet?.value && nowDataSet?.value !== ""){
                    setBtnChk(false)
                }else{
                    setBtnChk(true)
                }
            }else if(nowDataSet?.type == "nationSel"){
                if(
                    nowDataSet?.value && nowDataSet?.value !== "" && nowDataSet?.value?.split("&&")[0] && nowDataSet?.value?.split("&&").length >= 3
                    && nowDataSet?.value?.split("&&")[0] !== "" && nowDataSet?.value?.split("&&")[1] !== "" && nowDataSet?.value?.split("&&")[2] !== ""
                ){
                    setBtnChk(false)
                }else{
                    setBtnChk(true)
                }
            }else if(nowDataSet?.type == "chkItem"){
                let btnChkSetting = false;
                nowDataSet?.options?.forEach(function(item,i){
                    let yearSelectSet = false,
                        textAreaSet = false;
                    let nowDataValue = nowDataSet?.value && nowDataSet?.value !== "" && nowDataSet?.value?.split("||")[0] ? nowDataSet?.value?.split("||") : [],
                        optionItemArr = item?.split("&&"),
                        settingIndex = nowDataValue.findIndex(function(item, index, arr){
                            return item.split("&&")[0] == optionItemArr[0];
                        });
                    let nowValues = nowDataValue[settingIndex] && nowDataValue[settingIndex].split("&&")[0] ? nowDataValue[settingIndex].split("&&") : [];

                    if(optionItemArr.filter((el) => el == "yearSelectSet")[0]){
                        yearSelectSet = true;
                    }else{
                        yearSelectSet = false;
                    }
                    if(optionItemArr.filter((el) => el == "textAreaSet")[0]){
                        textAreaSet = true;
                    }else{
                        textAreaSet = false;
                    }
                    if(!btnChkSetting){
                        if(nowDataValue[0]){
                            if(nowValues[0]){
                                if(yearSelectSet && textAreaSet){
                                    if(nowValues[0] && nowValues[0] !== "" && nowValues[1] && nowValues[1] !== "" && nowValues[2] && nowValues[2] !== ""){
                                        btnChkSetting = false;
                                        setBtnChk(false);
                                    }else{
                                        btnChkSetting = true;
                                        setBtnChk(true);
                                        return false;
                                    }
                                }else if(yearSelectSet || textAreaSet){
                                    if(nowValues[0] && nowValues[0] !== "" && nowValues[1] && nowValues[1] !== ""){
                                        btnChkSetting = false;
                                        setBtnChk(false);
                                    }else{
                                        btnChkSetting = true;
                                        setBtnChk(true);
                                        return false;
                                    }
                                }else if(nowValues[0] && nowValues[0] !== ""){
                                    btnChkSetting = false;
                                    setBtnChk(false);
                                }else{
                                    btnChkSetting = true;
                                    setBtnChk(true);
                                    return false;
                                }
                            }
                        }else{
                            btnChkSetting = true;
                            setBtnChk(true)
                            return false;
                        }
                    }
                });
            }
        }else{
            setBtnChk(true)
        }
    }

    function btnDisabledBackPageChk(item){
        let nowDataSet = item;
        let chkType = true;

        if(nowDataSet){
            if(nowDataSet?.type == "basic"){
                if(nowDataSet?.value && nowDataSet?.value !== ""){
                    chkType = false;
                }else{
                    chkType = true;
                }
            }else if(nowDataSet?.type == "nationSel"){
                if(
                    nowDataSet?.value && nowDataSet?.value !== "" && nowDataSet?.value?.split("&&")[0] && nowDataSet?.value?.split("&&").length >= 3
                    && nowDataSet?.value?.split("&&")[0] !== "" && nowDataSet?.value?.split("&&")[1] !== "" && nowDataSet?.value?.split("&&")[2] !== ""
                ){
                    chkType = false;
                }else{
                    chkType = true;
                }
            }else if(nowDataSet?.type == "chkItem"){
                let btnChkSetting = false;
                nowDataSet?.options?.forEach(function(item,i){
                    let yearSelectSet = false,
                        textAreaSet = false;
                    let nowDataValue = nowDataSet?.value && nowDataSet?.value !== "" && nowDataSet?.value?.split("||")[0] ? nowDataSet?.value?.split("||") : [],
                        optionItemArr = item?.split("&&"),
                        settingIndex = nowDataValue.findIndex(function(item, index, arr){
                            return item.split("&&")[0] == optionItemArr[0];
                        });
                    let nowValues = nowDataValue[settingIndex] && nowDataValue[settingIndex].split("&&")[0] ? nowDataValue[settingIndex].split("&&") : [];

                    if(optionItemArr.filter((el) => el == "yearSelectSet")[0]){
                        yearSelectSet = true;
                    }else{
                        yearSelectSet = false;
                    }
                    if(optionItemArr.filter((el) => el == "textAreaSet")[0]){
                        textAreaSet = true;
                    }else{
                        textAreaSet = false;
                    }
                    if(!btnChkSetting){
                        if(nowDataValue[0]){
                            if(nowValues[0]){
                                if(yearSelectSet && textAreaSet){
                                    if(nowValues[0] && nowValues[0] !== "" && nowValues[1] && nowValues[1] !== "" && nowValues[2] && nowValues[2] !== ""){
                                        btnChkSetting = false;
                                        chkType = false;
                                    }else{
                                        btnChkSetting = true;
                                        chkType = true;
                                    }
                                }else if(yearSelectSet || textAreaSet){
                                    if(nowValues[0] && nowValues[0] !== "" && nowValues[1] && nowValues[1] !== ""){
                                        btnChkSetting = false;
                                        chkType = false;
                                    }else{
                                        btnChkSetting = true;
                                        chkType = true;
                                    }
                                }else if(nowValues[0] && nowValues[0] !== ""){
                                    btnChkSetting = false;
                                    chkType = false;
                                }else{
                                    btnChkSetting = true;
                                    chkType = true;
                                }
                            }
                        }else{
                            btnChkSetting = true;
                            chkType = true;
                        }
                    }
                });
            }
        }else{
            chkType = true;
        }

        return chkType;
    }

    //페이지 이탈 이벤트
    function backEventPopup(){
        setToastSubPopupData({
            addClass:"",
            title: null,
            basicTitle:"<i>Would you like to do it next time?</i><br/><i>You can continue anytime.</i>",
            basicText:null,
            closeType:true,
            btn0:"Stay",
            btn0Type:"line",
            btn1:"Save and Exit",
            btn1Type:"",
            btnFunc0:(e)=>{
                // setTimeout(() => {
                //     setPopupData({
                //         addClass:null,
                //         title:"Would you like to do it next time?",
                //         text:"<i>If you leave here, you have to start over.</i><br/><i>All entered answers will be deleted.</i>",
                //         closeType:true,
                //         closeFunc:(e) => {},
                //         btnFunc0:(e)=>{
                //             navigate("/main");
                //         },
                //         btn0:"Leave",
                //         btn0Type:"line",
                //         btn1:"Stay",
                //         btn1Type:"",
                //         btnFunc1:()=>{}
                //     });
                // }, 200);
            },
            btnFunc1:()=>{
                if(pageType == "basic"){
                    basicSurveySend(1);
                }else{
                    eventSurveySend(1);
                }
            },
        });
    }

    function backEvent(){
        window.history.pushState(null, '', window.location.href);
        backEventPopup();
    }
     
    useEffect(() => {
         window.history.pushState(null, '', window.location.href)
         window.addEventListener('popstate', backEvent);
         return () => {
           window.removeEventListener('popstate', backEvent);
         }
    }, [])

    //기본 정보 입력 뒤로가기 접근 유효성 체크
    function basicInfoBtnChk(){
        if(type !== "me"){
            if(basicInfo?.birth !== "" && basicInfo?.gender !== "" && basicInfo?.nickname !== "" && basicInfo?.name !== ""){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }else{
            if(basicInfo?.birth !== "" && basicInfo?.gender !== ""){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }
    }

    useEffect(() => {
        basicInfoBtnChk();
    }, [basicInfo]);

    useEffect(() => {
        if(pageType == "basic" && page == 1){
            basicInfoBtnChk();
        }else{
            btnDisabledChk();
        }
    }, [basicSurvey,eventSurvey,page]);

    useEffect(() => {
        let surveyInfoSaveData = [];

        if(basicSurveyData && basicSurveyData[0]){
            let surveyData = [...basicSurveyData];
            setBasicSurvey(surveyData);

            surveyInfoSaveData = surveyData.filter((el)=> el.value == null);
            if(pageType == "basic"){
                if(surveyInfoSaveData.length > 0){
                    let fId = Number(surveyInfoSaveData[0].pageId);
                    if(fId <= 2){
                        setPage(0);
                    }else{
                        if(btnDisabledBackPageChk(surveyData?.filter((el)=>el.pageId == (fId - 1))[0])){
                            setPage(fId - 1);
                        }else{
                            setPage(fId);
                        }
                    }
                }else{
                    setPage(surveyData[surveyData.length - 1].pageId);
                }
            }
        }else if(basicSurveyApi?.alert){
            navigate("/pageErr");
        }

        if(basicSurveyInfo){
            let basicInfoSetting = {
                birth:basicSurveyInfo?.birth||new Date().getFullYear(),
                gender:basicSurveyInfo?.gender||"M",
                nickname:basicSurveyInfo?.nickname||"",
                name:basicSurveyInfo?.name||""
            }
            setBasicInfo(basicInfoSetting);
        }
    }, [basicSurveyApi]);

    useEffect(() => {
        let surveyInfoSaveData = [];

        if(eventSurveyData && eventSurveyData[0]){
            let surveyData = [...eventSurveyData];
            setEventSurvey(surveyData);

            surveyInfoSaveData = surveyData.filter((el)=> el.value == null);
            if(pageType == "event"){
                if(surveyInfoSaveData.length > 0){
                    let fId = Number(surveyInfoSaveData[0].pageId);
                    if(fId <= 1){
                        setPage(0);
                    }else{
                        if(btnDisabledBackPageChk(surveyData?.filter((el)=>el.pageId == (fId - 1))[0])){
                            setPage(fId - 1);
                        }else{
                            setPage(fId);
                        }
                    }
                }else{
                    setPage(surveyData[surveyData.length - 1].pageId);
                }
            }
        }
    }, [eventSurveyApi]);

    useEffect(() => {
        let yearData = [];
        let nowYear = new Date().getFullYear();
        for(var i = 1900; i <= nowYear; i++){
            yearData.push(i);
            if(i == nowYear){
                setYearSetting(yearData.sort(function(a, b){return b - a}));
            }
        }
    }, []);

    return (
        <PageSizing>
            <Header 
                pageBack={page > 0 ? true : false}
                func={() => {
                    if(
                        (pageType == "basic" && page > 3 && (basicSurvey.filter((item)=>item.pageId == page - Number(skipPageNum||2))[0] && basicSurvey.filter((item)=>item.pageId == page - Number(skipPageNum||2))[0].nextChk) && basicSurvey.filter((item)=>item.pageId == page - Number(skipPageNum||2))[0].value == "No")
                        || (pageType !== "basic" && page > 2 && (eventSurvey.filter((item)=>item.pageId == page - Number(skipPageNum||2))[0] && eventSurvey.filter((item)=>item.pageId == page - Number(skipPageNum||2))[0].nextChk) && eventSurvey.filter((item)=>item.pageId == page - Number(skipPageNum||2))[0].value == "No")
                    ){
                        setNextPageNum(page);setPage(page - (skipPageNum||1));
                    }else{
                        setNextPageNum(page);setPage(page - 1);
                    }
                }}
                closeFunc={() => {
                    if(pageType == "basic" && page == 0){
                        navigate("/main")
                    }else{
                        backEvent();
                    }
                }}
                headTitle={pageType == "basic" && page == 1 ? "Basic Information" : ""}
                centerTitle={true}
                pageClose={pageType == "event" && page == 0 ? false : true}
                rightLinkType={pageType == "event" && page == 0 ? true : false}
                rightLinkText={"next time"}
                rightLink={() => {navigate("/main")}}
            />
            <PageSection>
                <MemberPage addClass="notTop">
                    <MemberInputSection>
                        {page == 0 ?
                        <SurveyCover
                            title={pageType == "basic" ? "<i>Please proceed with the</i><br/><span>survey required</span><i> for result analysis.</i>" : "<i>You've completed all the surveys! </i><br/><i>Check your </i><span>lifestyle habits</span><i> next.</i>"}
                            time={pageType == "basic" || type !== "me" ? 3 : 10}
                            caption={pageType == "basic" ? "This is a mandatory survey related to age, race, gender, country, and physical information closely related to genetic traits. Please complete it before conducting the genetic test." : "<b>Why are lifestyle habits important?</b><br/><i>While genetic factors inherited from parents play a significant role in determining individual traits, lifestyle habits are also crucial for overall health. Participate in a lifestyle habit check to examine various environmental factors around you and gather relevant data.</i>"}
                        />
                        :
                        <div>
                            {pageType == "basic" && page == 1 ? 
                                <SurveySection>
                                    <SurveyTitleBox
                                        addClass=""
                                        count="1"
                                        totalCount={basicSurvey?.length + 1 || 0}
                                        title={`<i>Please enter </i><span>basic information</span><i> of ${type == "me" ? "your" : "baby"}</i>`}
                                        caption=""
                                    />
                                    {type !== "me" ? 
                                    <div>
                                        <InputItemBox
                                            inputType={null}
                                            addClass=""
                                            inputName="Child's Name"
                                            placeholder="Child's Name"
                                            value={basicInfo?.name}
                                            max={255}
                                            regexp={null}
                                            func={(value)=>{basicInfoSetting("name",value)}}
                                        />
                                        <InputItemBox
                                            inputType={null}
                                            addClass=""
                                            inputName="Child's Nickname"
                                            placeholder="Child's Nickname (max 14 chars)"
                                            value={basicInfo?.nickname}
                                            max={14}
                                            regexp={null}
                                            func={(value)=>{basicInfoSetting("nickname",value)}}
                                        />
                                    </div>
                                    :""}
                                    <InputItemBox
                                        type="selItem"
                                        addClass="type2"
                                        inputName={`Year of Birth`}
                                        placeholder="Year of Birth"
                                        value={basicInfo?.birth}
                                        readOnly={true}
                                        func={(e) => {}}
                                        selItem={true}
                                        clickInput={(e) => setSelPopupData({
                                            title:"Year of Birth",
                                            addClass:null,
                                            closeType:true,
                                            items:yearSetting,
                                            val:yearSetting,
                                            sel:basicInfo?.birth,
                                            closeFunc:(e) => {},
                                            func:(e) => {
                                                basicInfoSetting("birth",e.val);
                                            }
                                        })}
                                    />
                                    <InputBoxOnly addClass="" inputName={`${type !== "me" ? "baby " : ""} Genetic Gender`}>
                                        <InputTap
                                            data={[
                                                {
                                                    id:"M",
                                                    name:"Male"
                                                },
                                                {
                                                    id:"F",
                                                    name:"Female"
                                                }
                                            ]}
                                            val={basicInfo?.gender}
                                            func={(value) => {basicInfoSetting("gender",value)}}
                                        />
                                    </InputBoxOnly>
                                </SurveySection>
                            :
                                <div>
                                    <SurveySection>
                                        <SurveyTitleBox
                                            addClass=""
                                            count={page}
                                            notranslateTitle={true}
                                            totalCount={pageType == "basic" ? basicSurvey?.length + 1 || 0 : eventSurvey?.length || 0}
                                            title={pageType == "basic" && basicSurvey.filter((el) => el.pageId == page)[0] ? 
                                                props?.language == "en" ?
                                                    basicSurvey.filter((el) => el.pageId == page)[0].question
                                                    :
                                                    basicSurvey.filter((el) => el.pageId == page)[0].question_id ? 
                                                        basicSurvey.filter((el) => el.pageId == page)[0].question_id
                                                        : 
                                                        basicSurvey.filter((el) => el.pageId == page)[0].question
                                            : pageType == "event" && eventSurvey.filter((el) => el.pageId == page)[0] ? 
                                                props?.language == "en" ?
                                                    eventSurvey.filter((el) => el.pageId == page)[0].question
                                                    :
                                                    eventSurvey.filter((el) => el.pageId == page)[0].question_id ? 
                                                        eventSurvey.filter((el) => el.pageId == page)[0].question_id
                                                        : 
                                                        eventSurvey.filter((el) => el.pageId == page)[0].question
                                            : ""}
                                            caption={
                                                pageType == "basic" && basicSurvey.filter((el) => el.pageId == page)[0] && basicSurvey.filter((el) => el.pageId == page)[0].type == "chkItem" ?
                                                basicSurvey.filter((el) => el.pageId == page)[0].maxSelect ? `You can only select ${basicSurvey.filter((el) => el.pageId == page)[0].maxSelect} for the relevant items.` : "Please select all applicable items."
                                                : pageType == "event" && eventSurvey.filter((el) => el.pageId == page).length > 0 && eventSurvey.filter((el) => el.pageId == page)[0].type == "chkItem" ?
                                                eventSurvey.filter((el) => el.pageId == page)[0].maxSelect ? `You can only select ${eventSurvey.filter((el) => el.pageId == page)[0].maxSelect} for the relevant items.` : "Please select all applicable items." : ""
                                            }
                                        />
                                        {pageType == "basic" ? 
                                            <div>
                                                {basicSurvey.filter((el) => el.pageId == page).map((item,i)=>(
                                                    <SurveyItem
                                                        key={i}
                                                        language={props?.language}
                                                        data={item}
                                                        yearSetting={yearSetting}
                                                        continent={countryData.continent}
                                                        country={countryData.country}
                                                        func={(value,nextNumber,skipNumber,prevSkip)=>{
                                                            let arrData = [...basicSurvey],
                                                                itemData = {...item},
                                                                settingIndex = arrData.findIndex(function(item, index, arr){
                                                                    return item.pageId == page;
                                                                });

                                                            itemData.value = value;
                                                            arrData[settingIndex].value = value;
                                                            setBasicSurvey(arrData);
                                                            setNextPageNum(nextNumber);
                                                            setSkipPageNum(prevSkip);
                                                        }}
                                                        nextNumberFunc={(nextNumber,skipNumber,prevSkip)=>{
                                                            setNextPageNum(nextNumber);
                                                            setSkipPageNum(prevSkip);
                                                        }}
                                                    />
                                                ))}
                                            </div>
                                        :""}
                                        {pageType !== "basic" ? 
                                            <div>
                                                {eventSurvey.filter((el) => el.pageId == page).map((item,i)=>(
                                                    <SurveyItem
                                                        key={i}
                                                        language={props?.language}
                                                        data={item}
                                                        yearSetting={yearSetting}
                                                        continent={countryData.continent}
                                                        country={countryData.country}
                                                        func={(value,nextNumber,skipNumber,prevSkip)=>{
                                                            let arrData = [...eventSurvey],
                                                                itemData = {...item},
                                                                settingIndex = arrData.findIndex(function(item, index, arr){
                                                                    return item.pageId == page;
                                                                });
                                                                
                                                            itemData.value = value;
                                                            arrData[settingIndex].value = value;
                                                            
                                                            setEventSurvey(arrData);

                                                            setNextPageNum(nextNumber);
                                                            setSkipPageNum(prevSkip);
                                                        }}
                                                        nextNumberFunc={(nextNumber,skipNumber,prevSkip)=>{
                                                            setNextPageNum(nextNumber);
                                                            setSkipPageNum(prevSkip);
                                                        }}
                                                    />
                                                ))}
                                            </div>
                                        :""}
                                    </SurveySection>
                                </div>
                            }
                        </div>
                        }
                    </MemberInputSection>
                    <BtnBox
                        boxType="fixed"
                        addClass=""
                    >
                        <BtnItem
                            addClass=""
                            contents={pageType == "basic" ? 
                                page == 0 ? `Start Survey` : 
                                    (Number(page) > 1 && !basicSurvey.filter((el) => el.pageId == nextPageNum)[0]) ? 
                                        `Complete` : `Next`
                            : 
                                Number(page) == 0 ? `Check Lifestyle Habits` : 
                                    (type == "me" || type == "baby") && (Number(page) >= 1 && !eventSurvey.filter((el) => el.pageId == nextPageNum)[0]) ? 
                                        `Complete` : `Next`
                            }
                            disabled={Number(page) == 0 ? false : btnChk}
                            func={() => {
                                if(pageType == "basic" && (Number(page) > 1 && !basicSurvey.filter((el) => el.pageId == nextPageNum)[0])){
                                    basicSurveySend(0);
                                }else if(pageType !== "basic" && type == "me" && (Number(page) >= 1 && !eventSurvey.filter((el) => el.pageId == nextPageNum)[0])){
                                    eventSurveySend(0);
                                }else if(pageType !== "basic" && type == "baby" && (Number(page) >= 1 && !eventSurvey.filter((el) => el.pageId == nextPageNum)[0])){
                                    eventSurveySend(0);
                                }else{
                                    setPage(pageType == "basic" && (Number(page) == 1 || Number(page) == 0) || pageType == "event" && Number(page) == 0 ? Number(page) + 1 : nextPageNum);
                                    if(pageType == "basic" && Number(page) == 0){
                                        setTimeout(() => {
                                            basicInfoBtnChk();
                                        }, 100);
                                    }
                                }
                                window.scrollTo(0, 0);
                            }}
                        />
                    </BtnBox>
                </MemberPage>
            </PageSection>
            <SelPopup
                data={selPopupData}
            />
            <Popup
                data={popupData}
            />
            <ToastPopup data={toastSubPopupData} closeType={true}>
                <ToastBasicContents
                    title={toastSubPopupData?.basicTitle ? toastSubPopupData?.basicTitle : ""}
                    text={toastSubPopupData?.basicText ? toastSubPopupData?.basicText : ""}
                />
            </ToastPopup>
        </PageSizing>
    );
};

export default Survey;