import { PageErrBox } from "component/app/pageErr";
import { BtnBox, BtnItem } from "component/basic/btns";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const OrderFailure = () => {
    const navigate = useNavigate();

    //결제 취소 등 실패 시
    useEffect(() => {
        
    }, []);

    return (
        <div className="pageSizing">
            <PageErrBox
                icon={true}
                title="Order canceled."
                text={null}
            />
            <BtnBox
                boxType="fixed"
                addClass=""
            >
                <BtnItem
                    addClass=""
                    contents={"Go back to the homepage"}
                    disabled={false}
                    func={() => {navigate("/main")}}
                />
            </BtnBox>
        </div>
    );
};

export default OrderFailure;