import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FamilyManagBox, FamilyManagItem, FamilyManagItemBox, FamilyManagTitleBox } from "component/report/items";
import { basicImgSet, nightTimeChk } from "js/function";
import { Popup, ToastPopup } from "component/basic/popup";
import { PageSection } from "component/app/items";

const FamilyManag = (props) => {
    const navigate = useNavigate();

    const [popupData,setPopupData] =  useState(null);
    const [toastPopupData,setToastPopupData] =  useState(null);
    const [chkVal,setChkVal] =  useState(false);

    const familyApi = useGet({
        url:`/family`,
        loginType:"login"
    });
    const familyData = familyApi?.data;

    //바코드 삭제 팝업
    function delBarcodeOpen(id){
        setPopupData({
            addClass:null,
            title:"Wait! Do you want to delete your barcode?",
            text:"If you delete the barcode, all registered test results will be deleted and cannot be registered again.",
            closeType:true,
            closeFunc:(e) => {},
            btnFunc0:(e)=>{},
            btn0:"Close",
            btn0Type:"",
            btn1:"Delete",
            btn1Type:"line",
            btnFunc1:()=>{delBarcode(id)}
        });
    }

    //바코드 삭제
    function delBarcode(id){
        let formData = new FormData();
        
        fatchSet.FatchApi({
            type:"DELETE",
            formDataItem: formData,
            loginType: "login",
            url: `/family/delete/barcode?id=${id}`,
            success: (data) => {
                window.location.reload(true)
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //배우자 삭제 팝업
    function removeConnectionOpen(id){
        setPopupData({
            addClass:null,
            title:"Would you like to disconnect from your partner?",
            text:"You won't be able to see your genetic chemistry with your partner and the DNA Report of the child registered by your partner.",
            closeType:true,
            closeFunc:(e) => {},
            btnFunc0:(e)=>{},
            btn0:"Close",
            btn0Type:"line",
            btn1:"Confirm",
            btn1Type:"",
            btnFunc1:()=>{removeSign(id)}
        });
    }

    //배우자 초대 취소
    function removeSignOpen(id){
        setPopupData({
            addClass:null,
            title:"Do you want to delete the genetic chemistry invitation?",
            text:"You can send the invitation again any time.",
            closeType:true,
            closeFunc:(e) => {},
            btnFunc0:(e)=>{},
            btn0:"Close",
            btn0Type:"",
            btn1:"Delete",
            btn1Type:"line",
            btnFunc1:()=>{removeSign(id)}
        });
    }

    //배우자 삭제
    function removeSign(id){
        let formData = new FormData();
        
        fatchSet.FatchApi({
            type:"DELETE",
            formDataItem: formData,
            loginType: "login",
            url: `/family/delete?id=${id}`,
            success: (data) => {
                window.location.reload(true)
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //배우자 거절
    function spouseRefuseOpen(id){
        let formData = new FormData();
        formData.append("id", id);
        
        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: "login",
            url: `/family/refuse`,
            success: (data) => {
                setPopupData({
                    addClass:null,
                    title:"The genetic chemistry invitation has been returned.",
                    text:"The genetic chemistry invitation has been declined.",
                    closeType:false,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{window.location.reload(true)},
                    btn0:"Confirm",
                    btn0Type:"",
                    btn1:"",
                    btn1Type:"",
                    btnFunc1:()=>{}
                });
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //배우자 요청
    function spouseAcceptanceOpen(id){
        setToastPopupData({
            addClass:"spouseAcceptancePopup",
            title:null,
            closeType:true,
            closeFunc:(e) => {},
            btn0:"Reject",
            btn0Type:"line",
            btn1:"Accept",
            btn1Type:"",
            btnFunc0:(e)=>{
                spouseRefuseOpen(id);
            },
            btnFunc1:()=>{
                spouseAcceptance(id);
            },
            btnChkBox:true,
            btnChkFuncLabel:"[Required] Collection and Usage of Personal Information",
            btnChkFunc:(e)=>{{
                setChkVal(e);
                if(e){
                    document.querySelectorAll(".spouseAcceptancePopup .btn_popupRight")[0] && document.querySelectorAll(".spouseAcceptancePopup .btn_popupRight")[0].removeAttribute("disabled");
                }else{
                    document.querySelectorAll(".spouseAcceptancePopup .btn_popupRight")[0] && document.querySelectorAll(".spouseAcceptancePopup .btn_popupRight")[0].setAttribute("disabled","disabled");
                }
            }},
            btnChkClickFunc:()=>{props.rightOpenFunc(26,"Collection and Usage of Personal Information")},
        });
        setTimeout(() => {
            document.querySelectorAll(".spouseAcceptancePopup .btn_popupRight")[0] && document.querySelectorAll(".spouseAcceptancePopup .btn_popupRight")[0].setAttribute("disabled","disabled");
        }, 100);
    }

    //배우자 승낙
    function spouseAcceptance(id){
        let formData = new FormData();
        formData.append("id", id);
        
        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: "login",
            url: `/family/accept`,
            success: (data) => {
                setPopupData({
                    addClass:null,
                    title:"Genetic Chemistry Invitation Accepted",
                    text:"<i>You have accepted the invitation.</i><br/><i>You can now find out your genetic chemistry with your partner, and check the DNA report of the child registered by your partner.</i>",
                    closeType:false,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{window.location.reload(true)},
                    btn0:"Confirm",
                    btn0Type:"",
                    btn1:"",
                    btn1Type:"",
                    btnFunc1:()=>{}
                });
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    return (
        <PageSizing addClass="gyBg">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="Add Family"
                centerTitle={true}
            />
            <PageSection addClass="subPage">
                <FamilyManagBox>
                    <FamilyManagTitleBox
                        title="Mom & Dad"
                        btn={familyData?.spouse_info ? null : "<img src='/assets/images/basic/add_big.svg'/><span>Add Partner</span>"}
                        func={()=>{navigate("/report/addSpouse")}}
                        subText={familyData?.spouse_info ? null : "Adding a partner allows you to find out your genetic chemistry with your partner and check the DNA Report of the child registered by your partner."}
                    />
                    <FamilyManagItemBox>
                        <FamilyManagItem
                            type={familyData?.my_info?.barcode_id ? "" : "notBarcode"}
                            character_image={
                                nightTimeChk() ? basicImgSet(familyData?.my_info?.character_image_night||null) : basicImgSet(familyData?.my_info?.character_image||null)
                            }
                            nickname={familyData?.my_info?.nickname}
                            notranslate={true}
                            age={familyData?.my_info?.age}
                            birthday={familyData?.my_info?.birthday}
                            btn={familyData?.my_info?.barcode_id ? "Delete Barcode" : "Register Barcode"}
                            func={()=>{if(familyData?.my_info?.barcode_id){delBarcodeOpen(familyData?.my_info?.barcode_id)}else{navigate("/addBarcode")}}}
                            btn2={familyData?.my_info?.barcode_id ? null : "Shop now"}
                            func2={()=>{navigate("/shop")}}
                        />
                        {familyData?.spouse_info ? 
                            <FamilyManagItem
                                type={familyData?.spouse_info?.status == 0 && familyData?.spouse_info?.is_my_request !== "Y" ? "notBarcode" : ""}
                                character_image={
                                    nightTimeChk() ? basicImgSet(familyData?.spouse_info?.character_image_night||null) : basicImgSet(familyData?.spouse_info?.character_image||null)
                                }
                                nickname={familyData?.spouse_info?.nickname}
                                notranslate={true}
                                age={familyData?.spouse_info?.status == 0 ? null : familyData?.spouse_info?.age}
                                birthday={familyData?.spouse_info?.status == 0 ? null : familyData?.spouse_info?.birthday}
                                btn={familyData?.spouse_info?.status == 0 && familyData?.spouse_info?.is_my_request !== "Y" ? "Reject" : familyData?.spouse_info?.status == 0 ? "Waiting for Acceptance" : "Disconnect"}
                                func={()=>{
                                    if(familyData?.spouse_info?.status == 0 && familyData?.spouse_info?.is_my_request !== "Y"){
                                        spouseRefuseOpen(familyData?.spouse_info?.id)
                                    }else if(familyData?.spouse_info?.status == 0){
                                        removeSignOpen(familyData?.spouse_info?.id)
                                    }else{
                                        removeConnectionOpen(familyData?.spouse_info?.id)
                                    }
                                }}
                                btn2={familyData?.spouse_info?.status == 0 && familyData?.spouse_info?.is_my_request !== "Y" ? "Accept" : null}
                                func2={()=>{
                                    if(familyData?.spouse_info?.status == 0 && familyData?.spouse_info?.is_my_request !== "Y"){
                                        spouseAcceptanceOpen(familyData?.spouse_info?.id)
                                    }
                                }}
                            />
                        :""}
                    </FamilyManagItemBox>
                </FamilyManagBox>
                <FamilyManagBox>
                    <FamilyManagTitleBox
                        title="Baby"
                        btn={
                            (familyData?.my_baby_barcodes && familyData?.my_baby_barcodes?.length > 0) || (familyData?.spouse_baby_barcodes && familyData?.spouse_baby_barcodes?.length > 0) ? 
                            "<img src='/assets/images/basic/add_big.svg'/><span>Add Baby</span>" : null
                        }
                        func={()=>{navigate("/addBarcode",{state: {barcodeSetType: "baby"}})}}
                    />
                    <FamilyManagItemBox>
                        {(familyData?.my_baby_barcodes && familyData?.my_baby_barcodes?.length > 0) || (familyData?.spouse_baby_barcodes && familyData?.spouse_baby_barcodes?.length > 0) ? 
                        <div>
                            {familyData?.my_baby_barcodes?.map((item,i)=>(
                                <FamilyManagItem
                                    key={i}
                                    nicknameType={item.resultType == 1 || item.is_refund == 1 ? true : false}
                                    nicknameFunc={()=>{if(item.resultType == 1){navigate("/report",{state: {selBabyId: item.barcode_id,selUserName:item.nickname}})}else if(item.is_refund == 1){navigate(`/report/status/${item.barcode_id}`)}}}
                                    character_image={
                                        nightTimeChk() ? basicImgSet(item?.character_image_night||null) : basicImgSet(item?.character_image||null)
                                    }
                                    nickname={item?.nickname}
                                    notranslate={true}
                                    age={item?.age}
                                    birthday={item?.birthday}
                                    btn={"Modify"}
                                    func={()=>{navigate(`/report/family/baby/${item?.barcode_id}`)}}
                                    btn2={null}
                                    func2={()=>{}}
                                />
                            ))}
                            {familyData?.spouse_baby_barcodes?.map((item,i)=>(
                                <FamilyManagItem
                                    key={i}
                                    nicknameType={item.resultType == 1 || item.is_refund == 1 ? true : false}
                                    nicknameFunc={()=>{if(item.resultType == 1){navigate("/report",{state: {selBabyId: item.barcode_id,selUserName:item.nickname}})}else if(item.is_refund == 1){navigate(`/report/status/${item.barcode_id}`)}}}
                                    character_image={
                                        nightTimeChk() ? basicImgSet(item?.character_image_night||null) : basicImgSet(item?.character_image||null)
                                    }
                                    nickname={item?.nickname}
                                    notranslate={true}
                                    age={item?.age}
                                    birthday={item?.birthday}
                                    btn={null}
                                    func={()=>{}}
                                    btn2={null}
                                    func2={()=>{}}
                                />
                            ))}
                        </div>
                        :
                        <FamilyManagItem
                            type={"notBarcode"}
                            character_image={basicImgSet(null)}
                            nickname={props?.language == "en" ? "My baby" : "Anakku"}
                            notranslate={true}
                            age={null}
                            birthday={null}
                            btn={"Register Barcode"}
                            func={()=>{navigate("/addBarcode",{state: {barcodeSetType: "baby"}})}}
                            btn2={"Shop now"}
                            func2={()=>{navigate("/shop")}}
                        />
                        }
                    </FamilyManagItemBox>
                </FamilyManagBox>
            </PageSection>
            <Popup
                data={popupData}
            />
            <ToastPopup
                data={toastPopupData}
            >
                <p className="spouseAcceptanceText"><i>When the partner accepts, both you and the partner can view the Baby Report, and the partner can view the genetic chemistry with you. </i><br/><i>Do you want to accept the partner request?</i></p>
            </ToastPopup>
        </PageSizing>
    );
};

export default FamilyManag;