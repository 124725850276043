import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ChkBox, InputBoxOnly, InputItemBox } from "component/basic/formItems";
import { InputTap } from "component/basic/tap";
import { useEffect } from "react";
import { BottomErrMsg, LoadingBox, Popup, RightPopup, SelPopup, ToastPopup } from "component/basic/popup";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Html5QrcodePlugin } from "./Html5QrcodePlugin";

//바코드 영역 타이틀 하단 버튼
function BarcodeHeaderBtn(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className={`btnMinLink ${data.addClass ? data.addClass : ""}`}>
            <p className="btnMinLink_text">{data.text}</p>
            <img src="/assets/images/icon/move_min.svg"/>
        </button>
    ); 
}

//바코드 영역 타이틀 하단 버튼(링크)
function BarcodeHeaderBtnLink(data){
    return (
        <a href={data.href} target="_blank" className={`btnMinLink ${data.addClass ? data.addClass : ""}`}>
            <p className="btnMinLink_text">{data.text}</p>
            <img src="/assets/images/icon/move_min.svg"/>
        </a>
    ); 
}

//바코드 영역 타이틀
function BarcodeHeader(data){
    let navigate = useNavigate();

    return (
        <div className={`barcodeHeader ${data.addClass ? data.addClass : ""}`}>
            {data.icon ? <img src="/assets/images/board/barcode.svg"/> : ""}
            <h1 className="barcodeHeaderTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
            {data.subLink ? <a href={data.subLinkUrl} target="_blank" className="barcodeHeaderLink">{data.subLink}</a> : ""}
            {data.btnBoxDel ? "" : 
            <div className="barcodeHeader_btnBox">
                <BarcodeHeaderBtnLink
                    href="https://youtu.be/jvozfwqq1Uc?feature=shared"
                    text="See Testing Method"
                />
                <BarcodeHeaderBtn
                    func={()=>{navigate("/mypage/order")}}
                    text="Report Damage"
                />
            </div>
            }
        </div>
    ); 
}

//툴팁
function ToolTip(data){
    const [openChk,setOpenChk] =  useState(false);
    const [slideChk,setSlideChk] = useState(true);

    return (
        <div className="btnToolTipBox">
            <button type="button" onClick={()=>{
                if(slideChk){
                    setSlideChk(false);
                    setTimeout(function(){
                        setOpenChk(!openChk)
                    },0);
                    setTimeout(() => {
                        setSlideChk(true);
                    }, 100);
                }
            }} className={`btnToolTip ${data.addClass ? data.addClass : ""}`}>
                <img src="/assets/images/icon/tooltip.svg"/>
            </button>
            <div className={`toolTipTextBox ${openChk ? "active" : ""}`}>
                <p className="toolTipText" dangerouslySetInnerHTML={{__html:data.text}}/>
                <button type="button" onClick={()=>{setOpenChk(false)}} className="toolTipClose"><img src="/assets/images/basic/close.svg"/></button>
            </div>
        </div>
    ); 
}

//바코드 입력 컨텐츠
function BarcodeContents(data){
    let navigate = useNavigate();

    const [btnChk,setBtnChk] =  useState(true);
    const [termsBtnChk,setTermsBtnChk] =  useState(true);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const [barcodeType,setBarcodeType] =  useState("me");
    const [name,setName] =  useState("");
    const [nickname,setNickname] =  useState("");
    const [barcodeNumber,setBarcodeNumber] =  useState("");
    const [birth,setBirth] =  useState("");
    const [gender,setGender] =  useState("");

    const [allChk,setAllChk] =  useState(false);

    const [toastPopupData,setToastPopupData] =  useState(null);
    const [selPopupData,setSelPopupData] =  useState(null);
    const [rightOpenType,setRightOpenType] =  useState(false);
    const [popupData,setPopupData] =  useState(null);

    const [checkItems, setCheckItems] = useState([]);
    const [checkItemTitles, setCheckItemTitles] = useState([]);
    const [chkData, setChkData] = useState([
        {
            id:15,
            term_title:"[Required] Consent for Collection and Usage of Personal Information",
            func:true
        },
        {
            id:16,
            term_title:"[Required] Consent for Collection and Use of Sensitive Information",
            func:true
        },
        {
            id:17,
            term_title:"[Required] Consent for Disclosure of Personal Information to Third Parties",
            func:true
        },
        {
            id:18,
            term_title:"[Required] Consent for Sharing Sensitive Information with Third Parties",
            func:true
        },
        {
            id:21,
            term_title:"<span><i>[Required] Introduction to Genetic Testing for</i><i class='notranslate'> BabyDNA </i><i>Users</i></span>",
            func:true
        }
    ]);
    
    const [winW,setWinW] = useState(window.innerWidth);

    function scanOpen(){
        setRightOpenType(true);
    }

    // 체크박스 선택
    const handleSingleCheck = (checked, id, text) => {
        if (checked) {
            setCheckItemTitles(prev => [...prev, text]);
            setCheckItems(prev => [...prev, id]);
        } else {
            setCheckItemTitles(checkItemTitles.filter((el) => el !== text));
            setCheckItems(checkItems.filter((el) => el !== id));
        }
    };

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if(checked) {
            const idArr = [],
                textArr = [];
                chkData?.forEach((el) => {idArr.push(el.id);textArr.push(el.term_title)});
                setCheckItemTitles(textArr);
                setCheckItems(idArr);
        }else {
            setCheckItemTitles([]);
            setCheckItems([]);
            termsReset(true);
        }
    }

    function btnChkFunc(){
        let regex = RegExp(/^(19[0-9]{2}|20[0-9]{2})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);

        if(data.minVar){
            if(barcodeNumber.length == 11){
                setBtnChk(false)
                return false;
            }else{
                setBtnChk(true)
                return true;
            }
        }else{
            if(barcodeType !== "" && barcodeNumber.length == 11 && regex.test(birth) && gender !== "" && allChk){
                if(barcodeType == "baby"){
                    if(name !== "" && nickname !== ""){
                        setBtnChk(false)
                        return false;
                    }else{
                        setBtnChk(true)
                        return true;
                    }
                }else{
                    setBtnChk(false)
                    return false;
                }
            }else{
                setBtnChk(true)
                return true;
            }
        }
    }

    function termsReset(btnType){
        if(toastPopupData && checkItemTitles.length > 0){
            let popupDataSet = {...toastPopupData}
            popupDataSet.btn0Disabled = btnType;
            setToastPopupData(popupDataSet)
        }
    }

    function onNewScanResult(decodedText, decodedResult){
        setBarcodeNumber(decodedText.replace(/[^0-9]/gi,""));
        setRightOpenType(false);
    };

    function windowResizeFunc(){
        setWinW(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize",windowResizeFunc);
        return() => {
            window.removeEventListener("resize",windowResizeFunc);
        };
    }, []);


    useEffect(() => {
        if(data.startScan){
            scanOpen();
        }
    }, []);

    useEffect(() => {
        if(data.barcodeSetType){
            setBarcodeType(data.barcodeSetType);
        }else if(data.typeChk){
            setBarcodeType("baby");
        }
    }, [data.typeChk]);

    useEffect(() => {
        if(barcodeType == "baby"){
            setChkData([
                {
                    id:15,
                    term_title:"[Required] Consent for Collection and Usage of Personal Information",
                    func:true
                },
                {
                    id:16,
                    term_title:"[Required] Consent for Collection and Use of Sensitive Information",
                    func:true
                },
                {
                    id:17,
                    term_title:"[Required] Consent for Disclosure of Personal Information to Third Parties",
                    func:true
                },
                {
                    id:18,
                    term_title:"[Required] Consent for Sharing Sensitive Information with Third Parties",
                    func:true
                },
                {
                    id:21,
                    term_title:"<span><i>[Required] Introduction to Genetic Testing for</i><i class='notranslate'> BabyDNA </i><i>Users</i></span>",
                    func:true
                },
                {
                    id:"babyAddChk",
                    term_title:"[Required] I confirm that I am the parent and/or legal guardian of the Applicant, and am a person who is validly acting on the Applicant's behalf for the collection, use and disclosure of the Applicant's personal data.",
                    func:false
                }
            ])
        }else{
            setChkData([
                {
                    id:15,
                    term_title:"[Required] Consent for Collection and Usage of Personal Information",
                    func:true
                },
                {
                    id:16,
                    term_title:"[Required] Consent for Collection and Use of Sensitive Information",
                    func:true
                },
                {
                    id:17,
                    term_title:"[Required] Consent for Disclosure of Personal Information to Third Parties",
                    func:true
                },
                {
                    id:18,
                    term_title:"[Required] Consent for Sharing Sensitive Information with Third Parties",
                    func:true
                },
                {
                    id:21,
                    term_title:"<span><i>[Required] Introduction to Genetic Testing for</i><i class='notranslate'> BabyDNA </i><i>Users</i></span>",
                    func:true
                }
            ])
        }
        setCheckItemTitles([]);
        setCheckItems([]);
        termsReset(true);
    }, [barcodeType]);

    useEffect(() => {
        if(!toastPopupData && termsBtnChk && !data.minVar){
            setBottomMsgData({
                addClass : "",
                text : "Please agree to all the terms and conditions.",
                chk : bottomMsgData.chk + 1
            });
        }
    }, [toastPopupData]);

    useEffect(()=>{
        let setData = {
            barcodeType : barcodeType,
            barcodeNumber : barcodeNumber,
            nickname:nickname,
            name:name,
            birth : birth,
            gender : gender,
            allChk : allChk,
            checkItems : [...checkItems],
            btnChk : btnChkFunc()
        }
        data.func(setData);
    },[name,nickname,barcodeType,barcodeNumber,birth,gender,allChk,chkData,checkItems]);

    useEffect(()=>{
        if(chkData.length === checkItemTitles.length){
            setTermsBtnChk(false);
            termsReset(false);
        }else{
            setTermsBtnChk(true);
            termsReset(true);
        }
    },[chkData,checkItems,checkItemTitles]);

    useEffect(()=>{
        if(chkData.length == checkItemTitles.length){
            setAllChk(true);
        }else{
            setAllChk(false);
        }
    },[chkData,checkItems,checkItemTitles]);

    return (
        <div className={`barcodeContents ${data.addClass ? data.addClass : ""} ${data.minVar ? "minVar" : ""}`}>
            {data.minVar ? "" : 
                <InputItemBox
                    type="selItem"
                    addClass=""
                    tooltip={<ToolTip text="· If you do not accurately select the subject of the test,<br/>it cannot be matched with the genetic analysis results.<br/>· The registered barcode number cannot be modified."/>}
                    inputName={`Whose DNA is it?`}
                    placeholder="Select Test Subject"
                    value={barcodeType}
                    disabled={data.typeChk ? true : false}
                    readOnly={true}
                    func={(e) => {}}
                    selItem={true}
                    clickInput={(e) => setSelPopupData({
                        title:null,
                        addClass:null,
                        closeType:true,
                        items:["me","baby"],
                        val:["me","baby"],
                        sel:barcodeType,
                        closeFunc:(e) => {},
                        func:(e) => {
                            setBarcodeType(e.val);
                        }
                    })}
                />
            }
            {barcodeType == "baby" ? 
            <div>
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Child's Name"
                    placeholder="Enter legal name"
                    value={name}
                    max={255}
                    regexp={null}
                    func={(value)=>{setName(value)}}
                />
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Child's Nickname"
                    placeholder=""
                    value={nickname}
                    max={14}
                    regexp={null}
                    func={(value)=>{setNickname(value)}}
                    caption="Enter Nickname (max 14 chars)"
                />
            </div>
            :""}
            <InputItemBox
                inputType={null}
                addClass="barcodeNumberInput"
                inputName="Barcode Number"
                placeholder="Enter 11-digit number"
                value={barcodeNumber}
                inputMode="numeric"
                pattern="[0-9]*"
                max={11}
                regexp={"number"}
                func={(value)=>{setBarcodeNumber(value)}}
            >
                <p className="barcodeNumber_text notranslate">GEN</p>
                <button type="button" onClick={()=>{scanOpen()}} className="btnBarcodeNumber"><img src="/assets/images/icon/camera.svg"/></button>
            </InputItemBox>
            {data.minVar ? "" : 
            <div>
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Date of Birth"
                    placeholder="YYYY-MM-DD"
                    value={birth}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    birthSetting={true}
                    max={10}
                    regexp={null}
                    func={(value)=>{setBirth(value)}}
                />
                <InputBoxOnly addClass="" inputName="Genetic Gender">
                    <InputTap
                        data={[
                            {
                                id:"M",
                                name:"Male"
                            },
                            {
                                id:"F",
                                name:"Female"
                            }
                        ]}
                        val={gender}
                        func={(value) => {setGender(value)}}
                    />
                </InputBoxOnly>
            </div>
            }
            {data.minVar ? "" : 
            <button type="button" className={`btnBarcodeChkOpen ${allChk ? "active" : ""}`} onClick={()=>{
                setToastPopupData({
                    addClass:"",
                    title: "Agree to Terms",
                    closeType:true,
                    closeFunc:(e) => {setTimeout(() => {
                        setToastPopupData(null);
                    }, 200)},
                    closeFuncChk:true,
                    btn0:"Understand and Agree",
                    btn0Type:"",
                    btn1:null,
                    btn0Disabled:termsBtnChk,
                    btn1Type:"",
                    btnFunc0:(e)=>{
                        setTimeout(() => {setToastPopupData(null)}, 200);
                    },
                    btnFunc1:()=>{},
                });
            }}>
                Genetic Testing Consent and Application
            </button>
            }
            <ToastPopup data={toastPopupData} closeType={true}>
                <div className="popupTermsChkBox">
                    <ChkBox
                        addClass="allChkItem"
                        func={(e) => handleAllCheck(e.target.checked)}
                        checkedType={chkData.length == checkItemTitles.length ? true : false}
                        label="Agree to All"
                    />
                    {chkData.map((item,i)=>(
                        <ChkBox
                            key={i}
                            addClass=""
                            func={(e) => handleSingleCheck(e.target.checked,item.id,item.term_title)}
                            checkedType={checkItemTitles.includes(item.term_title) ? true : false}
                            label={item.term_title}
                            chkFunc={item.func ? (e) => data.rightOpenFunc(item.id,item.term_title) : null}
                        />
                    ))}
                </div>
            </ToastPopup>
            <SelPopup
                data={selPopupData}
            />
            <RightPopup
                topTitle={"Barcode Scanner"}
                bType={true}
                addClass={rightOpenType ? "active scanPopup" : " scanPopup"}
                closePopup={() => {setRightOpenType(false)}}
                openType={rightOpenType}
            >
                <div className="bacodeQr">
                    <div className="qrCover">
                        <p className="qrCoverText">Please place the kit so that the barcode is visible</p>
                        <p className="t"></p>
                        <div className="qrCover_centerCover">
                            <p className="l"></p>
                            <p className="c">
                                <span className="clt"></span>
                                <span className="crt"></span>
                                <span className="clb"></span>
                                <span className="crb"></span>
                            </p> 
                            <p className="r"></p> 
                        </div>
                        <p className="b"></p>  
                    </div>
                    <div className="qrBox">
                        <Html5QrcodePlugin
                            setting={rightOpenType}
                            qrCodeSuccessCallback={onNewScanResult}
                            LoadingBoxOpen={(type)=>{
                                setLoadingBoxOpen(type);
                            }}
                            errPopup={()=>{
                                setPopupData({
                                    addClass:null,
                                    title:"No supported camera.",
                                    text:"There is currently no supported camera, or the camera cannot be loaded due to a temporary issue.",
                                    closeType:true,
                                    closeFunc:(e) => {},
                                    btnFunc0:(e)=>{
                                        setRightOpenType(false);
                                    },
                                    btn0:"Confirm",
                                    btn0Type:"",
                                    btn1:null,
                                    btn1Type:"",
                                    btnFunc1:()=>{}
                                });
                                setLoadingBoxOpen(false);
                            }}
                        />
                    </div>
                </div>
                <BtnBox
                    boxType="fixed"
                    addClass="notBg"
                >
                    <BtnItem
                        addClass="lineW"
                        contents={"Enter Barcode Directly"}
                        disabled={false}
                        func={() => {setRightOpenType(false)}}
                    />
                </BtnBox>
            </RightPopup>
            <Popup
                data={popupData}
            />
            <BottomErrMsg
                addClass={bottomMsgData.addClass ? bottomMsgData.addClass : ""}
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </div>
    ); 
}

export {BarcodeHeader,BarcodeContents,ToolTip};