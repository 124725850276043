import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem, MoreBtnItem } from "component/basic/btns";
import { InputItemBox } from "component/basic/formItems";
import { LoadingBox, Popup, RightPopup, SelPopup } from "component/basic/popup";
import { Footer } from "component/elements/footer";
import { MenuHeader, PageSizing } from "component/elements/header";
import React, { Fragment, useRef, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useNavigationType } from "react-router-dom";
import { LikeBox, MainBoard, MainBoardBox, MainFamily, MainFamilyBtn, MainSection, MainSectionTitle, MainSlideItem, MainVideo, MainYoutube, ProcessBox } from "component/main/main";
import { PageSection } from "component/app/items";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination,Controller,EffectCoverflow,Navigation } from 'swiper/modules';
import { AccordionItem, AccordionSection } from "component/basic/items";
import { BarcodeContents, BarcodeHeader } from "component/main/barcode";
import { ageSetting, phoneChk, whatsappOpen } from "js/function";

const Main = (props) => {
    const navigate = useNavigate();
    const state = useLocation();
    const selUserId = state?.state?.selUserId ? state?.state?.selUserId : sessionStorage.getItem("mainUserData") && sessionStorage.getItem("mainUserData") !== "null" && sessionStorage.getItem("mainUserData") !== "undefined" ? sessionStorage.getItem("mainUserData") : null;
    
    const [selId,setSelId] =  useState(null);
    const [selStatus,setSelStatus] =  useState(null);
    const [selType,setSelType] =  useState(null);
    const [selName,setSelName] =  useState(null);
    const [selBirthday,setSelBirthday] =  useState(null);
    const [selEventStatus,setSelEventStatus] =  useState(null);
    const [selWaybillNumber,setSelWaybillNumber] =  useState(null);

    const [slideIndex,setSlideIndex] = useState(0);
    const [faqCloseChk,setFaqCloseChk] = useState("");

    const processCountRef = useRef(null);
    const [processCount,setProcessCount] =  useState(1);

    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);

    const [popupData,setPopupData] =  useState(null);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [rightOpenType,setRightOpenType] =  useState(false);
    const [selPopupData,setSelPopupData] =  useState(null);

    const [btnChk,setBtnChk] =  useState(true);
    const [btnChkEditAddr,setBtnChkEditAddr] =  useState(true);

    const [barcodeType,setBarcodeType] =  useState("");
    const [name,setName] =  useState("");
    const [nickname,setNickname] =  useState("");
    const [barcodeNumber,setBarcodeNumber] =  useState("");
    const [birth,setBirth] =  useState("");
    const [gender,setGender] =  useState("");

    const [editName,setEditName] = useState("");
    const [editPhone,setEditPhone] = useState("");
    const [province,setProvince] = useState(null);
    const [city,setCity] = useState(null);
    const [district,setDistrict] = useState(null);
    const [subdistrict,setSubdistrict] = useState(null);
    const [editAddrDetail,setEditAddrDetail] = useState("");

    const [provinceAddr,setProvinceAddr] = useState([]);
    const [cityAddr,setCityAddr] = useState([]);
    const [districtAddr,setDistrictAddr] = useState([]);
    const [subdistrictAddr,setSubdistrictAddr] = useState([]);

    const [provinceAddrText,setProvinceAddrText] = useState([]);
    const [cityAddrText,setCityAddrText] = useState([]);
    const [districtAddrText,setDistrictAddrText] = useState([]);
    const [subdistrictAddrText,setSubdistrictAddrText] = useState([]);

    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);

    const [phoneErr,setPhoneErr] =  useState(null);

    //기본 배송지
    const deliveryApi = useGet({
        url: localStorage.getItem("token") ? "/mypage/delivery" : null,
        loginType:"login"
    });
    const deliveryData = localStorage.getItem("token") ? deliveryApi?.data?.default_delivery : null;

    //주소 정보
    const provinceApi = useGet({
        url:`/product/delivery/address?type=province`,
        loginType:"login"
    });
    const provinceData = provinceApi?.data?.types;

    const cityApi = useGet({
        url: province ? `/product/delivery/address?type=city&parent_id=${province.id}` : null,
        loginType:"login"
    });
    const cityData = cityApi?.data?.types||[];

    const districtApi = useGet({
        url: city ? `/product/delivery/address?type=district&parent_id=${city.id}` : null,
        loginType:"login"
    });
    const districtData = districtApi?.data?.types||[];

    const subdistrictApi = useGet({
        url: district ? `/product/delivery/address?type=subdistrict&parent_id=${district.id}` : null,
        loginType:"login"
    });
    const subdistrictData = subdistrictApi?.data?.types||[];

    //메인 정보
    const mainApi = useGet({
        url:"/home",
        loginType:"login"
    });
    const mainData = mainApi?.data;

    //유전적 특성 데이터
    const mainServiceData = [
        {
            img:"/assets/images/main/main_serviceImg_0.png",
            text:"You can create a character<br/>that perfectly matches your child's genetic traits."
        },
        {
            img:"/assets/images/main/main_serviceImg_1.png",
            text:"You can find out which abilities<br/>your child excels in and which ones are lacking."
        },
        {
            img:"/assets/images/main/main_serviceImg_2.png",
            text:"Find out how much your child resembles you."
        }
    ]
    //미비사항(이미지 미수령)

    //유전적 특성 슬라이드 페이지네이션
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
          return `<span class="${className}">${index == 0 ? "Character" : index == 1 ? "Ability Cards" : "Genetic Chemistry"}</span>`;
        },
    };

    //바코드 등록
    function addBarcode(){
        setLoadingBoxOpen(true);

        let formData = new FormData();
        formData.append("type", barcodeType);
        formData.append("barcode", `GEN${barcodeNumber}`);
        formData.append("gender", gender);
        formData.append("birthday", birth);
        if(barcodeType == "baby"){
            formData.append("baby_name", name);
            formData.append("baby_nickname", nickname);
        }

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/barcode/save`,
            loginType: "login",
            success: (data) => {
                setBtnChk(false);
                setPopupData({
                    addClass:null,
                    title:"Barcode Registration Successful.",
                    text:`Please proceed with the retest according to the sampling guidelines.`,
                    closeType:false,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{
                        window.location.reload(true);
                    },
                    btn0:"Confirm",
                    btn0Type:"",
                    btn1:"",
                    btn1Type:"",
                    btnFunc1:()=>{}
                });
                setLoadingBoxOpen(false);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
                setLoadingBoxOpen(false);
            }
        })
    }

    //재배송 바코드 등록
    function addBarcodeRe(){
        let formData = new FormData();
        formData.append("id", selId);
        formData.append("barcode", `GEN${barcodeNumber}`);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/barcode/resend/barcode`,
            loginType: "login",
            success: (data) => {
                setBtnChk(false);
                setPopupData({
                    addClass:null,
                    title:"Barcode Registration Successful.",
                    text:`Please proceed with the retest according to the sampling guidelines.`,
                    closeType:false,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{
                        window.location.reload(true);
                    },
                    btn0:"Confirm",
                    btn0Type:"",
                    btn1:"",
                    btn1Type:"",
                    btnFunc1:()=>{}
                });
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //다른 키트와 같이 반송 팝업
    function familyReturnOpen(){
        setPopupData({
            addClass:null,
            title:"Did you return it with your family's kit?",
            text:null,
            closeType:true,
            closeFunc:(e) => {},
            btnFunc0:(e)=>{},
            btn0:"no",
            btn0Type:"line",
            btn1:"yes",
            btn1Type:"",
            btnFunc1:()=>{
                familyReturn();
            }
        });
    }

    //다른 키트와 같이 반송
    function familyReturn(){
        let formData = new FormData();
        formData.append("id", selId);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/barcode/return/family`,
            loginType: "login",
            success: (data) => {
                window.location.reload(true);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //재배송 팝업 오픈
    function reAddrOpen(){
        setRightOpenType(true);
    }

    //재배송 주소 입력
    function reAddrFunc(){
        let formData = new FormData();
        formData.append("id", selId);
        formData.append("re_delivery_name", editName);
        formData.append("re_delivery_phone", `+${editPhone.replace(/\+/g,"")}`);
        formData.append("re_delivery_province", province?.text);
        formData.append("re_delivery_city", city?.text);
        formData.append("re_delivery_district", district?.text);
        formData.append("re_delivery_subdistrict", subdistrict?.text);
        formData.append("re_delivery_address_detail", editAddrDetail);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/barcode/resend`,
            loginType: "login",
            success: (data) => {
                window.location.reload(true);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //재배송 완료 수기처리
    function reDeliveryComFunc(){
        let formData = new FormData();
        formData.append("id", selId);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/barcode/resend/complete`,
            loginType: "login",
            success: (data) => {
                window.location.reload(true);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //검사 대상 삭제
    function removeBarcode(){
        fatchSet.FatchApi({
            type:"DELETE",
            url: `/barcode/delete?id=${selId}`,
            loginType: "login",
            success: (data) => {
                window.location.reload(true);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //process 전환 이벤트
    function processCountFunc(){
        let count = 1;
        processCountRef.current = setInterval(function() {
            if(count >= 4){
                count = 1;
            }else{
                count = count + 1;
            }
            setProcessCount(count)
        }, 2500);
    }

    //메인 유저 정보 세팅
    useEffect(() => {
        if(mainData){
            if(selUserId){
                let selUserArr = [...mainData?.barcodes];
                let selectItem = selUserArr.filter((el)=>el.id == selUserId);

                if(selectItem[0]){//최초 접근 시 선택된 유저 정보가 있을 시
                    setSelId(selectItem[0]?.id);
                    setSelStatus(selectItem[0]?.status);
                    setSelType(selectItem[0]?.type == "baby" ? "baby" : "me");
                    setSelName(selectItem[0]?.nickName);
                    setSelBirthday(selectItem[0]?.birthday);
                    setSelEventStatus(selectItem[0]?.eventStatus);
                    setSelWaybillNumber(selectItem[0]?.waybillNumber||null);

                    sessionStorage.setItem("mainUserData",selUserId);
                }else if(mainData?.barcodes[0]){//최초 접근 시 선택된 유저 정보가 없을 시
                    setSelId(mainData?.barcodes[0]?.id);
                    setSelStatus(mainData?.barcodes[0]?.status);
                    setSelType(mainData?.barcodes[0]?.type == "baby" ? "baby" : "me");
                    setSelName(mainData?.barcodes[0]?.nickName);
                    setSelBirthday(mainData?.barcodes[0]?.birthday);
                    setSelEventStatus(mainData?.barcodes[0]?.eventStatus);
                    setSelWaybillNumber(mainData?.barcodes[0]?.waybillNumber||null);
                }
            }else if(mainData?.barcodes[0]){//최초 접근 시 선택된 유저 정보가 없을 시
                setSelId(mainData?.barcodes[0]?.id);
                setSelStatus(mainData?.barcodes[0]?.status);
                setSelType(mainData?.barcodes[0]?.type == "baby" ? "baby" : "me");
                setSelName(mainData?.barcodes[0]?.nickName);
                setSelBirthday(mainData?.barcodes[0]?.birthday);
                setSelEventStatus(mainData?.barcodes[0]?.eventStatus);
                setSelWaybillNumber(mainData?.barcodes[0]?.waybillNumber||null);
            }
        }
    }, [mainApi]);

    useEffect(() => {
        processCountFunc();
        if(sessionStorage.getItem("withdrawal")){
            setPopupData({
                addClass:null,
                title:"Withdrawal",
                text:"<i>Thank you for using</i><i class='notranslate'> BabyDNA</i><i>. We will strive to become a better</i><i class='notranslate'> BabyDNA</i><i> based on your valuable feedback. Hope to see you again!</i>",
                closeType:false,
                closeFunc:(e) => {},
                btnFunc0:(e)=>{},
                btn0:"Confirm",
                btn0Type:"",
                btn1:"",
                btn1Type:"",
                btnFunc1:()=>{}
            });
            sessionStorage.removeItem("withdrawal")
        }
    }, []);

    //주소 세팅(회원)
    useEffect(() => {
        if(localStorage.getItem("token") && deliveryData){
            setEditName(deliveryData?.address_name);
            setEditPhone(deliveryData?.address_phone);
            setProvince({id:deliveryData?.province_id,text:deliveryData?.address_province});
            setEditAddrDetail(deliveryData?.address_detail);
            setTimeout(() => {
                setCity({id:deliveryData?.city_id,text:deliveryData?.address_city});
                setTimeout(() => {
                    setDistrict({id:deliveryData?.district_id,text:deliveryData?.address_district});
                    setTimeout(() => {
                        setSubdistrict({id:deliveryData?.sub_district_id,text:deliveryData?.address_subdistrict});
                    }, 100);
                }, 100);
            }, 100);
        }
    }, [deliveryData]);

    //주소 변경 이벤트
    useEffect(() => {
        let setData = [],
            setTextData = [];
        if(provinceData){
            provinceData.forEach(function(item,i){
                setData.push(item.id);
                setTextData.push(item.province);
                if(provinceData.length - 1 == i){
                    setProvinceAddr(setData);
                    setProvinceAddrText(setTextData);
                }
            });
        }else{
            setProvinceAddr(setData);
            setProvinceAddrText(setTextData);
        }
    }, [provinceData]);

    useEffect(() => {
        let setData = [],
            setTextData = [];
        if(cityData){
            cityData.forEach(function(item,i){
                setData.push(item.id);
                setTextData.push(item.city);
                if(cityData.length - 1 == i){
                    setCityAddr(setData);
                    setCityAddrText(setTextData);
                }
            });
        }else{
            setCityAddr(setData);
            setCityAddrText(setTextData);
        }
    }, [cityData]);

    useEffect(() => {
        let setData = [],
            setTextData = [];
        if(districtData){
            districtData.forEach(function(item,i){
                setData.push(item.id);
                setTextData.push(item.district);
                if(districtData.length - 1 == i){
                    setDistrictAddr(setData);
                    setDistrictAddrText(setTextData);
                }
            });
        }else{
            setDistrictAddr(setData);
            setDistrictAddrText(setTextData);
        }
    }, [districtData]);

    useEffect(() => {
        let setData = [],
            setTextData = [];
            if(subdistrictData){
                subdistrictData.forEach(function(item,i){
                    setData.push(item.id);
                    setTextData.push(item.sub_district);
                    if(subdistrictData.length - 1 == i){
                        setSubdistrictAddr(setData);
                        setSubdistrictAddrText(setTextData);
                    }
                });
            }else{
                setSubdistrictAddr(setData);
                setSubdistrictAddrText(setTextData);
            }
    }, [subdistrictData]);

    useEffect(() => {
        setCity(null);
        setDistrict(null);
        setSubdistrict(null);

        setCityAddr([]);
        setDistrictAddr([]);
        setSubdistrictAddr([]);

        setCityAddrText([]);
        setDistrictAddrText([]);
        setSubdistrictAddrText([]);
    }, [province]);

    useEffect(() => {
        setDistrict(null);
        setSubdistrict(null);

        setDistrictAddr([]);
        setSubdistrictAddr([]);

        setDistrictAddrText([]);
        setSubdistrictAddrText([]);
    }, [city]);

    useEffect(() => {
        setSubdistrict(null);

        setSubdistrictAddr([]);

        setSubdistrictAddrText([]);
    }, [district]);

    useEffect(() => {
        if(editPhone !== "" || editPhone){
            if(phoneChk(editPhone)){
                setPhoneErr(null);
            }else{
                setPhoneErr(`Please enter '62' followed by your phone number, excluding the initial '0' (zero), and without "-"`);
            }
        }else{
            setPhoneErr(null);
        }
    }, [editPhone]);

    //주소 버튼 유효성
    useEffect(() => {
        if(editName !== ""
        && phoneChk(editPhone)
        && province
        && city
        && district
        && subdistrict
        && editAddrDetail !== ""){
            setBtnChkEditAddr(false);
        }else{
            setBtnChkEditAddr(true);
        }
    }, [editName,editPhone,province,city,district,subdistrict,editAddrDetail]);

    return (
        <PageSizing addClass="headerPage">
            <MenuHeader
                languageCh={(type)=>{props.languageCh(type)}}
                language={props.language}
            />
            <PageSection>
                {/* 등록된 유저 정보 */}
                {mainData?.barcodes && mainData?.barcodes?.length > 0 && localStorage.getItem("token") ? 
                    <MainFamily>
                        {mainData?.barcodes.map((item,i)=>(
                            <MainFamilyBtn
                                key={i}
                                id={item.id}
                                status={item.status}
                                sel={selId}
                                name={item.nickName||item.type||""}
                                type={item.type}
                                birthday={item.birthday}
                                eventStatus={item.eventStatus}
                                waybillNumber={item.waybillNumber}
                                func={(id,status,type,birthday,nickName,eventStatus,waybillNumber)=>{
                                    setSelId(id);
                                    setSelStatus(status);
                                    setSelType(type == "baby" ? "baby" : "me");
                                    setSelBirthday(birthday);
                                    setSelName(nickName);
                                    setSelEventStatus(eventStatus);
                                    setSelWaybillNumber(waybillNumber||null);

                                    sessionStorage.setItem("mainUserData",id);
                                }}
                            />
                        ))}
                    </MainFamily>
                : ""}
                {localStorage.getItem("token") ? 
                    <div>
                    {/* 배송중 */}
                    {mainData?.first_order?.id && Number(mainData?.first_order?.status) <= 3 && (!mainData?.barcodes || !mainData?.barcodes[0]) ? 
                        <MainBoard
                            title={"<span class='notranslate'>BabyDNA</span><br/><i>is being delivered</i>"}
                            img={"/assets/images/board/main_img_0.svg"}
                            imgStyle={{margin:"20px 0 0"}}

                            btnAddClass=""
                            btn={"View Delivery Status"}
                            btnChk={false}
                            btnFunc={()=>{navigate(`/mypage/order/detail/1/${mainData?.first_order?.id}`)}}
                        /> : ""
                    }
                    {/* 바코드 등록 */}
                    {mainData?.first_order?.id && Number(mainData?.first_order?.status) >= 4 && (!mainData?.barcodes || !mainData?.barcodes[0]) ? 
                        <MainBoardBox>
                            <BarcodeHeader icon={true} title="<i>Please register the</i><br/><span>barcode number.</span>"/>
                            <BarcodeContents
                                startScan={false}
                                typeChk={null}
                                func={(settingData) => {
                                    setBtnChk(settingData?.btnChk);
                                    setBarcodeType(settingData?.barcodeType);
                                    setName(settingData?.name);
                                    setNickname(settingData?.nickname);
                                    setBarcodeNumber(settingData?.barcodeNumber);
                                    setBirth(settingData?.birth);
                                    setGender(settingData?.gender);
                                }}
                                rightOpenFunc={(id,title) => {props.rightOpenFunc(id,title)}}
                            />
                            <BtnItem
                                addClass=""
                                contents={"Register Barcode"}
                                disabled={btnChk}
                                func={() => {addBarcode()}}
                            />
                            <img src="/assets/images/main/main_board_down.svg" className="mainBoard_down_icon"/>
                        </MainBoardBox>
                        : ""
                    }
                    {/* 설문 진행 */}
                    {!selStatus ? "" :
                        selStatus == 1? 
                            <MainBoard
                                title={"<i>Please proceed with the</i><br/><span>survey required</span><i> for result analysis.</i>"}
                                img={"/assets/images/board/main_img_1.svg"}
                                imgStyle={{margin:"30px 0"}}

                                timeCaption={true}

                                btnAddClass=""
                                btn={"Start Survey"}
                                btnChk={false}
                                btnFunc={()=>{navigate(`/survey/${selId}/${selType}/basic`)}}
                            />
                        : 
                        selStatus == 2 ? //반송
                            <MainBoard
                                title={`<i>Please</i><i class='notranslate'> </i><span>return the tested kit</span><i class='notranslate'>, </i><i>and attach the return sticker on the envelope.</i>`}
                                img={"/assets/images/board/main_img_2.svg"}
                                imgStyle={{margin:"30px 0"}}

                                btnText={<p className="mainBoard_btnText"><i>If you returned it with your family, please </i><button type="button" onClick={()=>{familyReturnOpen()}}><i>press here!</i></button><br/><i>If you need help, please contact our</i><i className="notranslate"> </i><button type="button" onClick={()=>{whatsappOpen()}}>customer service.</button></p>}

                                btnAddClass=""
                                btn={"Register Return Information"}
                                btnChk={false}
                                btnFunc={()=>{navigate(`/return/${selId}/normal/notice`)}}
                            />
                        : 
                        selStatus == 3 || selStatus == 15 ? //반송 진행중
                            <MainBoard
                                titleIcon="/assets/images/main/title_icon_return.svg"
                                title={"<i class='notranslate'>BabyDNA</i><i> kit is being delivered to the analysis facility.</i>"}
                                titleCaption={<p className="mainBoard_text">{selWaybillNumber ? `Waybill number : ${selWaybillNumber}` : "The waybill is currently being processed."}</p>}
                                img={`/assets/images/board/${ageSetting(selBirthday) >= 3 && selEventStatus == 0 ? "main_img_3" : "main_img_3_1"}.svg`}
                                imgStyle={{margin:`-32px 0 ${ageSetting(selBirthday) >= 3 && selEventStatus == 0 ? "40px" : "60px"}`}}

                                btnText={ageSetting(selBirthday) >= 3 && selEventStatus == 0 ? <p className="mainBoard_btnText"><i>Before the genetic analysis begins,</i><br/><i>check [{selName||""}]'s lifestyle habits.</i></p> : null}

                                btnAddClass=""
                                btn={ageSetting(selBirthday) >= 3 && selEventStatus == 0 ? "Check Lifestyle Habits" : null}
                                btnChk={false}
                                btnFunc={()=>{navigate(`/survey/${selId}/${selType}/event`)}}
                            />
                        : 
                        selStatus == 4 || selStatus == 16 ? //검사실 도착
                            <MainBoard
                                title={"<i class='notranslate'>BabyDNA</i><i> has arrived at the </i><br/><span>analysis facility!</span>"}
                                img={"/assets/images/board/main_img_4.svg"}
                                imgStyle={{margin:"25px 0 40px"}}

                                btnAddClass=""
                                btn={"View Test Status"}
                                btnChk={false}
                                btnFunc={()=>{navigate(`/report/status/${selId}`)}}
                            />
                        : 
                        selStatus == 5 || selStatus == 17 ? //검사중
                            <MainBoard
                                title={"<i>The genetic analysis has started. Please wait for a while!</i>"}
                                img={"/assets/images/board/main_img_5.svg"}
                                imgStyle={{margin:"50px 0"}}

                                btnAddClass=""
                                btn={"View Test Status"}
                                btnChk={false}
                                btnFunc={()=>{navigate(`/report/status/${selId}`)}}
                            />
                        : 
                        selStatus == 6 ? //검사완료
                            <MainBoard
                                title={"<i>The genetic analysis is complete! Check your test results.</i>"}
                                img={"/assets/images/board/main_img_6.svg"}
                                imgStyle={{margin:"50px 0"}}

                                btnAddClass=""
                                btn={"Go to See Test Results"}
                                btnChk={false}
                                btnFunc={()=>{navigate(`/report`)}}
                            />
                        : 
                        selStatus == 10 ? //검사실패
                            <MainBoard
                                title={"<i>No results were available. Please apply for a </i><br/><span>retest</span>"}
                                img={"/assets/images/board/main_img_7.svg"}
                                imgStyle={{margin:"14px 0 24px"}}

                                warningText={
                                    <p className="warningBtnText">
                                        <button type="button" onClick={()=>{
                                            props.rightOpenFunc(1,"Terms of Service")
                                        }}>As a policy,</button> the kit can be resent only once, and the subject of the test cannot be changed.
                                    </p>
                                }
                                warningTitle={null}
                                warningIcon={true}

                                btnAddClass=""
                                btn={"Apply for Retest"}
                                btnChk={false}
                                btnFunc={()=>{reAddrOpen()}}
                            />
                        : 
                        selStatus == 11 || selStatus == 12 ? //재배송 신청
                            <MainBoard
                                titleIcon="/assets/images/main/title_icon_com.svg"
                                title={"<i>Application for retest kit has been </i><span>completed</span>"}
                                img={null}
                                imgStyle={null}

                                warningText={"For inquiries, please contact customer service at (+62 811-9003-0367)."}
                                warningTitle={"We will notify you when the kit delivery starts"}
                                warningIcon={false}

                                btnAddClass=""
                                // btn={selStatus == 12 ? "Received" : null}
                                btn={null}
                                btnChk={false}
                                btnFunc={()=>{
                                    // if(selStatus == 12){
                                    //     setPopupData({
                                    //         addClass:null,
                                    //         title:"Have you received the retest kit?",
                                    //         text:"If you haven't received the kit yet, please leave a 1:1 inquiry.",
                                    //         closeType:true,
                                    //         closeFunc:(e) => {},
                                    //         btnFunc0:(e)=>{},
                                    //         btn0:"No",
                                    //         btn0Type:"line",
                                    //         btn1:"Yes",
                                    //         btn1Type:"",
                                    //         btnFunc1:()=>{
                                    //             reDeliveryComFunc();
                                    //         }
                                    //     });
                                    // }
                                }}
                            />
                        : 
                        selStatus == 13 ? //재배송 바코드 등록
                            <MainBoardBox>
                                <BarcodeHeader 
                                    subLink="Please follow the testing instructions!"
                                    subLinkUrl="https://youtu.be/jvozfwqq1Uc?feature=shared"
                                    btnBoxDel={true}
                                    icon={true}
                                    title="<i>Register the </i><span>barcode number</span><i> of the retest kit.</i>"/>
                                <BarcodeContents
                                    minVar={true}
                                    startScan={false}
                                    typeChk={null}
                                    func={(settingData) => {
                                        setBtnChk(settingData?.btnChk);
                                        setBarcodeNumber(settingData?.barcodeNumber);
                                    }}
                                    rightOpenFunc={(id,title) => {props.rightOpenFunc(id,title)}}
                                />
                                <BtnItem
                                    addClass=""
                                    contents={"Register Barcode"}
                                    disabled={btnChk}
                                    func={() => {addBarcodeRe()}}
                                />
                                <img src="/assets/images/main/main_board_down.svg" className="mainBoard_down_icon"/>
                            </MainBoardBox>
                        :
                        selStatus == 14 ? //재배송 반송
                            <MainBoard
                                title={"<i>Have you collected your sample? </i><br/><i>Please return the retest kit.</i>"}
                                titleCaption={<p className="mainBoard_text">Dispose the original return label.</p>}

                                img={"/assets/images/board/main_img_8.svg"}
                                imgStyle={{margin:"30px 0 50px"}}

                                btnAddClass=""
                                btn={"Apply for Return"}
                                btnChk={false}
                                btnFunc={()=>{navigate(`/return/${selId}/resend/notice`)}}
                            />
                        :
                        selStatus == 20 ? //재배송 검사 실패
                            <MainBoard
                                title={"<i>The reanalysis did not yield any results.</i>"}
                                titleCaption={<p className="mainBoard_text"><i>To safeguard your personal information,</i><br/><i>we'll be deleting the barcode number associated with the kit that failed the reanalysis.</i><br/><i>If you would like to retain your barcode number or if you have any questions please leave a </i><button type="button" onClick={()=>{whatsappOpen();}}>1:1</button><i> inquiry.</i></p>}

                                img={"/assets/images/board/main_img_9.svg"}
                                imgStyle={{margin:"30px 0"}}

                                chkTitle="I agree to delete the barcode number for personal information protection."
                                chkType={!btnChk}
                                chkFunc={(e)=>{
                                    setBtnChk(!e.target.checked)
                                }}

                                btnAddClass=""
                                btn={"Confirm"}
                                btnChk={btnChk}
                                btnFunc={()=>{
                                    setPopupData({
                                        addClass:null,
                                        title:"Do you want to delete the barcode number?",
                                        text:"If you want to keep the barcode number, please leave a 1:1 inquiry.",
                                        closeType:true,
                                        closeFunc:(e) => {},
                                        btnFunc0:(e)=>{},
                                        btn0:"Close",
                                        btn0Type:"line",
                                        btn1:"Delete",
                                        btn1Type:"",
                                        btnFunc1:()=>{
                                            removeBarcode();
                                        }
                                    });
                                }}
                            />
                        : ""
                    }
                    </div>
                :""}
                {mainData?.product_introduce && mainData?.product_introduce.indexOf("youtube") > -1 ? 
                    <MainYoutube src={mainData?.product_introduce}/>
                : ""}
                <MainSection addClass="mainProductSection">
                    <MainSectionTitle
                        addClass=""
                        img={null}
                        title="Discover the DNA POWER of your child"
                        titleCaption={`<i>With</i><i class='notranslate'> BabyDNA</i><i>, </i><br/><i>you can learn various genetic traits!</i>`}
                    />
                    <div className="mainProductBtnBox">
                        <BtnItem
                            addClass=""
                            contents="SHOP NOW"
                            disabled={false}
                            func={() => {navigate("/shop")}}
                        />
                        <BtnItem
                            addClass="line"
                            contents="Register Barcode"
                            disabled={false}
                            func={() => {navigate("/addBarcode")}}
                        />
                    </div>
                </MainSection>
                <MainSection addClass="maingBgSection">
                    <MainSectionTitle
                        addClass=""
                        img={null}
                        title="What genetic traits does your child have?"
                        titleCaption=""
                    />
                    <div className="mainService">
                        <Swiper
                            spaceBetween={12}
                            effect={'coverflow'}
                            slidesPerView={1}
                            pagination={pagination}
                            coverflowEffect={{
                                rotate: 28,
                                stretch: 0,
                                depth: 40,
                                modifier: 1,
                                slideShadows: false,
                            }}
                            loop={true}
                            modules={[EffectCoverflow,Pagination,Controller]}
                            onRealIndexChange={(e) => {setSlideIndex(e.realIndex)}}
                        >
                            {mainServiceData?.map((item, i) => (
                                <SwiperSlide key={i}>
                                    <MainSlideItem
                                        img={item.img}
                                        text={item.text}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className="mainService_subPagination">
                        <div className="mainService_swiperPagination">
                            {mainServiceData?.map((item, i) => (
                                <span className={`swiperPagination_bullet ${i == slideIndex ? "active" : ""}`} key={i}></span>
                            ))}
                        </div>
                    </div>
                    <BtnItem
                        addClass="lineW"
                        contents="Go see Sample Report"
                        disabled={false}
                        func={() => {slideIndex == 2 ? navigate("/report/chemi") : navigate("/report")}}
                    />
                </MainSection>
                <MainSection>
                    <MainSectionTitle
                        addClass=""
                        img={null}
                        title="<i>About</i><span class='notranslate'> BabyDNA</span>"
                        titleCaption=""
                    />
                    <LikeBox
                        addClass="piBg"
                        img="like_icon_0"
                        title="It just takes a swab! No pain!"
                        text="No needles, just one swab is enough."
                    />
                    <LikeBox
                        addClass="pBg"
                        img="like_icon_1"
                        title="Conveniently at home"
                        text="It only takes 1 minute without the need to go to the hospital."
                    />
                    <LikeBox
                        addClass="bBg"
                        img="like_icon_2"
                        title="Easy and fun with characters and cards"
                        text="Goodbye to boring and difficult explanations!"
                    />
                </MainSection>
                <MainSection addClass="wgBg">
                    <h1 className="mainDnaTitle"><i>Process of the </i><i className="notranslate">BabyDNA</i></h1>
                    <ProcessBox
                        addClass={processCount == 1 ? "active" : ""}
                        number={1}
                        title="Order Kit"
                        text="Easily order with your mobile."
                        img="process_icon_0"
                    />
                    <ProcessBox
                        addClass={processCount == 2 ? "active" : ""}
                        number={2}
                        title="Collect Sample"
                        text="Collect the sample in just 1 minute with a cotton swab."
                        img="process_icon_1"
                    />
                    <ProcessBox
                        addClass={processCount == 3 ? "active" : ""}
                        number={3}
                        title="Genetic Analysis"
                        text="Thorough analysis by a professional institution."
                        img="process_icon_2"
                    />
                    <ProcessBox
                        addClass={processCount == 4 ? "active" : ""}
                        number={4}
                        title="Check Analysis Results"
                        text="Check your own character and ability cards."
                        img="process_icon_3"
                    />
                </MainSection>
                <MainSection addClass="maingBgSection">
                    <MainSectionTitle
                        addClass=""
                        img="/assets/images/main/about_title_icon.svg"
                        title="About Genoscape"
                        titleCaption=""
                    />
                    <h2 className="mainAboutText"><i>We will make your baby and your life</i><br/><i>healthier and happier with DNA information </i></h2>
                    <a type="button" target="_blank" href="https://aeolian-glass-cca.notion.site/GENOSCAPE-PTE-LTD-363ddeccf8f143a4b310f3d324076439" className="btn_mainAbout">Click to know more about our team</a>
                </MainSection>
                {mainData?.introduce_links?.length > 0 ? 
                <MainSection addClass="videoSlideSection">
                    <MainSectionTitle
                        addClass=""
                        img="/assets/images/main/review_title_icon.svg"
                        title="<i>Check the reviews of those who have used</i><i class='notranslate'> BabyDNA!</i>"
                        titleCaption=""
                    />
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        // loop={mainData?.introduce_links.length == 1 ? false : true}
                        modules={[Navigation]}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        onBeforeInit={(swiper) => {
                            swiper.params.navigation.prevEl = navigationPrevRef.current;
                            swiper.params.navigation.nextEl = navigationNextRef.current;
                        }}
                    >
                        {mainData?.introduce_links?.map((item, i) => (
                            <div key={i}>
                                <SwiperSlide key={i}>
                                    <MainYoutube src={item.youtube_url}/>
                                </SwiperSlide>
                            </div>
                        ))}
                    </Swiper>
                    <div className="videoSlideBtnBox">
                        <button type="button" ref={navigationPrevRef} className="btn_videoSlide btn_prevVideo"><img src="/assets/images/main/prev_video.svg"/><i>Previous Video</i></button>
                        <button type="button" ref={navigationNextRef} className="btn_videoSlide btn_nextVideo"><i>Next Video</i><img src="/assets/images/main/next_video.svg"/></button>
                    </div>
                </MainSection>
                : ""}
                <MainSection addClass="maingFaqSection">
                    <MainSectionTitle
                        addClass=""
                        img="/assets/images/main/faq_title_icon.svg"
                        title="Frequently Asked Questions"
                        titleCaption=""
                    />
                    <AccordionSection>
                        {
                        mainData?.faqs && mainData?.faqs?.length > 0 ?
                        mainData?.faqs.map((item,i)=>(
                            <AccordionItem
                                id={i}
                                key={i}
                                closeChk={faqCloseChk}
                                title={props.language == "en" ? item.question : item.question_id}
                                text={props.language == "en" ? item.answer : item.answer_id}
                                func={(id)=>{
                                    setFaqCloseChk(id)
                                }}
                            />
                        ))
                        :
                        ""}
                    </AccordionSection>
                    <MoreBtnItem
                        margin="30px 0 0"
                        contents="See More" func={()=>{navigate("/cs/faq")}}
                    />
                </MainSection>
            </PageSection>                    
            <Footer
                popupOpen={(id,title)=>{props.rightOpenFunc(id,title)}}
                languageCh={(type)=>{props.languageCh(type)}}
                language={props.language}
            />
            <RightPopup
                topTitle={"Delivery Information"}
                addClass={rightOpenType ? "active bottomBtn sidePadding" : " bottomBtn sidePadding"}
                closePopup={() => {setRightOpenType(false)}}
                openType={rightOpenType}
            >
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Name"
                    placeholder="Enter legal name"
                    value={editName}
                    max={null}
                    regexp={null}
                    func={(value)=>{setEditName(value)}}
                />
                <InputItemBox
                    inputType={null}
                    addClass="inputSubCaptionItem"
                    inputName="Mobile Phone Number"
                    placeholder="6201234567890"
                    value={editPhone}
                    max={16}
                    regexp={"number"}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    errMsg={phoneErr}
                    func={(value)=>{setEditPhone(value)}}
                />
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName="Province"
                    placeholder="Select Province"
                    value={province ? province?.text : ""}
                    readOnly={true}
                    func={(e) => {}}
                    selItem={true}
                    clickInput={(e) => setSelPopupData({
                        title:"Select Province",
                        addClass:null,
                        closeType:true,
                        items:provinceAddrText,
                        val:provinceAddr,
                        sel:province ? province?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setProvince({
                                id:e.val,
                                text:e.text
                            });
                        }
                    })}
                />
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName="City"
                    placeholder="Select City"
                    value={city ? city?.text : ""}
                    readOnly={true}
                    disabled={province && cityAddr.length > 0 ? false : true}
                    func={(e) => {}}
                    selItem={true}
                    clickInput={(e) => setSelPopupData({
                        title:"Select City",
                        addClass:null,
                        closeType:true,
                        items:cityAddrText,
                        val:cityAddr,
                        sel:city ? city?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setCity({
                                id:e.val,
                                text:e.text
                            });
                        }
                    })}
                />
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName={window.languageType() === "en" ? "District" : "Kecamatan"}
                    placeholder={window.languageType() === "en" ? "Select District" : "Pilih Kecamatan"}
                    value={district ? district?.text : ""}
                    readOnly={true}
                    disabled={city && districtAddr.length > 0 ? false : true}
                    func={(e) => {}}
                    selItem={true}
                    notranslateName={true}
                    notranslateInput={true}
                    clickInput={(e) => setSelPopupData({
                        title: window.languageType() === "en" ? "Select District" : "Pilih Kecamatan",
                        notranslateTitle:true,
                        addClass:null,
                        closeType:true,
                        items:districtAddrText,
                        val:districtAddr,
                        sel:district ? district?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setDistrict({
                                id:e.val,
                                text:e.text
                            });
                        }
                    })}
                />
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName={window.languageType() === "en" ? "Subdistrict" : "Kelurahan"}
                    placeholder={window.languageType() === "en" ? "Select Subdistrict" : "Pilih Kelurahan"}
                    value={subdistrict ? subdistrict?.text : ""}
                    readOnly={true}
                    disabled={district && subdistrictAddr.length > 0 ? false : true}
                    func={(e) => {}}
                    selItem={true}
                    notranslateName={true}
                    notranslateInput={true}
                    clickInput={(e) => setSelPopupData({
                        title: window.languageType() === "en" ? "Select Subdistrict" : "Pilih Kelurahan",
                        notranslateTitle:true,
                        addClass:null,
                        closeType:true,
                        items:subdistrictAddrText,
                        val:subdistrictAddr,
                        sel:subdistrict ? subdistrict?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setSubdistrict({
                                id:e.val,
                                text:e.text
                            });
                        }
                    })}
                />
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Detailed Address"
                    placeholder="Detailed Address"
                    value={editAddrDetail}
                    max={null}
                    regexp={null}
                    func={(value)=>{setEditAddrDetail(value)}}
                />
                <BtnBox
                    boxType="fixed"
                    addClass="btnTextAdd"
                >
                    <BtnItem
                        addClass=""
                        contents={"Complete"}
                        disabled={btnChkEditAddr}
                        func={() => {reAddrFunc()}}
                    />
                </BtnBox>
            </RightPopup>
            <SelPopup
                data={selPopupData}
            />
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default Main;