import { useState } from "react";

//상품 수량
export default function CountInput(data){
    function setCountUp(){
        if(data.qty && (data.optionQty ? Math.min(Number(data.optionQty),Number(data.qty)) : data.qty) < 15){
            if(data.val < (data.optionQty ? Math.min(Number(data.optionQty),Number(data.qty)) : data.qty)){
                data.func(data.val + 1);
            }else if(data.excessFunc){
                data.excessFunc()
            }
        }else{
            if(data.val < 15){
                data.func(data.val + 1);
            }else if(data.excessFunc){
                data.excessFunc_max()
            }
        }
    }

    function setCountDown(){
        if(data.val > 1){
            data.func(data.val - 1);
        }
    }

    return (
        <div className="countBox">
            <button type="button" onClick={() => setCountDown()} className="btn_m"><img src={`/assets/images/basic/${data.val > 1 ? "count_down" : "count_down_off"}.svg`}/></button>
            <input type="text" value={data.val} readOnly className="countInput"/>
            <button type="button" onClick={() => setCountUp()} className="btn_p"><img src={`/assets/images/basic/${data.val < (data.optionQty ? Math.min(Number(data.optionQty),Number(data.qty)) : data.qty) ? "count_up" : "count_up_off"}.svg`}/></button>
        </div>
    );
}