import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header, PageSizing } from "component/elements/header";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ProductSelPriceData } from "component/product/list";
import { BtnBox, BtnItem, MinBtnBtnItem } from "component/basic/btns";
import { LoadingBox, Popup, RightPopup, SelPopup } from "component/basic/popup";
import { ChkBox, InputBoxOnly, InputItemBox } from "component/basic/formItems";
import { OrderAddrAddr, OrderAddrBox, OrderAddrInfo, ProductItem, ProductItemBox, ProductItemTitle } from "component/product/detail";
import { InputTap, SubLine } from "component/basic/tap";
import { comFormat, phoneChk, productData_param } from "js/function";

const Order = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const type = param.type;
    const state = useLocation();
    const productData = state?.state?.productData||[];

    const emailRegExp = /[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}/;
    const pwRegExp = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,20}$/;

    const [btnChk,setBtnChk] = useState(true);

    const [productPrice,setProductPrice] = useState(0);
    const [deliveryPrice,setDeliveryPrice] = useState(0);
    const [totalPrice,setTotalPrice] = useState(0);

    const [settingName,setSettingName] = useState("");
    const [settingPhone,setSettingPhone] = useState("");
    const [settingProvince,setSettingProvince] = useState(null);
    const [settingCity,setSettingCity] = useState(null);
    const [settingDistrict,setSettingDistrict] = useState(null);
    const [settingsubdistrict,setSettingSubdistrict] = useState(null);
    const [settingAddrDetail,setSettingAddrDetail] = useState("");

    const [settingPhoneErr,setSettingPhoneErr] =  useState(null);

    const [provinceSettingAddr,setProvinceSettingAddr] = useState([]);
    const [citySettingAddr,setCitySettingAddr] = useState([]);
    const [districtSettingAddr,setDistrictSettingAddr] = useState([]);
    const [subdistrictSettingAddr,setSubdistrictSettingAddr] = useState([]);

    const [provinceSettingAddrText,setProvinceSettingAddrText] = useState([]);
    const [citySettingAddrText,setCitySettingAddrText] = useState([]);
    const [districtSettingAddrText,setDistrictSettingAddrText] = useState([]);
    const [subdistrictSettingAddrText,setSubdistrictSettingAddrText] = useState([]);

    const [editName,setEditName] = useState("");
    const [editPhone,setEditPhone] = useState("");
    const [province,setProvince] = useState(null);
    const [city,setCity] = useState(null);
    const [district,setDistrict] = useState(null);
    const [subdistrict,setSubdistrict] = useState(null);
    const [editAddrDetail,setEditAddrDetail] = useState("");

    const [editPhoneErr,setEditPhoneErr] =  useState(null);

    const [provinceAddr,setProvinceAddr] = useState([]);
    const [cityAddr,setCityAddr] = useState([]);
    const [districtAddr,setDistrictAddr] = useState([]);
    const [subdistrictAddr,setSubdistrictAddr] = useState([]);

    const [provinceAddrText,setProvinceAddrText] = useState([]);
    const [cityAddrText,setCityAddrText] = useState([]);
    const [districtAddrText,setDistrictAddrText] = useState([]);
    const [subdistrictAddrText,setSubdistrictAddrText] = useState([]);

    const [tariffCode,setTariffCode] = useState(null);

    const [basicAddrSet,setBasicAddrSet] = useState(false);

    const [orderType,setOrderType] = useState("Xendit");

    const [orderJoinType,setOrderJoinType] = useState(false);

    const [orderEmail,setOrderEmail] = useState("");
    const [orderEmailErr,setOrderEmailErr] = useState(null);
    const [orderPw,setOrderPw] = useState("");
    const [orderPwErr,setOrderPwErr] = useState(null);

    const [orderJoinPw,setOrderJoinPw] = useState("");
    const [orderJoinPwCom,setOrderJoinPwCom] = useState("");
    const [pwErr,setPwErr] =  useState(null);
    const [pwComErr,setPwComErr] =  useState(null);

    const [cetified,setCetified] =  useState("");
    const [certifyErr,setCertifyErr] =  useState(null);
    const [timerCount,setTimerCount] =  useState("3:00");
    const [cetifiedChk,setCetifiedChk] =  useState(false);
    const [cetifiedComChk,setCetifiedComChk] =  useState(false);
    const [cetifiedInputComChk,setCetifiedInputComChk] =  useState(false);

    const [phone,setPhone] =  useState("");
    const [gender,setGender] =  useState("");
    const [birthday,setBirthday] =  useState("");
    const [nickName,setNickName] =  useState("");

    const [phoneErr,setPhoneErr] =  useState(null);

    const [phoneCetified,setPhoneCetified] =  useState("");
    const [phoneCertifyErr,setPhoneCertifyErr] =  useState(null);
    const [phoneTimerCount,setPhoneTimerCount] =  useState("3:00");
    const [phoneCetifiedChk,setPhoneCetifiedChk] =  useState(false);
    const [phoneCetifiedComChk,setPhoneCetifiedComChk] =  useState(false);
    const [phoneCetifiedInputComChk,setPhoneCetifiedInputComChk] =  useState(false);

    const [checkItems, setCheckItems] = useState([]);
    const [checkItemTitles, setCheckItemTitles] = useState([]);
    const [chkData, setChkData] = useState([{
        id:"purchaseTerms",
        chkId:"purchaseTerms",
        term_title:"[Required] I confirm the purchase order and consent to the collection, use and disclosure of my personal data in accordance with Genoscape’s Terms of Use and Privacy Policy, and Policy on Provision of Personal Information to Third-Party.",
        func:false
    }]);

    const [popupData,setPopupData] =  useState(null);
    const [selPopupData,setSelPopupData] =  useState(null);
    const [rightOpenType,setRightOpenType] =  useState(false);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);

    const [btnChkEditAddr,setBtnChkEditAddr] =  useState(true);

    const [clickChk,setClickChk] =  useState(true);

    //배송비
    const deliveryApi = useGet({
        url:tariffCode ? `/product/delivery/price?tariff_code=${tariffCode}${productData_param(productData,type)}` : null,
        loginType:"login"
    });
    const deliveryData = deliveryApi?.data?.delivery_price||0;

    //기본 배송지
    const addrApi = useGet({
        url:localStorage.getItem("token") ? `/mypage/delivery` : null,
        loginType:"login"
    });
    const addrData = addrApi?.data?.default_delivery;

    //주소 정보 세팅
    const provinceSettingApi = useGet({
        url:`/product/delivery/address?type=province`,
        loginType:"login"
    });
    const provinceSettingData = provinceSettingApi?.data?.types;

    const citySettingApi = useGet({
        url: settingProvince ? `/product/delivery/address?type=city&parent_id=${settingProvince.id}` : null,
        loginType:"login"
    });
    const citySettingData = citySettingApi?.data?.types||[];

    const districtSettingApi = useGet({
        url: settingCity ? `/product/delivery/address?type=district&parent_id=${settingCity.id}` : null,
        loginType:"login"
    });
    const districtSettingData = districtSettingApi?.data?.types||[];

    const subdistrictSettingApi = useGet({
        url: settingDistrict ? `/product/delivery/address?type=subdistrict&parent_id=${settingDistrict.id}` : null,
        loginType:"login"
    });
    const subdistrictSettingData = subdistrictSettingApi?.data?.types||[];

    const provinceApi = useGet({
        url:`/product/delivery/address?type=province`,
        loginType:"login"
    });
    const provinceData = provinceApi?.data?.types;

    const cityApi = useGet({
        url: province ? `/product/delivery/address?type=city&parent_id=${province.id}` : null,
        loginType:"login"
    });
    const cityData = cityApi?.data?.types||[];

    const districtApi = useGet({
        url: city ? `/product/delivery/address?type=district&parent_id=${city.id}` : null,
        loginType:"login"
    });
    const districtData = districtApi?.data?.types||[];

    const subdistrictApi = useGet({
        url: district ? `/product/delivery/address?type=subdistrict&parent_id=${district.id}` : null,
        loginType:"login"
    });
    const subdistrictData = subdistrictApi?.data?.types||[];

    //이메일 인증
    const sval = useRef(null);
    function timerStart(){
        //타이머
        setTimerCount("3:00");
        setCertifyErr("");
        
        if (sval.current !== null) {
            clearInterval(sval.current);
            sval.current = null;
        }
        
        timerChk(2.99);

        function timerChk(duration) {
            let timerNumber = duration * 60;
            let m, s;
            sval.current = setInterval(function() {
                m = parseInt(timerNumber / 60 % 60, 10);
                s = parseInt(timerNumber % 60, 10);
                s = s < 10 ? "0" + s : s;

                setTimerCount(m + ":" + s);

                if (--timerNumber < 0) {
                    timerNumber = 0;
                    clearInterval(sval.current);
                    sval.current = null;
                    setCertifyErr("The authentication time has expired.");

                    setCetifiedComChk(true);
                    setCetified("");
                }
            }, 1000);
        }
    }

    function cetifiedStert(){
        if(clickChk){
            setClickChk(false);
            setLoadingBoxOpen(true);
            
            let formData = new FormData();
            formData.append("email", orderEmail);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/email/certification`,
                success: (data) => {
                    timerStart();
                    setCetified("");
                    setCetifiedChk(true);

                    setLoadingBoxOpen(false);

                    setClickChk(true);
                },
                err: (data) => {
                    setClickChk(true);

                    if(data.alert){
                        setPopupData({
                            addClass:null,
                            title:null,
                            text:data.alert,
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"Confirm",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }

                    setLoadingBoxOpen(false);
                }
            })
        }
    }

    function cetifiedEnd(){
        setLoadingBoxOpen(true);

        let formData = new FormData();
        formData.append("email", orderEmail);
        formData.append("code", cetified);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/email/verification`,
            success: (data) => {
                setCetifiedComChk(true);
                setCetifiedInputComChk(true);

                setTimerCount("3:00");
                setCertifyErr("");

                clearInterval(sval.current);
                sval.current = null;

                setLoadingBoxOpen(false);
            },
            err: (data) => {
                if(data.alert){
                    if(data.alert == "Please enter a different email address."){
                        setCetified("");
                        setCetifiedChk(false);
                        setTimerCount("3:00");
                        setCertifyErr("");

                        clearInterval(sval.current);
                        sval.current = null;

                        setPopupData({
                            addClass:null,
                            title:"This email address has already been registered.",
                            text:"Please enter a different email address.",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"Confirm",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });

                    }else if(data.alert == "An account is already registered with the email you entered."){
                        setPopupData({
                            addClass:null,
                            title:"This email address has already been registered.",
                            text:data.alert,
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"Confirm",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }else if(data.alert){
                        setCertifyErr(data.alert);
                    }
                }
                setLoadingBoxOpen(false);
            }
        })
    }

    //휴대폰 인증
    const phoneSval = useRef(null);
    function phoneTimerStart(){
        //타이머
        setPhoneTimerCount("3:00");
        setPhoneCertifyErr("");
        
        if (phoneSval.current !== null) {
            clearInterval(phoneSval.current);
            phoneSval.current = null;
        }
        
        phoneTimerChk(2.99);

        function phoneTimerChk(duration) {
            let timerNumber = duration * 60;
            let m, s;
            phoneSval.current = setInterval(function() {
                m = parseInt(timerNumber / 60 % 60, 10);
                s = parseInt(timerNumber % 60, 10);
                s = s < 10 ? "0" + s : s;

                setPhoneTimerCount(m + ":" + s);

                if (--timerNumber < 0) {
                    timerNumber = 0;
                    clearInterval(phoneSval.current);
                    phoneSval.current = null;
                    setPhoneCertifyErr("The authentication time has expired.");

                    setPhoneCetifiedComChk(true);
                    setPhoneCetified("");
                }
            }, 1000);
        }
    }

    function phoneCetifiedStert(){
        if(clickChk){
            setClickChk(false);
            setLoadingBoxOpen(true);

            let formData = new FormData();
            formData.append("phone", `+${phone.replace(/\+/g,"")}`);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/phone/certification`,
                success: (data) => {
                    phoneTimerStart();
                    setPhoneCetified("");
                    setPhoneCetifiedChk(true);

                    setLoadingBoxOpen(false);

                    setClickChk(true);
                },
                err: (data) => {
                    setClickChk(true);
                    if(data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.data||"",
                            text:data.alert,
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"Confirm",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }

                    setLoadingBoxOpen(false);
                }
            })
        }
    }

    function phoneCetifiedEnd(){
        setLoadingBoxOpen(true);

        let formData = new FormData();
        formData.append("phone", `+${phone.replace(/\+/g,"")}`);
        formData.append("code", phoneCetified);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/phone/verification`,
            success: (data) => {
                setPhoneCetifiedComChk(true);
                setPhoneCetifiedInputComChk(true);

                setPhoneTimerCount("3:00");
                setPhoneCertifyErr("");

                clearInterval(phoneSval.current);
                phoneSval.current = null;

                setLoadingBoxOpen(false);
            },
            err: (data) => {
                if(data.alert){
                    if(data.alert == "Please enter a different Mobile Phone Number."){
                        setPhoneCetified("");
                        setPhoneCetifiedChk(false);
                        setPhoneTimerCount("3:00");
                        setPhoneCertifyErr("");

                        clearInterval(phoneSval.current);
                        phoneSval.current = null;

                        setPopupData({
                            addClass:null,
                            title:"This Mobile Phone Number has already been registered.",
                            text:"Please enter a different Mobile Phone Number.",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"Confirm",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }else if(data.alert == "An account is already registered with the Mobile Phone Number you entered."){
                        setPopupData({
                            addClass:null,
                            title:"This Mobile Phone Number has already been registered.",
                            text:data.alert,
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"Confirm",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }else if(data.alert){
                        setPhoneCertifyErr(data.alert);
                    }
                }
                setLoadingBoxOpen(false);
            }
        })
    }

    // 체크박스 선택
    const handleSingleCheck = (checked, id, text) => {
        if (checked) {
           setCheckItemTitles(prev => [...prev, text]);
           setCheckItems(prev => [...prev, id]);
       } else {
           setCheckItemTitles(checkItemTitles.filter((el) => el !== text));
           setCheckItems(checkItems.filter((el) => el !== id));
       }
    };

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if(checked) {
        const idArr = [],
                textArr = [];
                chkData?.forEach((el) => {idArr.push(el.chkId);textArr.push(el.term_title)});
                setCheckItemTitles(textArr);
                setCheckItems(idArr);
        }
        else {
            setCheckItemTitles([]);
            setCheckItems([]);
        }
    }

    //주문 시작
    function orderStart(){
        setLoadingBoxOpen(true);

        let formData = new FormData();

        productData.forEach(function(item,i){
            formData.append("product_id[]", type == "basic" ? item.id : item.product_id);
            formData.append("option_id[]", item.option_id||"");
            formData.append("qty[]", type == "basic" ? item.qty||1 : item.cart_qty||1);

            if(type == "cart"){
                formData.append("cart_id[]", item.id);
            }
        });
 
        formData.append("address_name", settingName);
        formData.append("address_phone", `+${settingPhone.replace(/\+/g,"")}`);
        formData.append("address_province", settingProvince?.text);
        formData.append("address_city", settingCity?.text);
        formData.append("address_district", settingDistrict?.text);
        formData.append("address_subdistrict", settingsubdistrict?.text);

        formData.append("address_province_id", settingProvince?.id);
        formData.append("address_city_id", settingCity?.id);
        formData.append("address_district_id", settingDistrict?.id);
        formData.append("address_subdistrict_id", settingsubdistrict?.id);

        formData.append("address_detail", settingAddrDetail);

        formData.append("is_default_address", basicAddrSet ? 1 : 0);

        formData.append("payment_method", orderType);

        formData.append("tariff_code", tariffCode);

        formData.append("product_price", productPrice);
        formData.append("delivery_price", deliveryPrice);
        formData.append("total_price", totalPrice);

        if(!localStorage.getItem("token")){
            formData.append("is_join", orderJoinType ? 1 : 0);

            formData.append("email", orderEmail);
            formData.append("password", orderJoinType ? orderJoinPw : orderPw);

            if(orderJoinType){
                formData.append("gender", gender);
                formData.append("birthday", birthday);
                formData.append("nickName", nickName);
                formData.append("phone", `+${phone.replace(/\+/g,"")}`);
            }
        }

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/order/payment`,
            loginType: "login",
            success: (data) => {
                setLoadingBoxOpen(false);
                window.location.href = data.data.payment_url;
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
                setLoadingBoxOpen(false);
            }
        })
    }

    //배송지 변경
    function editAddrFunc(){
        let formData = new FormData();
        formData.append("address_name", editName);
        formData.append("address_phone", `+${editPhone.replace(/\+/g,"")}`);
        formData.append("address_province", province?.text);
        formData.append("address_city", city?.text);
        formData.append("address_district", district?.text);
        formData.append("address_subdistrict", subdistrict?.text);
        formData.append("address_detail", editAddrDetail);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/mypage/delivery/modify`,
            loginType: "login",
            success: (data) => {
                setRightOpenType(false);

                setSettingName(editName);
                setSettingPhone(editPhone);
                setSettingProvince(province);
                setSettingAddrDetail(editAddrDetail);
                setTimeout(() => {
                    setSettingCity(city);
                    setTimeout(() => {
                        setSettingDistrict(district);
                        setTimeout(() => {
                            setSettingSubdistrict(subdistrict);

                            if(subdistrictData?.filter((el)=>el.id == subdistrict.id)[0]){
                                setTariffCode(subdistrictData?.filter((el)=>el.id == subdistrict.id)[0]?.tariff_code);
                            }else{
                                setTariffCode(null);
                            }
                        }, 100);
                    }, 100);
                }, 100);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    function addrSetting(){
        if(settingName){
            setEditName(settingName);
            setEditPhone(settingPhone);
            setProvince({id:settingProvince?.id,text:settingProvince?.text});
            setEditAddrDetail(settingAddrDetail);
            setTimeout(() => {
                setCity({id:settingCity?.id,text:settingCity?.text});
                setTimeout(() => {
                    setDistrict({id:settingDistrict?.id,text:settingDistrict?.text});
                    setTimeout(() => {
                        setSubdistrict({id:settingsubdistrict?.id,text:settingsubdistrict?.text});
                    }, 100);
                }, 100);
            }, 100);
        }else{
            setEditName(settingName);
            setEditPhone(settingPhone);
            setProvince(null);
            setCity(null);
            setDistrict(null);
            setSubdistrict(null);
            setEditAddrDetail(settingAddrDetail);
        }
    }

    useEffect(() => {
        let productPriceSet = 0;

        if(productData && productData.length > 0){
            let productTotalQty = 0;

            productData.forEach(function(item,i){
                if(type == "basic"){
                    productPriceSet = productPriceSet + Number(item?.productTotalPrict);
                    productTotalQty = productTotalQty + Number(item.qty);
                }else{
                    productPriceSet = productPriceSet + (((item.sale_price ? Number(item.sale_price) : Number(item.price)) + (item.option_price ? Number(item.option_price) : 0)) * Number(item.cart_qty));
                    productTotalQty = productTotalQty + Number(item.cart_qty);
                }

                if(productData.length - 1 == i){
                    setProductPrice(productPriceSet);
                    if(productTotalQty > 15 || productPriceSet >= 50000000){
                        setPopupData({
                            addClass:null,
                            title:"",
                            text:"Please inquire separately for bulk purchases!",
                            closeType:false,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{navigate(-1)},
                            btn0:"Confirm",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                }
            });
        }else{
            navigate("/pageErr");
        }

        //비회원 기본 주소 세팅
        if(!localStorage.getItem("token") && localStorage.getItem("orderData")){
            let saveOrderData = JSON.parse(localStorage.getItem("orderData"));

            setEditName(saveOrderData?.address_name);
            setEditPhone(saveOrderData?.address_phone);
            setProvince({id:saveOrderData?.province_id,text:saveOrderData?.address_province});
            setEditAddrDetail(saveOrderData?.address_detail);
            setTimeout(() => {
                setCity({id:saveOrderData?.city_id,text:saveOrderData?.address_city});
                setTimeout(() => {
                    setDistrict({id:saveOrderData?.district_id,text:saveOrderData?.address_district});
                    setTimeout(() => {
                        setSubdistrict({id:saveOrderData?.sub_district_id,text:saveOrderData?.address_subdistrict});
                        setTariffCode(saveOrderData?.tariff_code);
                    }, 100);
                }, 100);
            }, 100);

            setSettingName(saveOrderData?.address_name);
            setSettingPhone(saveOrderData?.address_phone);
            setSettingProvince({id:saveOrderData?.province_id,text:saveOrderData?.address_province});
            setSettingAddrDetail(saveOrderData?.address_detail);
            setTimeout(() => {
                setSettingCity({id:saveOrderData?.city_id,text:saveOrderData?.address_city});
                setTimeout(() => {
                    setSettingDistrict({id:saveOrderData?.district_id,text:saveOrderData?.address_district});
                    setTimeout(() => {
                        setSettingSubdistrict({id:saveOrderData?.sub_district_id,text:saveOrderData?.address_subdistrict});
                        setTariffCode(saveOrderData?.tariff_code);
                    }, 100);
                }, 100);
            }, 100);
        }
    }, []);

    useEffect(() => {
        //회원 기본 주소 세팅
        if(localStorage.getItem("token") && addrData){
            setEditName(addrData?.address_name);
            setEditPhone(addrData?.address_phone);
            setProvince({id:addrData?.province_id,text:addrData?.address_province});
            setEditAddrDetail(addrData?.address_detail);
            setTimeout(() => {
                setCity({id:addrData?.city_id,text:addrData?.address_city});
                setTimeout(() => {
                    setDistrict({id:addrData?.district_id,text:addrData?.address_district});
                    setTimeout(() => {
                        setSubdistrict({id:addrData?.sub_district_id,text:addrData?.address_subdistrict});
                        setTariffCode(addrData?.tariff_code);
                    }, 100);
                }, 100);
            }, 100);

            setSettingName(addrData?.address_name);
            setSettingPhone(addrData?.address_phone);
            setSettingProvince({id:addrData?.province_id,text:addrData?.address_province});
            setSettingAddrDetail(addrData?.address_detail);
            setTimeout(() => {
                setSettingCity({id:addrData?.city_id,text:addrData?.address_city});
                setTimeout(() => {
                    setSettingDistrict({id:addrData?.district_id,text:addrData?.address_district});
                    setTimeout(() => {
                        setSettingSubdistrict({id:addrData?.sub_district_id,text:addrData?.address_subdistrict});
                        setTariffCode(addrData?.tariff_code);
                    }, 100);
                }, 100);
            }, 100);
        }
    }, [addrApi]);

    //주소 변경 이벤트
    useEffect(() => {
        let setData = [],
            setTextData = [];
        if(provinceSettingData){
            provinceSettingData.forEach(function(item,i){
                setData.push(item.id);
                setTextData.push(item.province);
                if(provinceSettingData.length - 1 == i){
                    setProvinceSettingAddr(setData);
                    setProvinceSettingAddrText(setTextData);
                }
            });
        }else{
            setProvinceSettingAddr(setData);
            setProvinceSettingAddrText(setTextData);
        }
    }, [provinceSettingData]);

    useEffect(() => {
        let setData = [],
            setTextData = [];
        if(citySettingData){
            citySettingData.forEach(function(item,i){
                setData.push(item.id);
                setTextData.push(item.city);
                if(citySettingData.length - 1 == i){
                    setCitySettingAddr(setData);
                    setCitySettingAddrText(setTextData);
                }
            });
        }else{
            setCitySettingAddr(setData);
            setCitySettingAddrText(setTextData);
        }
    }, [citySettingData]);

    useEffect(() => {
        let setData = [],
            setTextData = [];
        if(districtSettingData){
            districtSettingData.forEach(function(item,i){
                setData.push(item.id);
                setTextData.push(item.district);
                if(districtSettingData.length - 1 == i){
                    setDistrictSettingAddr(setData);
                    setDistrictSettingAddrText(setTextData);
                }
            });
        }else{
            setDistrictSettingAddr(setData);
            setDistrictSettingAddrText(setTextData);
        }
    }, [districtSettingData]);

    useEffect(() => {
        let setData = [],
            setTextData = [];
            if(subdistrictSettingData){
                subdistrictSettingData.forEach(function(item,i){
                    setData.push(item.id);
                    setTextData.push(item.sub_district);
                    if(subdistrictSettingData.length - 1 == i){
                        setSubdistrictSettingAddr(setData);
                        setSubdistrictSettingAddrText(setTextData);
                    }
                });
            }else{
                setSubdistrictSettingAddr(setData);
                setSubdistrictSettingAddrText(setTextData);
            }
    }, [subdistrictSettingData]);

    useEffect(() => {
        setSettingCity(null);
        setSettingDistrict(null);
        setSettingSubdistrict(null);

        setTariffCode(null);

        setCitySettingAddr([]);
        setDistrictSettingAddr([]);
        setSubdistrictSettingAddr([]);

        setCitySettingAddrText([]);
        setDistrictSettingAddrText([]);
        setSubdistrictSettingAddrText([]);
    }, [settingProvince]);

    useEffect(() => {
        setSettingDistrict(null);
        setSettingSubdistrict(null);

        setTariffCode(null);

        setDistrictSettingAddr([]);
        setSubdistrictSettingAddr([]);

        setDistrictSettingAddrText([]);
        setSubdistrictSettingAddrText([]);
    }, [settingCity]);

    useEffect(() => {
        setSettingSubdistrict(null);

        setTariffCode(null);

        setSubdistrictSettingAddr([]);

        setSubdistrictSettingAddrText([]);
    }, [settingDistrict]);

    useEffect(() => {
        let setData = [],
            setTextData = [];
        if(provinceData){
            provinceData.forEach(function(item,i){
                setData.push(item.id);
                setTextData.push(item.province);
                if(provinceData.length - 1 == i){
                    setProvinceAddr(setData);
                    setProvinceAddrText(setTextData);
                }
            });
        }else{
            setProvinceAddr(setData);
            setProvinceAddrText(setTextData);
        }
    }, [provinceData]);

    useEffect(() => {
        let setData = [],
            setTextData = [];
        if(cityData){
            cityData.forEach(function(item,i){
                setData.push(item.id);
                setTextData.push(item.city);
                if(cityData.length - 1 == i){
                    setCityAddr(setData);
                    setCityAddrText(setTextData);
                }
            });
        }else{
            setCityAddr(setData);
            setCityAddrText(setTextData);
        }
    }, [cityData]);

    useEffect(() => {
        let setData = [],
            setTextData = [];
        if(districtData){
            districtData.forEach(function(item,i){
                setData.push(item.id);
                setTextData.push(item.district);
                if(districtData.length - 1 == i){
                    setDistrictAddr(setData);
                    setDistrictAddrText(setTextData);
                }
            });
        }else{
            setDistrictAddr(setData);
            setDistrictAddrText(setTextData);
        }
    }, [districtData]);

    useEffect(() => {
        let setData = [],
            setTextData = [];
            if(subdistrictData){
                subdistrictData.forEach(function(item,i){
                    setData.push(item.id);
                    setTextData.push(item.sub_district);
                    if(subdistrictData.length - 1 == i){
                        setSubdistrictAddr(setData);
                        setSubdistrictAddrText(setTextData);
                    }
                });
            }else{
                setSubdistrictAddr(setData);
                setSubdistrictAddrText(setTextData);
            }
    }, [subdistrictData]);

    useEffect(() => {
        setCity(null);
        setDistrict(null);
        setSubdistrict(null);

        setCityAddr([]);
        setDistrictAddr([]);
        setSubdistrictAddr([]);

        setCityAddrText([]);
        setDistrictAddrText([]);
        setSubdistrictAddrText([]);
    }, [province]);

    useEffect(() => {
        setDistrict(null);
        setSubdistrict(null);

        setDistrictAddr([]);
        setSubdistrictAddr([]);

        setDistrictAddrText([]);
        setSubdistrictAddrText([]);
    }, [city]);

    useEffect(() => {
        setSubdistrict(null);

        setSubdistrictAddr([]);

        setSubdistrictAddrText([]);
    }, [district]);

    //비회원 가입 여부 변경 시
    useEffect(() => {
        let chkDataSet = [];
        setCheckItems(chkDataSet);
        setCheckItemTitles(chkDataSet);

        if(orderJoinType){
            chkDataSet = [{
                id:1,
                chkId:1,
                term_title:"[Required] Terms of Service",
                func:true
            },
            {
                id:3,
                chkId:3,
                term_title:"[Required] Privacy Policy",
                func:true
            },
            {
                id:3,
                chkId:4,
                term_title:"[Required] Policy on Provision of Personal Information to Third-Party",
                func:true
            },
            {
                id:"purchaseTerms",
                chkId:"purchaseTerms",
                term_title:"[Required] I confirm the purchase order and consent to the collection, use and disclosure of my personal data in accordance with Genoscape’s Terms of Use and Privacy Policy, and Policy on Provision of Personal Information to Third-Party.",
                func:false
            },
            {
                id:"marketing",
                chkId:"marketing",
                term_title:"[Optional] I consent to receiving marketing information in accordance with Genoscape’s Privacy Policy.",
                func:false
            }];
            setChkData(chkDataSet);
        }else{
            chkDataSet = [{
                id:"purchaseTerms",
                chkId:"purchaseTerms",
                term_title:"[Required] I confirm the purchase order and consent to the collection, use and disclosure of my personal data in accordance with Genoscape’s Terms of Use and Privacy Policy, and Policy on Provision of Personal Information to Third-Party.",
                func:false
            }];
            setChkData(chkDataSet);
        }
    }, [orderJoinType]);
    
    useEffect(() => {
        if(deliveryData){
            setDeliveryPrice(Number(deliveryData))
        }
    }, [deliveryData]);

    useEffect(() => {
        setTotalPrice(productPrice + deliveryPrice);
    }, [productPrice,deliveryPrice]);

    //유효성 검증
    useEffect(() => {
        if(orderJoinPw !== orderJoinPwCom && orderJoinPw !== "" && orderJoinPwCom !== ""){
            setPwComErr("Passwords do not match.")
        }else{
            setPwComErr(null)
        }

        if(!pwRegExp.test(orderJoinPw) && orderJoinPw !== ""){
            setPwErr("Please enter a combination of English letters, numbers, and special characters, ranging from 8 to 20 characters.")
        }else{
            setPwErr(null)
        }
    }, [orderJoinPw,orderJoinPwCom]);

    useEffect(() => {
        if(orderPw.length < 8 && orderPw !== ""){
            setOrderPwErr("Please set a password with a combination of English letters, numbers, and special characters, ranging from 8 to 20 characters.")
        }else{
            setOrderPwErr(null)
        }
    }, [orderPw]);

    useEffect(() => {
        if(!emailRegExp.test(orderEmail) && orderEmail !== ""){
            setOrderEmailErr("Please enter a valid email address.")
        }else{
            setOrderEmailErr(null)
        }
    }, [orderEmail]);

    useEffect(() => {
        if(editPhone !== "" || editPhone){
            if(phoneChk(editPhone)){
                setEditPhoneErr(null);
            }else{
                setEditPhoneErr(`Please enter '62' followed by your phone number, excluding the initial '0' (zero), and without "-"`);
            }
        }else{
            setEditPhoneErr(null);
        }
    }, [editPhone]);

    useEffect(() => {
        if(settingPhone !== "" || settingPhone){
            if(phoneChk(settingPhone)){
                setSettingPhoneErr(null);
            }else{
                setSettingPhoneErr(`Please enter '62' followed by your phone number, excluding the initial '0' (zero), and without "-"`);
            }
        }else{
            setSettingPhoneErr(null);
        }
    }, [settingPhone]);

    useEffect(() => {
        if(phone !== "" || phone){
            if(phoneChk(phone)){
                setPhoneErr(null);
            }else{
                setPhoneErr(`Please enter '62' followed by your phone number, excluding the initial '0' (zero), and without "-"`);
            }
        }else{
            setPhoneErr(null);
        }
    }, [phone]);

    useEffect(() => {
        let chkSet = [...checkItems];
        let regex = RegExp(/^(19[0-9]{2}|20[0-9]{2})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
        
        if(settingName !== ""
        && phoneChk(settingPhone)
        && settingProvince
        && settingCity
        && settingDistrict
        && settingsubdistrict
        && settingAddrDetail !== ""
        && (orderJoinType ? (chkSet.filter((el) => el !== "marketing")?.length == chkData?.length - 1) : (chkData.length == checkItems.length))){
            if(localStorage.getItem("token")){
                setBtnChk(false);
            }else if(orderJoinType ? 
                orderJoinPw == orderJoinPwCom && pwRegExp.test(orderJoinPw) && cetifiedComChk && emailRegExp.test(orderEmail) && phoneChk(phone) && phoneCetifiedComChk && regex.test(birthday) && gender !== "" && nickName !== ""
                :
                emailRegExp.test(orderEmail) && orderPw.length > 7)
            {
                setBtnChk(false);
            }else{
                setBtnChk(true);
            }
        }else{
            setBtnChk(true);
        }
    }, [settingName,settingPhone,settingProvince,settingCity,settingDistrict,settingsubdistrict,settingAddrDetail,chkData,checkItems,cetifiedComChk,orderJoinType,orderEmail,orderPw,orderJoinPw,orderJoinPwCom,phone,phoneCetifiedComChk,birthday,gender,nickName]);

    useEffect(() => {
        if(editName !== ""
        && phoneChk(editPhone)
        && province
        && city
        && district
        && subdistrict
        && editAddrDetail !== ""){
            setBtnChkEditAddr(false);
        }else{
            setBtnChk(true);
            setBtnChkEditAddr(true);
        }
    }, [editName,editPhone,province,city,district,subdistrict,editAddrDetail]);
    
    return (
        <PageSizing addClass="bottomBtn headerSet">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="Buy Now"
                centerTitle={true}
            />
            <ProductItemBox>
                <ProductItemTitle
                    title="My Cart"
                />
                {productData && productData.map((item,i)=>(
                    <ProductItemBox addClass="multipleBox" key={i}>
                        <ProductItem
                            img={item?.thumb_image}
                            name={props.language == "en" ? item?.product_name : item?.product_name_id}
                            options={
                                type == "basic" ? 
                                item?.option_name ? `${item.option_name} / ${item.qty ? comFormat(item.qty) : 1}EA` : `${item.qty ? comFormat(item.qty) : 1}EA`
                                :
                                item?.option_name ? `${item.option_name} / ${item.cart_qty ? comFormat(item.cart_qty) : 1}EA` : `${item.cart_qty ? comFormat(item.cart_qty) : 1}EA`
                            }
                            priceClass=""
                            sale_price={null}
                            price={type == "basic" ? item?.productTotalPrict : ((item.sale_price ? Number(item.sale_price) : Number(item.price)) + (item.option_price ? Number(item.option_price) : 0)) * Number(item.cart_qty)}
                        />
                    </ProductItemBox>
                ))}
            </ProductItemBox>
            <SubLine addClass="" margin="0"/>
            <ProductItemBox>
                <ProductItemTitle
                    title="Shipping Information"
                >
                    {localStorage.getItem("token") && addrData ? 
                    <MinBtnBtnItem
                        contents="Edit"
                        func={()=>{setRightOpenType(true)}}
                    />
                    :""}
                </ProductItemTitle>
                {localStorage.getItem("token") && addrData ? 
                <OrderAddrBox>
                    <OrderAddrInfo info={`${settingName||""} ${settingPhone ? `(${settingPhone})` : ""}`}/>
                    <OrderAddrAddr addr={`${settingAddrDetail||""}${settingAddrDetail ? ", " : ""}${settingsubdistrict?.text||""}${settingsubdistrict?.text ? ", " : ""}${settingDistrict?.text||""}${settingDistrict?.text ? ", " : ""}${settingCity?.text||""}${settingCity?.text ? ", " : ""}${settingProvince?.text||""}`}/>
                </OrderAddrBox>
                :
                <div>
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Name"
                    placeholder="Enter legal name"
                    value={settingName}
                    max={null}
                    regexp={null}
                    func={(value)=>{setSettingName(value)}}
                />
                <InputItemBox
                    inputType={null}
                    addClass="inputSubCaptionItem"
                    inputName="Mobile Phone Number"
                    placeholder="6201234567890"
                    value={settingPhone}
                    max={16}
                    regexp={"number"}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    errMsg={settingPhoneErr}
                    func={(value)=>{setSettingPhone(value)}}
                ><p className="inputSubCaption_text">+</p></InputItemBox>
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName="Province"
                    placeholder="Select Province"
                    value={settingProvince ? settingProvince?.text : ""}
                    readOnly={true}
                    func={(e) => {}}
                    selItem={true}
                    clickInput={(e) => setSelPopupData({
                        title:"Select Province",
                        addClass:null,
                        closeType:true,
                        items:provinceSettingAddrText,
                        val:provinceSettingAddr,
                        sel:settingProvince ? settingProvince?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setSettingProvince({
                                id:e.val,
                                text:e.text
                            });
                        }
                    })}
                />
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName="City"
                    placeholder="Select City"
                    value={settingCity ? settingCity?.text : ""}
                    readOnly={true}
                    disabled={settingProvince && citySettingAddr.length > 0 ? false : true}
                    func={(e) => {}}
                    selItem={true}
                    clickInput={(e) => setSelPopupData({
                        title:"Select City",
                        addClass:null,
                        closeType:true,
                        items:citySettingAddrText,
                        val:citySettingAddr,
                        sel:settingCity ? settingCity?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setSettingCity({
                                id:e.val,
                                text:e.text
                            });
                        }
                    })}
                />
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName={window.languageType() === "en" ? "District" : "Kecamatan"}
                    placeholder={window.languageType() === "en" ? "Select District" : "Pilih Kecamatan"}
                    value={settingDistrict ? settingDistrict?.text : ""}
                    readOnly={true}
                    disabled={settingCity && districtSettingAddr.length > 0 ? false : true}
                    func={(e) => {}}
                    selItem={true}
                    notranslateName={true}
                    notranslateInput={true}
                    clickInput={(e) => setSelPopupData({
                        title: window.languageType() === "en" ? "Select District" : "Pilih Kecamatan",
                        notranslateTitle:true,
                        addClass:null,
                        closeType:true,
                        items:districtSettingAddrText,
                        val:districtSettingAddr,
                        sel:settingDistrict ? settingDistrict?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setSettingDistrict({
                                id:e.val,
                                text:e.text
                            });
                        }
                    })}
                />
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName={window.languageType() === "en" ? "Subdistrict" : "Kelurahan"}
                    placeholder={window.languageType() === "en" ? "Select Subdistrict" : "Pilih Kelurahan"}
                    value={settingsubdistrict ? settingsubdistrict?.text : ""}
                    readOnly={true}
                    disabled={settingDistrict && subdistrictSettingAddr.length > 0 ? false : true}
                    func={(e) => {}}
                    selItem={true}
                    notranslateName={true}
                    notranslateInput={true}
                    clickInput={(e) => setSelPopupData({
                        title: window.languageType() === "en" ? "Select Subdistrict" : "Pilih Kelurahan",
                        notranslateTitle:true,
                        addClass:null,
                        closeType:true,
                        items:subdistrictSettingAddrText,
                        val:subdistrictSettingAddr,
                        sel:settingsubdistrict ? settingsubdistrict?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setSettingSubdistrict({
                                id:e.val,
                                text:e.text
                            });

                            if(subdistrictSettingData?.filter((el)=>el.id == e.val)[0]){
                                setTariffCode(subdistrictSettingData?.filter((el)=>el.id == e.val)[0]?.tariff_code);
                            }else{
                                setTariffCode(null);
                            }
                        }
                    })}
                />
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Detailed Address"
                    placeholder="Detailed Address"
                    value={settingAddrDetail}
                    max={null}
                    regexp={null}
                    func={(value)=>{setSettingAddrDetail(value)}}
                />

                <ChkBox
                    addClass=""
                    func={(e) => setBasicAddrSet(e.target.checked)}
                    checkedType={basicAddrSet}
                    label={"Set as Default Shipping Address"}
                />
                </div>}
            </ProductItemBox>
            {localStorage.getItem("token") ? "" : 
            <div>
            <SubLine addClass="" margin="0"/>
            <ProductItemBox>
                <ProductItemTitle
                    title="Order Information"
                />
                <ChkBox
                    addClass=""
                    func={(e) => setOrderJoinType(e.target.checked)}
                    checkedType={orderJoinType}
                    label={"Sign up through shipping information"}
                />
                {
                    orderJoinType ? 
                    <div>
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="Email Address"
                            placeholder="example@email.com"
                            value={orderEmail}
                            readOnly={cetifiedInputComChk}
                            max={null}
                            regexp={null}
                            errMsg={orderEmailErr}
                            func={(value)=>{setOrderEmail(value);setCetifiedChk(false);setCetifiedComChk(false);setCetified("")}}
                            btnAddClass={emailRegExp.test(orderEmail) ? `pBg`: ""}
                            btnDisabled={!emailRegExp.test(orderEmail)}
                            clickEv={()=>{
                                if(cetifiedComChk && cetified !== ""){
                                    setCetifiedChk(false);setCetifiedComChk(false);setCetifiedInputComChk(false);setCetified("");
                                }else{
                                    cetifiedStert();setCetifiedComChk(false);setCetified("");
                                }
                            }}
                            btnName={`${cetifiedComChk && cetified !== "" ? "Change" : cetifiedChk ? "Resend" : "Verify"}`}
                            caption="Please authenticate with the email you check most frequently. Important notifications from BabyDNA, such as orders, completion of genetics tests, and adding of partner, will be sent to this email."
                        />
                        {cetifiedChk ? 
                        <InputItemBox
                            inputType={null}
                            addClass="cetifiedInput"
                            inputName="Verify"
                            placeholder=""
                            value={cetified}
                            max={6}
                            readOnly={cetifiedComChk}
                            cetified={timerCount}
                            regexp={"number"}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            func={(value)=>{setCetified(value)}}
                            btnAddClass={`${cetified.length == 6 && !cetifiedComChk ? "pBg" : ""}`}
                            btnDisabled={cetifiedComChk || cetified.length !== 6}
                            clickEv={()=>{cetifiedEnd()}}
                            btnName={cetifiedComChk ? "Verified" : "Verify"}
                            errMsg={certifyErr}
                            caption="<i>Enter Verification Code</i><br/><i>If you haven't received the email, check your spam folder.</i>"
                        />
                        :""}
                        <InputItemBox
                            inputType="password"
                            addClass=""
                            inputName="Password"
                            placeholder="********"
                            value={orderJoinPw}
                            max={20}
                            regexp={null}
                            errMsg={pwErr}
                            func={(value)=>{setOrderJoinPw(value)}}
                            caption="8 to 20 characters containing a combination of English letters, numbers, and special characters"
                        />
                        <InputItemBox
                            inputType="password"
                            addClass=""
                            inputName="Confirm Password"
                            placeholder="Re-enter Password"
                            value={orderJoinPwCom}
                            max={20}
                            regexp={null}
                            errMsg={pwComErr}
                            func={(value)=>{setOrderJoinPwCom(value)}}
                        />
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="Nickname"
                            placeholder=""
                            value={nickName}
                            max={14}
                            regexp={null}
                            func={(value)=>{setNickName(value)}}
                            caption="Enter Nickname (max 14 chars)"
                        />
                        <InputItemBox
                            inputType={null}
                            addClass="inputSubCaptionItem"
                            inputName="Mobile Phone Number"
                            placeholder="6201234567890"
                            value={phone}
                            readOnly={phoneCetifiedInputComChk}
                            max={16}
                            regexp={"number"}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            errMsg={phoneErr}
                            func={(value)=>{setPhone(value);setPhoneCetifiedChk(false);setPhoneCetifiedComChk(false);setPhoneCetified("")}}
                            btnAddClass={(phoneChk(phone)) ? `pBg`: ""}
                            btnDisabled={!(phoneChk(phone))}
                            clickEv={()=>{
                                if(phoneCetifiedComChk && phoneCetified !== ""){
                                    setPhoneCetifiedChk(false);setPhoneCetifiedComChk(false);setPhoneCetifiedInputComChk(false);setPhoneCetified("");
                                }else{
                                    phoneCetifiedStert();setPhoneCetifiedComChk(false);setPhoneCetified("");
                                }
                            }}
                            btnName={`${phoneCetifiedComChk && phoneCetified !== "" ? "Change" : phoneCetifiedChk ? "Resend" : "Verify"}`}
                        >
                            <p className="inputSubCaption_text">+</p>
                        </InputItemBox>
                        {phoneCetifiedChk ? 
                            <InputItemBox
                                inputType={null}
                                addClass="cetifiedInput"
                                inputName="Verify"
                                placeholder=""
                                value={phoneCetified}
                                max={6}
                                readOnly={phoneCetifiedComChk}
                                cetified={phoneTimerCount}
                                regexp={"number"}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                func={(value)=>{setPhoneCetified(value)}}
                                btnAddClass={`${phoneCetified.length == 6 && !phoneCetifiedComChk ? "pBg" : ""}`}
                                btnDisabled={phoneCetifiedComChk || phoneCetified.length !== 6}
                                clickEv={()=>{phoneCetifiedEnd()}}
                                btnName={phoneCetifiedComChk ? "Verified" : "Verify"}
                                errMsg={phoneCertifyErr}
                                caption="Enter Verification Code."
                            />
                        :""}
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="Date of Birth"
                            placeholder="YYYY-MM-DD"
                            value={birthday}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            birthSetting={true}
                            max={10}
                            regexp={null}
                            func={(value)=>{setBirthday(value)}}
                        />
                        <InputBoxOnly addClass="" inputName="Genetic Gender">
                            <InputTap
                                data={[
                                    {
                                        id:"M",
                                        name:"Male"
                                    },
                                    {
                                        id:"F",
                                        name:"Female"
                                    }
                                ]}
                                val={gender}
                                func={(value) => {setGender(value)}}
                            />
                        </InputBoxOnly>
                    </div>
                    :
                    <div>
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="Email Address"
                            placeholder="example@email.com"
                            value={orderEmail}
                            max={null}
                            regexp={null}
                            errMsg={orderEmailErr}
                            func={(value)=>{setOrderEmail(value)}}
                            caption="Order information will be sent to your email. Please enter it accurately."
                        />
                        <InputItemBox
                            inputType="password"
                            addClass=""
                            inputName="Order Password"
                            placeholder="********"
                            value={orderPw}
                            max={20}
                            regexp={null}
                            errMsg={orderPwErr}
                            func={(value)=>{setOrderPw(value)}}
                            caption="<i>8 to 20 characters containing a combination of English letters, numbers, and special characters</i><br/><i>Remember your order password as it is used for order inquiries!</i>"
                        />
                    </div>
                }
            </ProductItemBox>
            </div>}
            <SubLine addClass="" margin="0"/>
            <ProductItemBox addClass="orderPriceBox">
                <ProductSelPriceData name="Total Payment" val={`Rp${comFormat(totalPrice)}`} notranslate={true} addClass="total"/>
                <ProductSelPriceData name="Product Price" val={`Rp${comFormat(productPrice)}`} notranslate={true} addClass=""/>
                <ProductSelPriceData name="Shipping Fee" val={`Rp${comFormat(deliveryPrice)}`} notranslate={true} addClass=""/>
            </ProductItemBox>
            {/* 결제방법 제거로 인한 주석 */}
            {/* <ProductItemBox>
                <ProductItemTitle
                    title="Select Payment Method"
                />
                <InputTap
                    data={[
                        {
                            id:"Xendit",
                            name:"Xendit"
                        }
                    ]}
                    val={orderType}
                    func={(id) => {setOrderType(id)}}
                />
            </ProductItemBox> */}
            <p className="productOrder_announcement">* To make a purchase, you need Premium features on your E-Wallet account. We recommend using a Virtual Account.</p>
            <ProductItemBox>
                {orderJoinType ? 
                <ChkBox
                    addClass="allChkItem"
                    func={(e) => handleAllCheck(e.target.checked)}
                    checkedType={checkItems?.length === 5 ? true : false}
                    label="Agree to All"
                />
                :""}
                {chkData.map((item,i)=>(
                    <ChkBox
                        key={i}
                        addClass=""
                        func={(e) => handleSingleCheck(e.target.checked,item.chkId,item.term_title)}
                        checkedType={checkItems.includes(item.chkId) ? true : false}
                        label={item.term_title}
                        chkFunc={item.func ? (e) => props.rightOpenFunc(item.id,item.term_title) : null}
                    />
                ))}
            </ProductItemBox>
            <BtnBox
                boxType="fixed"
                addClass=""
            >
                <BtnItem
                    addClass=""
                    contents={"Buy Now"}
                    disabled={btnChk}
                    func={() => {orderStart()}}
                />
            </BtnBox>
            <RightPopup
                topTitle={"Modify Shipping Information"}
                addClass={rightOpenType ? "active bottomBtn sidePadding" : " bottomBtn sidePadding"}
                closePopup={() => {setRightOpenType(false);addrSetting()}}
                openType={rightOpenType}
            >
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Name"
                    placeholder="Enter legal name"
                    value={editName}
                    max={null}
                    regexp={null}
                    func={(value)=>{setEditName(value)}}
                />
                <InputItemBox
                    inputType={null}
                    addClass="inputSubCaptionItem"
                    inputName="Mobile Phone Number"
                    placeholder="6201234567890"
                    value={editPhone}
                    max={16}
                    regexp={"number"}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    errMsg={editPhoneErr}
                    func={(value)=>{setEditPhone(value)}}
                ><p className="inputSubCaption_text">+</p></InputItemBox>
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName="Province"
                    placeholder="Select Province"
                    value={province ? province?.text : ""}
                    readOnly={true}
                    func={(e) => {}}
                    selItem={true}
                    clickInput={(e) => setSelPopupData({
                        title:"Select Province",
                        addClass:null,
                        closeType:true,
                        items:provinceAddrText,
                        val:provinceAddr,
                        sel:province ? province?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setProvince({
                                id:e.val,
                                text:e.text
                            });
                        }
                    })}
                />
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName="City"
                    placeholder="Select City"
                    value={city ? city?.text : ""}
                    readOnly={true}
                    disabled={province && cityAddr.length > 0 ? false : true}
                    func={(e) => {}}
                    selItem={true}
                    clickInput={(e) => setSelPopupData({
                        title:"Select City",
                        addClass:null,
                        closeType:true,
                        items:cityAddrText,
                        val:cityAddr,
                        sel:city ? city?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setCity({
                                id:e.val,
                                text:e.text
                            });
                        }
                    })}
                />
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName={window.languageType() === "en" ? "District" : "Kecamatan"}
                    placeholder={window.languageType() === "en" ? "Select District" : "Pilih Kecamatan"}
                    value={district ? district?.text : ""}
                    readOnly={true}
                    disabled={city && districtAddr.length > 0 ? false : true}
                    func={(e) => {}}
                    selItem={true}
                    notranslateName={true}
                    notranslateInput={true}
                    clickInput={(e) => setSelPopupData({
                        title: window.languageType() === "en" ? "Select District" : "Pilih Kecamatan",
                        notranslateTitle:true,
                        addClass:null,
                        closeType:true,
                        items:districtAddrText,
                        val:districtAddr,
                        sel:district ? district?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setDistrict({
                                id:e.val,
                                text:e.text
                            });
                        }
                    })}
                />
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName={window.languageType() === "en" ? "Subdistrict" : "Kelurahan"}
                    placeholder={window.languageType() === "en" ? "Select Subdistrict" : "Pilih Kelurahan"}
                    value={subdistrict ? subdistrict?.text : ""}
                    readOnly={true}
                    disabled={district && subdistrictAddr.length > 0 ? false : true}
                    func={(e) => {}}
                    selItem={true}
                    notranslateName={true}
                    notranslateInput={true}
                    clickInput={(e) => setSelPopupData({
                        title: window.languageType() === "en" ? "Select Subdistrict" : "Pilih Kelurahan",
                        notranslateTitle:true,
                        addClass:null,
                        closeType:true,
                        items:subdistrictAddrText,
                        val:subdistrictAddr,
                        sel:subdistrict ? subdistrict?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setSubdistrict({
                                id:e.val,
                                text:e.text
                            });
                        }
                    })}
                />
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Detailed Address"
                    placeholder="Detailed Address"
                    value={editAddrDetail}
                    max={null}
                    regexp={null}
                    func={(value)=>{setEditAddrDetail(value)}}
                />
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"Complete"}
                        disabled={btnChkEditAddr}
                        func={() => {editAddrFunc()}}
                    />
                </BtnBox>
            </RightPopup>
            <SelPopup
                data={selPopupData}
            />
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default Order;