import { InputItemBox, TextAreaItem } from "component/basic/formItems";
import { SelPopup } from "component/basic/popup";
import { Fragment, forwardRef } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

//설문 표지
function SurveyCover(data){
    return (
        <div className={`surveyCover ${data.addClass ? data.addClass : ""}`}>
            <h1 className="surveyCover_title" dangerouslySetInnerHTML={{__html:data.title}}/>
            <p className="surveyCover_time"><img src="/assets/images/img/time_icon.svg"/><i>Estimated time: About {data.time} minutes</i></p>
            <img src="/assets/images/img/survey_img.svg"/>
            <p className="surveyCover_caption" dangerouslySetInnerHTML={{__html:data.caption}}/>
        </div>
    );
}

//설문 section
function SurveySection(data){
    return (
        <div className={`surveySection ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

//설문 페이지 타이틀
function SurveyTitleBox(data){
    return (
        <div className={`surveyTitleBox ${data.addClass ? data.addClass : ""}`}>
            <p className="surveyTitle_count notranslate">
                <span className="notranslate">{data.count}</span><i>/</i><span className="notranslate">{data.totalCount}</span>
            </p>
            <h1 className={`surveyTitle ${data.notranslateTitle ? "notranslate" : ""}`} dangerouslySetInnerHTML={{__html:data.title}}/>
            {data.caption ? <p className="surveyTitle_caption" dangerouslySetInnerHTML={{__html:data.caption}}/> : ""}
        </div>
    );
}

//설문 문항
function SurveyItem(data){
    const [selPopupData,setSelPopupData] =  useState(null);

    function dataSettingChk(type,label,value,textAreaSet,yearSelectSet,chkType,maxSel){
        let nowValue = data.data.value ? data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == label) ? data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == label)[0] && data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == label)[0].split("&&") : [] : [];
        let values = data.data.value ? data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] !== label)[0] && data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] !== label) : [];
        let settingValue = "";
        
        if(chkType){
            if(type == "chk"){
                if(yearSelectSet && textAreaSet){
                    settingValue = `${value}&&${nowValue && nowValue[1] && nowValue[1] ? nowValue[1] : ""}&&${nowValue && nowValue[2] && nowValue[2] ? nowValue[2] : ""}`;
                }else if(yearSelectSet || textAreaSet){
                    settingValue = `${value}&&${nowValue && nowValue[1] && nowValue[1] ? nowValue[1] : ""}`;
                }else{
                    settingValue = `${value}`;
                }
            }else if(type == "select"){
                if(yearSelectSet && textAreaSet){
                    settingValue = `${nowValue && nowValue[0] && nowValue[0] ? nowValue[0] : ""}&&${nowValue && nowValue[1] && nowValue[1] ? nowValue[1] : ""}&&${value}`;
                }else{
                    settingValue = `${nowValue && nowValue[0] && nowValue[0] ? nowValue[0] : ""}&&${value}`;
                }
            }else if(type == "textarea"){
                if(yearSelectSet && textAreaSet){
                    settingValue = `${nowValue && nowValue[0] && nowValue[0] ? nowValue[0] : ""}&&${value}&&${nowValue && nowValue[2] && nowValue[2] ? nowValue[2] : ""}`;
                }else{
                    settingValue = `${nowValue && nowValue[0] && nowValue[0] ? nowValue[0] : ""}&&${value}`;
                }
            }
            if(values && values.length > 0){
                values.forEach(function(item,i){
                    settingValue = maxSel == 1 ? settingValue : settingValue + `||${item}`;
                    if(values.length - 1 == i){
                        data.func(settingValue,data.data.nextChk && settingValue == "No" ? Number(data.data.pageId) + Number(data.data.nextSkip) : Number(data.data.pageId) + 1,data.data.nextSkip,data.data.prevSkip);
                    }
                });
            }else{
                data.func(settingValue,data.data.nextChk && settingValue == "No" ? Number(data.data.pageId) + Number(data.data.nextSkip) : Number(data.data.pageId) + 1,data.data.nextSkip,data.data.prevSkip);
            }
        }else{
            if(values && values.length > 0){
                values.forEach(function(item,i){
                    settingValue = settingValue || settingValue !== "" ? settingValue + `||${item}` : item;
                    if(values.length - 1 == i){
                        data.func(settingValue,data.data.nextChk && settingValue == "No" ? Number(data.data.pageId) + Number(data.data.nextSkip) : Number(data.data.pageId) + 1,data.data.nextSkip,data.data.prevSkip);
                    }
                });
            }else{
                data.func(null,data.data.nextChk && settingValue == "No" ? Number(data.data.pageId) + Number(data.data.nextSkip) : Number(data.data.pageId) + 1,data.data.nextSkip,data.data.prevSkip);
            }
        }
    }

    function nationDataSetting(type,value){
        let nowValue = data.data.value ? data.data.value.split("&&") : "";
        let settingValue = "";

        if(type == "continent"){
            settingValue = `${value}&&${nowValue && nowValue[1] ? nowValue[1] : ""}&&${nowValue && nowValue[2] ? nowValue[2] : ""}`;
        }else if(type == "country"){
            settingValue = `${nowValue && nowValue[0] ? nowValue[0] : ""}&&${value}&&${nowValue && nowValue[2] ? nowValue[2] : ""}`;
        }else if(type == "city"){
            settingValue = `${nowValue && nowValue[0] ? nowValue[0] : ""}&&${nowValue && nowValue[1] ? nowValue[1] : ""}&&${value}`;
        }
        data.func(settingValue,Number(data.data.pageId) + 1);
    }
    
    useEffect(()=>{
        let nextNumber = data.data.nextChk && data.data.value == "No" ? Number(data.data.pageId) + Number(data.data.nextSkip) : Number(data.data.pageId) + 1;

        if(data.data.type == "basic"){
            data.nextNumberFunc(Number(data.data.pageId) + 1,data.data.nextSkip,data.data.prevSkip);
        }
        if(data.data.type == "nationSel"){
            data.nextNumberFunc(Number(data.data.pageId) + 1,data.data.nextSkip,data.data.prevSkip);
        }
        if(data.data.type == "chkItem"){
            data.nextNumberFunc(nextNumber,data.data.nextSkip,data.data.prevSkip);
        }
    },[data.data]);

    return (
        <div>
            {data.data.type == "chkItem" ? 
                <SurveyChkBox
                    data={data.data}
                    language={data.language}
                    addClass=""
                    yearSetting={data.yearSetting}
                    chkFunc={(label,textAreaSet,yearSelectSet,chkType,maxSel) => {dataSettingChk("chk",label,label,textAreaSet,yearSelectSet,chkType,maxSel)}}
                    selFunc={(label,val,textAreaSet,yearSelectSet,chkType) => {dataSettingChk("select",label,val,textAreaSet,yearSelectSet,chkType)}}
                    textFunc={(label,val,textAreaSet,yearSelectSet,chkType) => {dataSettingChk("textarea",label,val,textAreaSet,yearSelectSet,chkType)}}
                />
            : ""}
            {data.data.type == "nationSel" ? 
                <div>
                    <InputItemBox
                        type="selItem"
                        addClass=""
                        inputName={"Continent"}
                        placeholder="Select Continent"
                        value={data.data.value ? data.data.value.split("&&")[0] : null}
                        readOnly={true}
                        func={(e) => {}}
                        selItem={true}
                        clickInput={(e) => setSelPopupData({
                            title:"Select Continent",
                            addClass:null,
                            closeType:true,
                            items:data.continent,
                            val:data.continent,
                            sel:data.data.value ? data.data.value.split("&&")[0] : null,
                            closeFunc:(e) => {},
                            func:(e) => {
                                nationDataSetting("continent",e.val)
                            }
                        })}
                    />
                    <InputItemBox
                        type="selItem"
                        addClass=""
                        inputName={"Country"}
                        placeholder="Select Country"
                        value={data.data.value ? data.data.value.split("&&").length >= 2 ? data.data.value.split("&&")[1] : null : null}
                        readOnly={true}
                        func={(e) => {}}
                        selItem={true}
                        clickInput={(e) => setSelPopupData({
                            title:"Select Country",
                            addClass:null,
                            closeType:true,
                            items:data.country,
                            val:data.country,
                            sel:data.data.value ? data.data.value.split("&&").length >= 2 ? data.data.value.split("&&")[1] : null : null,
                            closeFunc:(e) => {},
                            func:(e) => {
                                nationDataSetting("country",e.val)
                            }
                        })}
                    />
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="City"
                        placeholder="Enter City Name"
                        value={data.data.value ? data.data.value.split("&&").length >= 3 ? data.data.value.split("&&")[2] : null : null}
                        max={null}
                        regexp={null}
                        func={(value)=>{nationDataSetting("city",value)}}
                    />
                </div>
            :""}
            {data.data.type == "basic" ? 
                <div>
                    <InputItemBox
                        pageSetting={data.data.pageId}
                        inputType={null}
                        addClass=""
                        inputName={null}
                        notranslateInput={true}
                        placeholder={data.data.placeholder ? data.language == "en" ? data.data.placeholder : data.data.placeholder_id ? data.data.placeholder_id : data.data.placeholder : ""}
                        unit={data.data.unit}
                        value={data.data.value ? data.data.value : null}
                        max={null}
                        regexp={data.data.inputType}
                        func={(value)=>{data.func(value, Number(data.data.pageId) + 1)}}
                    />
                </div>
            :""}
            <SelPopup
                data={selPopupData}
            />
        </div>
    );
}

//설문 선택 항목
function SurveyChkBox(data){
    const [selPopupData,setSelPopupData] =  useState(null);
    const chkItem = useRef(null);

    function chkChlick(label,textAreaSet,yearSelectSet,chkType,maxSel){
        data.chkFunc(label,textAreaSet,yearSelectSet,chkType,maxSel);
    }

    function selChlick(label,val,textAreaSet,yearSelectSet,chkType){
        data.selFunc(label,val,textAreaSet,yearSelectSet,chkType);
    }

    function textChlick(label,val,textAreaSet,yearSelectSet,chkType){
        data.textFunc(label,val,textAreaSet,yearSelectSet,chkType);
    }

    return (
        <div>
        <div className={`surveyChkBox ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
            {data.data.options && data.data.options.map((item,i)=>(
                <div key={i} className="surveyChk_itemBox">
                <SurveyChkBoxItem
                    addClass=""
                    id={item.split("&&")[0]}
                    maxSelect={data.data.maxSelect}
                    maxSelectId={data.data.pageId}
                    func={(e) => {
                        if(data.data.value && data.data.maxSelect && data.data.maxSelect !== 1 && data.data.value.split("||").length >= data.data.maxSelect){
                            document.querySelectorAll(`#surveyChk_item_${item.split("&&")[0].replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim,"")}`)[0].checked = false;
                            // chkItem.current.checked = false;
                        }
                        chkChlick(item.split("&&")[0],item.split("&&").filter((el) => el == "textAreaSet").length > 0,item.split("&&").filter((el) => el == "yearSelectSet").length > 0,e.target.checked,data.data.maxSelect)
                    }}
                    checkedType={data.data.value
                        && data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0]
                        && data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0].split("&&")[0] !== "" ? true : false}
                    label={data.language == "en" ? item.split("&&")[0] : data.data.options_id[i] ? data.data.options_id[i].split("&&")[0] : item.split("&&")[0]}
                    val={item.split("&&")[0]}
                    ref={chkItem}
                />
                {item.split("&&").filter((el) => el == "textAreaSet").length > 0
                && data.data.value
                && data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0]
                && data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0].split("&&")[0] !== "" ? 
                    <TextAreaItem
                        addClass="typeLine"
                        inputName=""
                        placeholder={data.data.placeholder ? data.language == "en" ? data.data.placeholder : data.data.placeholder_id ? data.data.placeholder_id : data.data.placeholder : ""}
                        max={null}
                        maxChk={false}
                        value={data.data.value ?
                            data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0] && 
                            data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0].split("&&").length >= 2 ?
                            data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0].split("&&")[1] : null : null}
                        func={(e)=>textChlick(item.split("&&")[0],e,item.split("&&").filter((el) => el == "textAreaSet").length > 0,item.split("&&").filter((el) => el == "yearSelectSet").length > 0,document.querySelectorAll(`#surveyChk_item_${item.split("&&")[0].replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim,"")}`)[0] && document.querySelectorAll(`#surveyChk_item_${item.split("&&")[0].replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim,"")}`)[0].checked)}
                    />
                : ""}
                {item.split("&&").filter((el) => el == "yearSelectSet").length > 0
                && data.data.value
                && data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0]
                && data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0].split("&&")[0] !== "" ? 
                    <InputItemBox
                        type="selItem"
                        addClass="typeLine"
                        inputName={null}
                        placeholder="Choose the year of diagnosis"
                        value={item.split("&&").filter((el) => el == "textAreaSet").length > 0 ? 
                        data.data.value ? 
                        data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0]
                        && data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0].split("&&")[2]
                        ? data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0].split("&&")[2] : null : null
                         : 
                        data.data.value ? 
                        data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0]
                        && data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0].split("&&")[1]
                        ? data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0].split("&&")[1] : null : null}
                        readOnly={true}
                        func={(e) => {}}
                        selItem={true}
                        clickInput={(e) => setSelPopupData({
                            title:"Choose the year of diagnosis",
                            addClass:null,
                            closeType:true,
                            items:data.yearSetting,
                            val:data.yearSetting,
                            sel:item.split("&&").filter((el) => el == "textAreaSet").length > 0 ? 
                            data.data.value ? 
                            data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0]
                            && data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0].split("&&")[2]
                            ? data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0].split("&&")[2] : null : null
                             : 
                            data.data.value ? 
                            data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0]
                            && data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0].split("&&")[1]
                            ? data.data.value.split("||").filter((el) => !Array.isArray(el) && el && el.split("&&")[0] == item.split("&&")[0])[0].split("&&")[1] : null : null,
                            closeFunc:(e) => {},
                            func:(e) => {
                                selChlick(item.split("&&")[0],e.val,item.split("&&").filter((el) => el == "textAreaSet").length > 0,item.split("&&").filter((el) => el == "yearSelectSet").length > 0,document.querySelectorAll(`#surveyChk_item_${item.split("&&")[0].replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim,"")}`)[0] && document.querySelectorAll(`#surveyChk_item_${item.split("&&")[0].replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim,"")}`)[0].checked);
                            }
                        })}
                    />
                : ""}
                </div>
            ))}
        </div>
        <SelPopup
            data={selPopupData}
        />
        </div>
    );
}

//설문 체크 항목
const SurveyChkBoxItem = forwardRef((data, ref) => {
    let id = `surveyChk_item_${data.id.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gim,"")}`;

    return (
        <div className={`surveyChk_item ${data.addClass && data.addClass !== "" ? data.addClass : ""}`}>
            <input type={data.maxSelect == 1 ? "radio" : "checkbox"} name={data.maxSelect == 1 ? `surveyChk_item_${data.maxSelectId}` : ""} ref={ref} value={data.val||""} onChange={(e) => data.func(e)} className="surveyChk_itemChk" id={id} checked={data.checkedType||""} disabled={data.disabled ? data.disabled : false}/>
            <label htmlFor={id}>
                {data.children ? data.children : 
                    <p className="surveyChk_item_text notranslate" dangerouslySetInnerHTML={{__html:`${data.chkSubType ? `<span class='notranslate'>(${data.chkSubType})</span>` : ""} <i class='notranslate'>${data.label}</i>`}}></p>
                }
            </label>
        </div>
    );
})

export {SurveyCover,SurveyTitleBox,SurveySection, SurveyItem};