import { comFormat } from "js/function";

//상품 padding용
function ProductItemBox(data){
    return (
        <div className={`productItemBox ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    ); 
}

//작은 상품 정보
function ProductItem(data){
    return (
        <div className={`productItem ${data.addClass ? data.addClass : ""}`}>
            <div className="productItemImg bgImgItem" style={{backgroundImage:`url('${data.img}')`}}/>
            <div className="productItem_infoBox">
                <h2 className="productItem_name notranslate" dangerouslySetInnerHTML={{__html:data.name}}/>
                {data.options ? <p className="productItem_options" dangerouslySetInnerHTML={{__html:data.options}}/> : ""}
                {data.price ? 
                    <p className={`productItem_price notranslate ${data.priceClass ? data.priceClass : ""}`}>{data.price == "-" ? "-" : `Rp ${comFormat(data.sale_price ? data.sale_price : data.price)}`}</p>
                : ""}
                {data.sale_price ? <div className="productItem_salePrice notranslate">
                    <p className="productItem_sale">{Math.floor((1 - (Number(data.sale_price) / Number(data.price))) * 100)}%</p>
                    <p className="productItem_basicPrice">Rp {comFormat(data.price)}</p>
                </div> : ""}
            </div>
        </div>
    ); 
}

//제품 페이지 서브 타이틀
function ProductItemTitle(data){
    return (
        <div className={`productItemTitleBox ${data.addClass ? data.addClass : ""}`}>
            <div className="productItemTitleItem">
                <h1 className="productItemTitle" dangerouslySetInnerHTML={{__html:data.title}}/>
                {data.children}
            </div>
            {data.caption ? <p className="productItemTitleCaption" dangerouslySetInnerHTML={{__html:data.caption}}/> : ""}
        </div>
    ); 
}

//주문 주소 박스
function OrderAddrBox(data){
    return (
        <div className={`orderAddrBox ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    ); 
}

//주문 주소
function OrderAddrAddr(data){
    return (
        <h1 className="orderAddrAddr notranslate" dangerouslySetInnerHTML={{__html:data.addr||""}}/>
    ); 
}

//주문자 정보
function OrderAddrInfo(data){
    return (
        <h1 className="orderAddrInfo notranslate" dangerouslySetInnerHTML={{__html:data.info||""}}/>
    ); 
}

export {ProductItemBox,ProductItem,ProductItemTitle,OrderAddrBox,OrderAddrAddr,OrderAddrInfo};