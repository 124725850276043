import { ToggleBtn } from "component/basic/btns";
import { useNavigate } from "react-router-dom";

//사이드 메뉴 메뉴항목 박스
function SideMenuBox(data){
    return (
        <div onClick={()=>{if(data.func){data.func()}}} className={`sideMenuBox ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

//사이드 메뉴 메뉴항목
function SideMenuBoxItem(data){
    return (
        <div>
            <img src={data.src}/>
            <h2 className="sideMenuBoxTitle" dangerouslySetInnerHTML={{__html:data.text}}/>
        </div>
    );
}

//설정 메뉴 섹션
function MenuBox(data){
    return (
        <div className={`menuBox ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

//설정 메뉴 섹션 타이틀
function MenuTitle(data){
    return (
        <h1 className="menuBoxTitle" dangerouslySetInnerHTML={{__html:data.text}}/>
    );
}

//설정 메뉴 섹션 항목 박스
function MenuItemBox(data){
    return (
        <div className={`menuBox ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

//설정 메뉴 섹션 항목
function MenuItem(data){
    return (
        <div>
            <button type="button" onClick={()=>{data.func()}} style={{cursor : data.cursorType ? data.cursorType : "pointer"}} disabled={data.disabled ? data.disabled : false} className={`menuItem ${data.addClass ? data.addClass : ""}`}>
                <div className="menuItemNameBox">
                    <p className={`menuItemName ${data.notranslate ? "notranslate" : ""}`} dangerouslySetInnerHTML={{__html:data.menuItemName}}/>
                    {data.nameIcon ? <p className="menuItemIcon" dangerouslySetInnerHTML={{__html:data.nameIcon}}/> : ""}
                </div>
                <div className="menuItemSubItemBox">
                    {data.subItemText ? <p className={`menuSubItemText ${data.subItemTextAddClass ? data.subItemTextAddClass : ""}`} dangerouslySetInnerHTML={{__html:data.subItemText}}/> : ""}
                    {data.icon ? <img src={`/assets/images/basic/${data.icon}.svg`}/> : ""}
                    {data.toggleBtn ? 
                        <ToggleBtn
                            checkedType={data.checkedType}
                            chkFunc={(e)=>{data.chkFunc(e)}}
                            id={data.toggleId}
                        /> : ""    
                    }
                </div>
            </button>
        </div>
    );
}

export {SideMenuBox, SideMenuBoxItem, MenuBox, MenuTitle, MenuItemBox, MenuItem};