//회색 bg
function BgSection(data){
    return (
        <div>
        <div className={`bgSection ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
        <span className="bgSectionCover"></span>
        </div>
    );
}

//좌 타이틀 / 우 컨텐츠(텍스트)
function TextItem(data){
    return (
        <div className={`textItem ${data.addClass ? data.addClass : ""}`}>
            <p className="textItem_title" dangerouslySetInnerHTML={{__html:data.title}}/>
            <p className={`textItem_contents ${data.notranslate ? "notranslate" : ""}`} dangerouslySetInnerHTML={{__html:data.contents}}/>
        </div>
    );
}

//페이지 기본
function PageSection(data){
    return (
        <div className={`pageSection ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

export {BgSection,TextItem,PageSection};