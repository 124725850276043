import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ReportDataList, ReportDataListItem, ReportDataSection, ReportDetailInfoBox, ReportDetailSubTitleBox, ReportDetailtextBox } from "component/report/items";

const ReportDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;
    const barcode = param.barcode == null ? "" : param.barcode;
    
    const reportApi = useGet({
        url:`/report/detail?barcode_id=${barcode}&report_id=${id}`,
        loginType:"login"
    });
    const reportData = reportApi?.data?.report;
    const otherReportData = reportApi?.data?.other_reports;

    useEffect(() => {

    }, []);

    return (
        <PageSizing addClass="">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="View Detailed Test Results"
                centerTitle={true}
            />
            <ReportDetailInfoBox
                type={reportData?.type}
                name={props.language == "en" ? reportData?.name : reportData?.name_id}
                gradeType={reportData?.gradeType}
                nickName={reportApi?.data?.nickname}
                grade={props.language == "en" ? reportData?.grade : reportData?.grade_id}
                tag={
                    props.language == "en" ? 
                        reportData?.keyword ? reportData?.keyword.indexOf("#") !== -1 ? reportData?.keyword.split("#") : reportData?.keyword : []
                    :
                        reportData?.keyword_id ? reportData?.keyword_id.indexOf("#") !== -1 ? reportData?.keyword_id.split("#") : reportData?.keyword_id : []
                }
            />
            <div className="reportDetailSection">
                {reportData?.dna_image ? <img src={reportData?.dna_image}/> : ""}
                {props.language == "en" ? 
                    reportData?.explanation ? <ReportDetailtextBox text={reportData?.explanation}/> : ""
                :
                    reportData?.explanation_id ? <ReportDetailtextBox text={reportData?.explanation_id}/> : ""
                }
                <div className="reportDetailTextArea">
                    <ReportDetailSubTitleBox title="Interpretation of Results"/>
                    {(props.language == "en" ? reportData?.translate : reportData?.translate_id) ? <ReportDetailtextBox text={props.language == "en" ? reportData?.translate||"" : reportData?.translate_id||""}/> : ""}
                </div>
                <div className="reportDetailTextArea">
                    <ReportDetailSubTitleBox title={`<i>TMI about</i><i class='notranslate'> </i><span class='notranslate'>${props.language == "en" ? reportData?.name||"" : reportData?.name_id||""}</span>`}/>
                    {(props.language == "en" ? reportData?.tmi : reportData?.tmi_id) ? <ReportDetailtextBox text={props.language == "en" ? reportData?.tmi||"" : reportData?.tmi_id||""}/> : ""}
                </div>
            </div>
            {otherReportData && otherReportData.length > 0 ? 
            <ReportDataSection>
                <ReportDetailSubTitleBox title="Recommended Together"/>
                <ReportDataList>
                    {otherReportData && otherReportData?.map((item,i)=>(
                        <ReportDataListItem
                            key={i}
                            type={item.type}
                            gradeType={item.gradeType}
                            language={props.language}
                            func={() => {navigate(`/report/detail/${barcode}/${item.id}`)}}
                            name={item.name}
                            grade={item.grade}
                            name_id={item.name_id}
                            grade_id={item.grade_id}
                        />  
                    ))}
                </ReportDataList>
            </ReportDataSection>
            :""}
        </PageSizing>
    );
};

export default ReportDetail;