import { PageErrBox } from "component/app/pageErr";
import { BtnBox, BtnItem } from "component/basic/btns";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Private = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if(localStorage.getItem("token")){
            navigate(0);
        }
    }, []);

    return (
        <div className="pageSizing">
            <PageErrBox
                icon={true}
                title="This page requires login."
                text={null}
            />
            <BtnBox
                boxType="fixed"
                addClass="col2"
            >
                <BtnItem
                    addClass="line"
                    contents={"Cancel"}
                    disabled={false}
                    func={() => {navigate(-1)}}
                />
                <BtnItem
                    addClass=""
                    contents={"Login"}
                    disabled={false}
                    func={() => {
                        let nowUrl = new URL(window.location.href);
                        sessionStorage.setItem("setUrl",nowUrl.pathname);
                        navigate("/welCome")
                    }}
                />
            </BtnBox>
        </div>
    );
};

export default Private;