import { useNavigate } from "react-router-dom";

//회원 페이지
function MemberPage(data){
    return (
        <div className={`memberPage ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

//회원 페이지 타이틀
function MemberTitle(data){
    return (
        <div className="memberTitleBox">
            <h1 className="memberTitle" dangerouslySetInnerHTML={{__html:data.contents}}/>
        </div>
    );
}

//회원 페이지 input section
function MemberInputSection(data){
    return (
        <div className={`memberInput_section ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

//로그인 하단 링크 버튼
function LoginSubLink(data){
    let navigate = useNavigate();

    return (
        <button type="button" className="loginSubLink" onClick={() => data.func()} dangerouslySetInnerHTML={{__html:data.contents}}/>
    );
}

export {MemberPage,MemberTitle,MemberInputSection,LoginSubLink};