import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { InputItemBox } from "component/basic/formItems";
import { LoadingBox, Popup } from "component/basic/popup";
import { HeaderTap } from "component/basic/tap";
import { Header, PageSizing } from "component/elements/header";
import { MemberInputSection, MemberPage, MemberTitle } from "component/member/items";
import { phoneChk } from "js/function";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Find = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const pageType = param.type;

    const [email,setEmail] =  useState("");
    const [phone,setPhone] =  useState("");
    const [emailErr,setEmailErr] =  useState(null);
    const [phoneErr,setPhoneErr] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);

    const [btnChk,setBtnChk] =  useState("");

    const [errPopupData,setErrPopupData] =  useState(null);

    //버튼 유효성(이메일)
    useEffect(() => {
        if(email !== ""){
            let emailRegExp = /[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}/;

            if(emailRegExp.test(email)){
                setBtnChk(false);
                setEmailErr(null);
            }else{
                setBtnChk(true);
                setEmailErr("Please enter a valid email address.");
            }
        }else{
            setBtnChk(true);
        }
    }, [email]);

    //버튼 유효성(연락처)
    useEffect(() => {
        if(phone !== "" || phone){
            if(phoneChk(phone)){
                setPhoneErr(null);
                setBtnChk(false);
            }else{
                setPhoneErr(`Please enter '62' followed by your phone number, excluding the initial '0' (zero), and without "-"`);
                setBtnChk(true);
            }
        }else{
            setPhoneErr(null);
            setBtnChk(true);
        }
    }, [phone]);

    //이메일 <> 연락처 전환
    function tapCh(id){
        setBtnChk(true);

        setEmail("");
        setPhone("");
        setEmailErr(null);

        navigate(`/member/find/${id}`);
    }

    //임시 비밀번호 발급
    function pwFind(){
        setLoadingBoxOpen(true);

        let formData = new FormData();
        formData.append("type", pageType);
        if(pageType == "email"){
            formData.append("email", email);
        }else{
            formData.append("phone", `+${phone.replace(/\+/g,"")}`);
        }

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/password/find",
            success: (data) => {
                setErrPopupData({
                    addClass:null,
                    title:"We have sent a temporary password.",
                    text:`Please log in again with the temporary password.`,
                    closeType:true,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{navigate(-1)},
                    btn0:"Confirm",
                    btn0Type:"",
                    btn1:"",
                    btn1Type:"",
                    btnFunc1:()=>{}
                });
                setLoadingBoxOpen(false);
            },
            err: (data) => {
                if(data.alert){
                    setErrPopupData({
                        addClass:null,
                        title:pageType == "email" ? "Unable to find the email address." : "Unable to find the phone number.",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
                setLoadingBoxOpen(false);
            }
        })
    }

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="Password Recovery"
                centerTitle={true}
            />
            <HeaderTap
                data={[
                    {
                        id:"email",
                        category:"E-mail"
                    },
                    {
                        id:"phone",
                        category:"Mobile Phone"
                    }
                ]}
                val={pageType}
                func={(id) => {tapCh(id)}}
            />
            <MemberPage addClass="headerTapPage">
                <MemberTitle
                    contents={`<i>To receive a temporary password we need your </i><br/><span>${pageType == "email" ? "email address" : "cell phone number"}</span>`}
                />
                <MemberInputSection>
                    {pageType == "email" ? 
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="Email Address"
                            placeholder="example@gmail.com"
                            value={email}
                            max={null}
                            regexp={null}
                            errMsg={emailErr}
                            func={(value)=>{setEmail(value)}}
                        />
                    : ""}
                    {pageType == "phone" ? 
                        <InputItemBox
                            inputType={null}
                            addClass="inputSubCaptionItem"
                            inputName="Phone Number"
                            placeholder="6201234567890"
                            value={phone}
                            max={16}
                            regexp={"number"}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            errMsg={phoneErr}
                            func={(value)=>{setPhone(value)}}
                        ><p className="inputSubCaption_text">+</p></InputItemBox>
                    :""}
                </MemberInputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents="Get a Temporary Password"
                        disabled={btnChk}
                        func={() => {pwFind()}}
                    />
                </BtnBox>
            </MemberPage>
            <Popup
                data={errPopupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default Find;