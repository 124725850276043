import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { LoadingBox, Popup } from "component/basic/popup";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MypageSection } from "component/mypage/basic";
import { BtnBox, BtnItem } from "component/basic/btns";
import { InputItemBox, TextAreaItem } from "component/basic/formItems";
import { phoneChk } from "js/function";

const Bulk = (props) => {
    const navigate = useNavigate();

    const [popupData,setPopupData] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);

    const emailRegExp = /[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}/;

    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [phone,setPhone] = useState("");
    const [count,setCount] = useState("");
    const [csText,setCsText] = useState("");

    const [emailErr,setEmailErr] =  useState(null);
    const [phoneErr,setPhoneErr] =  useState(null);

    const [btnChk,setBtnChk] = useState(true);

    //대량 주문
    function csStart(){
        setLoadingBoxOpen(true);

        let formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("phone", `+${phone.replace(/\+/g,"")}`);
        formData.append("qty", count);
        formData.append("contents", csText);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: localStorage.getItem("token") ? "login" : null,
            url: "/order/big/request",
            success: (data) => {
                setLoadingBoxOpen(false);
                setPopupData({
                    addClass:null,
                    title:"Your inquiry has been submitted.",
                    text:"We will inform you through your email or mobile.",
                    closeType:false,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{navigate(-1)},
                    btn0:"Confirm",
                    btn0Type:"",
                    btn1:"",
                    btn1Type:"",
                    btnFunc1:()=>{}
                });
            },
            err: (data) => {
                setLoadingBoxOpen(false);
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:"Inquiry Submission Failed",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //이메일 유효성
    useEffect(() => {
        if(email !== ""){
            if(emailRegExp.test(email)){
                setEmailErr(null);
            }else{
                setEmailErr("Please enter a valid email address.");
            }
        }else{
            setEmailErr(null);
        }
    }, [email]);

    useEffect(() => {
        if(phone !== "" || phone){
            if(phoneChk(phone)){
                setPhoneErr(null);
            }else{
                setPhoneErr(`Please enter '62' followed by your phone number, excluding the initial '0' (zero), and without "-"`);
            }
        }else{
            setPhoneErr(null);
        }
    }, [phone]);

    //버튼 유효성
    useEffect(() => {
        if(name !== "" && emailRegExp.test(email) && phoneChk(phone) && count !== "" && csText !== ""){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }, [name,email,phone,count,csText]);

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="Bulk Purchase Inquiry"
                centerTitle={true}
            />
            <MypageSection addClass="bottomBtn">
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Contact Person"
                    placeholder=""
                    value={name}
                    max={255}
                    regexp={null}
                    func={(value)=>{setName(value)}}
                    caption="Please provide the contact person's name."
                />
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Email Address"
                    placeholder="example@email.com"
                    value={email}
                    max={255}
                    regexp={null}
                    errMsg={emailErr}
                    func={(value)=>{setEmail(value)}}
                />
                <InputItemBox
                    inputType={null}
                    addClass="inputSubCaptionItem"
                    inputName="Mobile Phone Number"
                    placeholder="6201234567890"
                    value={phone}
                    max={16}
                    regexp={"number"}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    errMsg={phoneErr}
                    func={(value)=>{setPhone(value)}}
                ><p className="inputSubCaption_text">+</p></InputItemBox>
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Desired Purchase Quantity"
                    placeholder=""
                    value={count}
                    max={10}
                    regexp={"number"}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    func={(value)=>{setCount(value)}}
                    caption="Please let us know the desired purchase quantity."
                />
                <TextAreaItem
                    addClass=""
                    inputName="Additional Inquiry"
                    placeholder="Please provide as much detail as possible."
                    max={500}
                    maxChk={true}
                    value={csText}
                    func={(value)=>setCsText(value)}
                />
            </MypageSection>
            <BtnBox
                boxType="fixed"
                addClass=""
            >
                <BtnItem
                    addClass=""
                    contents="Submit"
                    disabled={btnChk}
                    func={() => {csStart()}}
                />
            </BtnBox>
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default Bulk;