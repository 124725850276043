import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Popup, RightPopup, SelPopup, ToastBasicContents, ToastPopup } from "component/basic/popup";
import { Header, PageSizing } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BtnBox, BtnItem, MinBtnBtnItem } from "component/basic/btns";
import { OrderDetailReTitleBox, OrderListItem, TransportNumberItem } from "component/mypage/order";
import { InputItemBox } from "component/basic/formItems";
import { OrderAddrAddr, OrderAddrBox, OrderAddrInfo, ProductItemBox, ProductItemTitle } from "component/product/detail";
import { SubLine } from "component/basic/tap";
import { ProductSelPriceData } from "component/product/list";
import { comFormat, getDateGap, phoneChk, setDateTime, whatsappOpen } from "js/function";
import { Fragment } from "react";

const OrderDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const type = param.type;
    const id = param.id;

    const state = useLocation();
    const orderChkData = state?.state||null;
    const whatsappChk = state?.state?.whatsapp||null;

    const [popupData,setPopupData] =  useState(null);
    const [toastPopupData,setToastPopupData] =  useState(null);
    const [selPopupData,setSelPopupData] =  useState(null);
    const [rightOpenType,setRightOpenType] =  useState(false);

    const [editName,setEditName] = useState("");
    const [editPhone,setEditPhone] = useState("");
    const [province,setProvince] = useState(null);
    const [city,setCity] = useState(null);
    const [district,setDistrict] = useState(null);
    const [subdistrict,setSubdistrict] = useState(null);
    const [editAddrDetail,setEditAddrDetail] = useState("");

    const [phoneErr,setPhoneErr] =  useState(null);

    const [provinceAddr,setProvinceAddr] = useState([]);
    const [cityAddr,setCityAddr] = useState([]);
    const [districtAddr,setDistrictAddr] = useState([]);
    const [subdistrictAddr,setSubdistrictAddr] = useState([]);

    const [provinceAddrText,setProvinceAddrText] = useState([]);
    const [cityAddrText,setCityAddrText] = useState([]);
    const [districtAddrText,setDistrictAddrText] = useState([]);
    const [subdistrictAddrText,setSubdistrictAddrText] = useState([]);

    const [btnChkEditAddr,setBtnChkEditAddr] =  useState(true);
    
    //상세 정보(재배송 정보 없을시)
    const orderApi = useGet({
        url:`/order/detail?id=${id}${type == 0 && orderChkData?.orderNumber ? `&orderNumber=${orderChkData?.orderNumber}&password=${orderChkData?.pw}` : ""}`,
        loginType:localStorage.getItem("token") ? "login" : null
    });
    const orderData = orderApi?.data?.order_info;

    //재배송 정보(재배송 정보 있을시)
    const redeliveryApi = useGet({
        url:`/order/redelivery/detail?id=${id}`,
        loginType:localStorage.getItem("token") ? "login" : null
    });
    const redeliveryData = redeliveryApi?.data?.reorder;

    //주소 정보
    const provinceApi = useGet({
        url:`/product/delivery/address?type=province`,
        loginType:localStorage.getItem("token") ? "login" : null
    });
    const provinceData = provinceApi?.data?.types;

    const cityApi = useGet({
        url: province ? `/product/delivery/address?type=city&parent_id=${province.id}` : null,
        loginType:localStorage.getItem("token") ? "login" : null
    });
    const cityData = cityApi?.data?.types||[];

    const districtApi = useGet({
        url: city ? `/product/delivery/address?type=district&parent_id=${city.id}` : null,
        loginType:localStorage.getItem("token") ? "login" : null
    });
    const districtData = districtApi?.data?.types||[];

    const subdistrictApi = useGet({
        url: district ? `/product/delivery/address?type=subdistrict&parent_id=${district.id}` : null,
        loginType:localStorage.getItem("token") ? "login" : null
    });
    const subdistrictData = subdistrictApi?.data?.types||[];

    //장바구니 다시 담기
    function addCart(productId,optionId,qty){
        let formData = new FormData();
        formData.append("product_id[]", productId);
        formData.append("option_id[]", optionId);
        formData.append("qty[]", qty);

        if(!localStorage.getItem("token")){
            formData.append("nonmember_key", localStorage.getItem("nonmemberKey"));
        }

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: localStorage.getItem("token") ? "login" : null,
            url: "/product/cart",
            success: (data) => {
                setToastPopupData({
                    addClass:"",
                    title: "Added to Cart!",
                    basicTitle:null,
                    basicText:"Selected item has been added to your cart.",
                    closeType:true,
                    btn0:"View Cart",
                    btn0Type:"",
                    btn1:"",
                    btn1Type:"",
                    btnFunc0:(e)=>{
                        navigate("/cart");
                    },
                    btnFunc1:()=>{},
                });
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"Failed to add items to the cart.",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //주문 취소
    function orderCanCel(cancelId,allType){
        let formData = new FormData();
        if(allType){
            formData.append("order_id", id);
        }else{
            formData.append("order_product_id", cancelId);
        }
        if(!localStorage.getItem("token")){
            formData.append("order_password", orderChkData?.pw);
        }

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: localStorage.getItem("token") ? "login" : null,
            url: "/order/purchase/cancel",
            success: (data) => {
                window.location.reload(true);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //반송 확인
    function orderReceipt(receiptId,orderType){
        let formData = new FormData();

        if(orderType == "redelivery"){
            formData.append("id", receiptId);
        }else{
            formData.append("order_product_id", receiptId);
        }

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType: localStorage.getItem("token") ? "login" : null,
            url: orderType == "redelivery" ? "/order/redelivery/receive" : "/order/receive",
            success: (data) => {
                window.location.reload(true);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //배송지 변경
    function reAddrFunc(){
        let formData = new FormData();
        formData.append("id", id);
        formData.append("name", editName);
        formData.append("phone", `+${editPhone.replace(/\+/g,"")}`);
        formData.append("address_province", province?.text);
        formData.append("address_city", city?.text);
        formData.append("address_district", district?.text);
        formData.append("address_subdistrict", subdistrict?.text);
        formData.append("address_detail", editAddrDetail);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/order/address/modify`,
            loginType: localStorage.getItem("token") ? "login" : null,
            success: (data) => {
                window.location.reload(true);
            },
            err: (data) => {
                if(data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.data||"",
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"Confirm",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    //주소 세팅(회원)
    useEffect(() => {
        if(orderData){
            setEditName(orderData?.address_name);
            setEditPhone(orderData?.address_phone);
            setProvince({id:orderData?.province_id,text:orderData?.address_province});
            setEditAddrDetail(orderData?.address_detail);
            setTimeout(() => {
                setCity({id:orderData?.city_id,text:orderData?.address_city});
                setTimeout(() => {
                    setDistrict({id:orderData?.district_id,text:orderData?.address_district});
                    setTimeout(() => {
                        setSubdistrict({id:orderData?.sub_district_id,text:orderData?.address_subdistrict});
                    }, 100);
                }, 100);
            }, 100);
        }
    }, [orderData]);

    //주소 변경 이벤트
    useEffect(() => {
        let setData = [],
            setTextData = [];
        if(provinceData){
            provinceData.forEach(function(item,i){
                setData.push(item.id);
                setTextData.push(item.province);
                if(provinceData.length - 1 == i){
                    setProvinceAddr(setData);
                    setProvinceAddrText(setTextData);
                }
            });
        }else{
            setProvinceAddr(setData);
            setProvinceAddrText(setTextData);
        }
    }, [provinceData]);

    useEffect(() => {
        let setData = [],
            setTextData = [];
        if(cityData){
            cityData.forEach(function(item,i){
                setData.push(item.id);
                setTextData.push(item.city);
                if(cityData.length - 1 == i){
                    setCityAddr(setData);
                    setCityAddrText(setTextData);
                }
            });
        }else{
            setCityAddr(setData);
            setCityAddrText(setTextData);
        }
    }, [cityData]);

    useEffect(() => {
        let setData = [],
            setTextData = [];
        if(districtData){
            districtData.forEach(function(item,i){
                setData.push(item.id);
                setTextData.push(item.district);
                if(districtData.length - 1 == i){
                    setDistrictAddr(setData);
                    setDistrictAddrText(setTextData);
                }
            });
        }else{
            setDistrictAddr(setData);
            setDistrictAddrText(setTextData);
        }
    }, [districtData]);

    useEffect(() => {
        let setData = [],
            setTextData = [];
            if(subdistrictData){
                subdistrictData.forEach(function(item,i){
                    setData.push(item.id);
                    setTextData.push(item.sub_district);
                    if(subdistrictData.length - 1 == i){
                        setSubdistrictAddr(setData);
                        setSubdistrictAddrText(setTextData);
                    }
                });
            }else{
                setSubdistrictAddr(setData);
                setSubdistrictAddrText(setTextData);
            }
    }, [subdistrictData]);

    useEffect(() => {
        setCity(null);
        setDistrict(null);
        setSubdistrict(null);

        setCityAddr([]);
        setDistrictAddr([]);
        setSubdistrictAddr([]);

        setCityAddrText([]);
        setDistrictAddrText([]);
        setSubdistrictAddrText([]);
    }, [province]);

    useEffect(() => {
        setDistrict(null);
        setSubdistrict(null);

        setDistrictAddr([]);
        setSubdistrictAddr([]);

        setDistrictAddrText([]);
        setSubdistrictAddrText([]);
    }, [city]);

    useEffect(() => {
        setSubdistrict(null);

        setSubdistrictAddr([]);

        setSubdistrictAddrText([]);
    }, [district]);

    useEffect(() => {
        if(editPhone !== "" || editPhone){
            if(phoneChk(editPhone)){
                setPhoneErr(null);
            }else{
                setPhoneErr(`Please enter '62' followed by your phone number, excluding the initial '0' (zero), and without "-"`);
            }
        }else{
            setPhoneErr(null);
        }
    }, [editPhone]);

    //주소 변경 버튼 유효성
    useEffect(() => {
        if(editName !== ""
        && phoneChk(editPhone)
        && province
        && city
        && district
        && subdistrict
        && editAddrDetail !== ""){
            setBtnChkEditAddr(false);
        }else{
            setBtnChkEditAddr(true);
        }
    }, [editName,editPhone,province,city,district,subdistrict,editAddrDetail]);

    useEffect(() => {
        if(whatsappChk){
            setTimeout(() => {
                whatsappOpen();
            }, 300);
        }

        //정보 없을 시 에러페이지 노출
        if(!localStorage.getItem("token") && !orderChkData?.pw && !orderChkData?.orderNumber){
            navigate("/pageErr")
        }
    }, []);

    return (
        <PageSizing addClass={!orderData?.products?.filter((el)=>el.request_status !== null)[0] && !orderData?.products?.filter((el)=>el.is_cancel == 1)[0] && orderData?.status == 1 || redeliveryData ? `bottomBtn headerSet` : `headerSet`}>
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="Order Details"
                centerTitle={true}
            />
            <ProductItemBox>
                <div className="orderList_alignList">
                    {redeliveryData ?
                        <div>
                        <OrderDetailReTitleBox
                            icon={
                                redeliveryData?.status == 0 || redeliveryData?.status == 1 ?
                                "/assets/images/icon/reDelivery.svg"
                                : redeliveryData?.status == 2 && redeliveryData?.barcode_status == 13 ? 
                                "/assets/images/main/title_icon_barcode.svg"
                                : redeliveryData?.status == 2 && redeliveryData?.barcode_status == 14 ? 
                                "/assets/images/main/title_icon_com.svg"
                                :"/assets/images/main/title_icon_com.svg"
                            }
                            title={
                                redeliveryData?.status == 0 ?
                                "<i>The kit for re-testing is being prepared</i>"
                                : redeliveryData?.status == 1 ?
                                "<i>Shipping of the kit for re-testing has started</i>"
                                : redeliveryData?.status == 2 && redeliveryData?.barcode_status == 13 ? 
                                "<i>Please register the</i><br/><span>barcode number </span><i>of the kit for re-testing.</i>"
                                : redeliveryData?.status == 2 && redeliveryData?.barcode_status == 14 ? 
                                "<i>Have you collected your sample? </i><br/><i>Please return the retest kit.</i>"
                                : "<span>Return application</span><i> has been completed.</i>"
                            }
                            subLink={
                                redeliveryData?.status == 2 ? 
                                "Please follow the testing instructions!"
                                : false
                            }
                            subLinkUrl="https://youtu.be/jvozfwqq1Uc?feature=shared"
                        />
                        <OrderListItem
                            id={redeliveryData?.id}
                            transportNumber={redeliveryData?.transportNumber}
                            return_price={redeliveryData?.return_price}
                            addClass="orderDetailItem"
                            order_number={redeliveryData?.id}
                            date={redeliveryData?.created_at}
                            statusType={"Reshipment"}
                            caption={redeliveryData?.status == 0 ? "Preparing for Reshipment" : redeliveryData?.status == 1 ? "Reshipment in Progress" : redeliveryData?.status == 2 ? "Reshipment Completed" : null}
                            captionAddClass={redeliveryData?.status == 0 ? "yColor" : redeliveryData?.status == 1 ? "yColor" : redeliveryData?.status == 2 ? "gColor" : null}
                            language={props.language}
                            product={{
                                thumb_image:null,
                                product_name:null,
                                product_name_id:null,
                                option_name:null,
                                qty:null,
                                total_price:null                                
                            }}
                            reasonTitle={null}
                            reason={null}
                            status={redeliveryData?.status}
                            request_status={null}
                            request_type={null}
                            is_cancel={null}
                            func={()=>{}}
                            func2={()=>{}}
                            func3={()=>{}}
                        />
                        </div>
                    :
                        <div>
                        {orderData?.products && orderData?.products.length > 0 ? 
                            <div>
                            {orderData?.products.map((item,i)=>(
                                <div key={i} className="orderDetailItemBox">
                                    <OrderListItem
                                        id={orderData?.id}
                                        transportNumber={orderData?.transportNumber}
                                        return_price={orderData?.return_price}
                                        addClass="orderDetailItem"
                                        order_number={orderData?.order_number}
                                        date={orderData?.created_at}
                                        receiptDate={orderData?.receipt_at}
                                        statusType={"Order Kit"}
                                        caption={
                                            item.is_cancel == 1 ? 
                                                item.cancel_status == 0 ?
                                                "Payment Cancellation Requested"
                                                : "Payment Canceled"
                                            :
                                            item.request_status || item.request_status == 0 ? 
                                                item.request_type == "Exchange" ? 
                                                    item.request_status == 0 ? "Applied for Exchange" : item.request_status == 1 ? "Waiting for Exchange Return" : item.request_status == 2 ? "Exchange Return in Progress" : item.request_status == 3 ? "Exchange Return Completed" : item.request_status == 4 ? "Exchange in transit" : item.request_status == 5 ? "Exchange Completed" : null
                                                    : item.request_status == 0 ? "Applied for Refund" : item.request_status == 1 ? "Waiting for Refund Return" : item.request_status == 2 ? "Refund Return in Progress" : item.request_status == 3 ? item.refund_status == 2 ? "Refund in Progress" : item.refund_status == 1 ? "Refund Completed" : null : null
                                            :
                                            orderData?.status == 1 ? "Payment Complete" : orderData?.status == 2 ? "Preparing for Shipment" : orderData?.status == 3 ? "In transit" : orderData?.status == 4 ? "Delivered" : null
                                        }
                                        captionAddClass={
                                            item.is_cancel == 1 ? 
                                            "rColor"
                                            :
                                            item.request_status || item.request_status == 0 ? 
                                                item.request_type == "Exchange" ? 
                                                    item.request_status == 0 ? "rColor" : item.request_status == 1 ? "rColor" : item.request_status == 2 ? "yColor" : item.request_status == 3 ? "gColor" : item.request_status == 4 ? "yColor" : item.request_status == 5 ? "gColor" : null
                                                    : item.request_status == 0 ? "rColor" : item.request_status == 1 ? "rColor" : item.request_status == 2 ? "yColor" : item.request_status == 3 ? item.refund_status == 2 ? "yColor" : item.refund_status == 1 ? "gColor" : null : null
                                            :
                                            orderData?.status == 1 ? "gColor" : orderData?.status == 2 ? "yColor" : orderData?.status == 3 ? "yColor" : orderData?.status == 4 ? "gColor" : null
                                        }
                                        language={props.language}
                                        product={{
                                            thumb_image:item.thumb_image,
                                            product_name:item.product_name,
                                            product_name_id:item.product_name_id,
                                            option_name:item.option_name,
                                            qty:item.qty,
                                            total_price:item.total_price
                                        }}
                                        reasonTitle={
                                            item.refuse_type == "Exchange" ? 
                                                `Reason for Exchange Rejection`
                                                : `Reason for Refund Rejection`
                                        }
                                        reason={item.request_text||""}
                                        status={orderData?.status}
                                        request_status={item.request_status}
                                        request_type={item.request_type}
                                        is_cancel={item.is_cancel}
                                        cancel_status={item.cancel_status}
                                        refund_status={item.refund_status}
                                        func={()=>{
                                            if(item.is_cancel == 1){
                                                if(item.cancel_status == 1){
                                                    //"결제 취소"
                                                    addCart(item.product_id,item.option_id,item.qty);//장바구니 다시 담기
                                                }
                                            }else{
                                                if(item.request_status || item.request_status == 0){
                                                    if(item.request_type == "Exchange"){
                                                        if(item.request_status == 1){
                                                            // "Waiting for Exchange Return"
                                                            navigate(`/mypage/order/return/add/exchange/${orderData?.id}/${item.id}`,{state: {pw: orderChkData?.pw,orderNumber:orderChkData?.orderNumber}});//반송 신청
                                                        }else if(item.request_status == 5){
                                                            //교환 완료
                                                            navigate("/addBarcode");
                                                        }
                                                    }else{
                                                        if(item.request_status == 1){
                                                            // "환불 반송 대기"
                                                            navigate(`/mypage/order/return/add/return/${orderData?.id}/${item.id}`,{state: {pw: orderChkData?.pw,orderNumber:orderChkData?.orderNumber}});//반송 신청
                                                        }else if(item.request_status == 3){
                                                            if(item.refund_status == 2){
                                                                //환불 중
                                                                whatsappOpen();
                                                            }else if(item.refund_status == 1){
                                                                //환불 완료
                                                                //"Refund Completed"
                                                                addCart(item.product_id,item.option_id,item.qty);//장바구니 다시 담기
                                                            }
                                                        }
                                                    }
                                                }else{
                                                    if(orderData?.status == 1){
                                                        //"결제완료"
                                                        setPopupData({
                                                            addClass:null,
                                                            title:"Hold on! Do you want to cancel the entire order?",
                                                            text:"The refund link will be sent to your email. Please check the email you used to sign up. Email delivery may take at least 3 days. Check your spam folder if not received.",
                                                            closeType:true,
                                                            closeFunc:(e) => {},
                                                            btnFunc0:(e)=>{},
                                                            btn0:"Close",
                                                            btn0Type:"line",
                                                            btn1:"Proceed",
                                                            btn1Type:"",
                                                            btnFunc1:()=>{
                                                                orderCanCel(item.id);
                                                            }
                                                        });
                                                    }else if(orderData?.status == 2){
                                                        //"배송 준비중"
                                                        whatsappOpen();
                                                    }else if(orderData?.status == 3){
                                                        //"배송중"
                                                        whatsappOpen();
                                                    }else if(orderData?.status == 4){
                                                        //"배송완료"
                                                        if(item.request_text){
                                                            //교환,환불 반려
                                                            whatsappOpen();
                                                        }else if(getDateGap(orderData?.receipt_at,setDateTime(new Date(),"yymmddhhttss","hymd"),"day") > 7){
                                                            //교환,환불 불가
                                                            navigate("/addBarcode");//바코드 등록
                                                        }else{
                                                            //교환,환불 가능
                                                            navigate(`/mypage/order/cs/exchange/${orderData?.id}/${item.id}`);//교환 신청
                                                        }
                                                    }
                                                }
                                            }
                                        }}
                                        func2={()=>{
                                            if(item.is_cancel !== 1 && (!item.request_status && item.request_status !== 0)){
                                                if(orderData?.status == 4){
                                                    //"배송완료"
                                                    if(item.request_text){
                                                        //교환,환불 반려
                                                        navigate("/addBarcode");//바코드 등록
                                                    }else if(getDateGap(orderData?.receipt_at,setDateTime(new Date(),"yymmddhhttss","hymd"),"day") <= 7){
                                                        //교환,환불 가능
                                                        navigate(`/mypage/order/cs/return/${orderData?.id}/${item.id}`);//환불 신청
                                                    }
                                                }
                                            }
                                        }}
                                        func3={()=>{
                                            if(item.is_cancel !== 1 && (!item.request_status && item.request_status !== 0) && orderData?.status == 4 && !item.request_text && getDateGap(orderData?.receipt_at,setDateTime(new Date(),"yymmddhhttss","hymd"),"day") <= 7){
                                                navigate("/addBarcode");
                                            }
                                        }}
                                    />
                                </div>
                            ))}
                            </div>
                        :""}
                        </div>
                    }
                </div>
            </ProductItemBox>
            <SubLine addClass="" margin="0"/>
            <ProductItemBox>
                <ProductItemTitle
                    title="Shipping Information"
                >
                    {!orderData?.products?.filter((el)=>el.request_status !== null)[0] && !orderData?.products?.filter((el)=>el.is_cancel == 1)[0] && orderData?.status == 1 ? 
                        <MinBtnBtnItem
                            contents="Change Shipping Address"
                            func={()=>{setRightOpenType(true)}}
                        />
                    :""}
                </ProductItemTitle>
                <OrderAddrBox>
                    <OrderAddrInfo info={
                        redeliveryData ? 
                        `${redeliveryData?.re_delivery_name||""} ${redeliveryData?.re_delivery_phone ? `(${redeliveryData?.re_delivery_phone})` : ""}`
                        :
                        `${orderData?.address_name||""} ${orderData?.address_phone ? `(${orderData?.address_phone})` : ""}`
                    }/>
                    <OrderAddrAddr addr={
                        redeliveryData ? 
                        `${redeliveryData?.re_delivery_address_detail||""}${redeliveryData?.re_delivery_address_detail ? ", " : ""}${redeliveryData?.re_delivery_address_subdistrict||""}${redeliveryData?.re_delivery_address_subdistrict ? ", " : ""}${redeliveryData?.re_delivery_address_district||""}${redeliveryData?.re_delivery_address_district ? ", " : ""}${redeliveryData?.re_delivery_address_city||""}${redeliveryData?.re_delivery_address_city ? ", " : ""}${redeliveryData?.re_delivery_address_province||""}`
                        :
                        `${editAddrDetail||""}${editAddrDetail ? ", " : ""}${subdistrict?.text||""}${subdistrict?.text ? ", " : ""}${district?.text||""}${district?.text ? ", " : ""}${city?.text||""}${city?.text ? ", " : ""}${province?.text||""}`
                    }/>
                </OrderAddrBox>
                {redeliveryData && redeliveryData?.status !== 0 && redeliveryData?.transportNumber ? <TransportNumberItem text={`Redelivery Tracking Number : ${redeliveryData?.transportNumber}`}/> : ""}
            </ProductItemBox>
            {redeliveryData ? 
                ""
                : 
                <div>
                <SubLine addClass="" margin="0"/>
                <ProductItemBox addClass="orderPriceBox">
                    <ProductSelPriceData name="Total Payment" val={`Rp${comFormat(orderData?.total_price)}`} notranslate={true} addClass="total"/>
                    <ProductSelPriceData name="Product Price" val={`Rp${comFormat(orderData?.product_price)}`} notranslate={true} addClass=""/>
                    <ProductSelPriceData name="Shipping Fee" val={`Rp${comFormat(orderData?.delivery_price)}`} notranslate={true} addClass=""/>
                    <ProductSelPriceData name="Payment Method" val={orderData?.payment_method} addClass="" notranslate={true}/>
                </ProductItemBox>
                </div>
            }
            {orderData?.products?.filter((el)=>el.is_cancel == 1)[0] || (orderData?.products?.filter((el)=>el.request_type == "Refund" && el.request_status == 3)[0]) ?
                <div>
                    <SubLine addClass="" margin="0"/>
                    <ProductItemBox addClass="orderPriceBox">
                        <ProductItemTitle
                            title="Payment Cancellation/Refund Information"
                        />
                        <ProductSelPriceData name="Refund Amount" val={`Rp${comFormat(orderData?.return_price)}`} notranslate={true} addClass=""/>
                    </ProductItemBox>
                </div>
            :""}
            {!orderData?.products?.filter((el)=>el.request_status !== null)[0] && !orderData?.products?.filter((el)=>el.is_cancel == 1)[0] && orderData?.status == 1 || redeliveryData ? 
            <BtnBox
                boxType="fixed"
                addClass=""
            >
                {redeliveryData ? 
                    redeliveryData?.status == 1 ? 
                    ""
                    : redeliveryData?.status == 2 && redeliveryData?.barcode_status == 13 ?
                        <BtnItem
                            addClass=""
                            contents={"Register Barcode"}
                            disabled={false}
                            func={() => {navigate(`/main`,{state: {selUserId: redeliveryData?.barcode_id}})}}
                        />
                    : redeliveryData?.status == 2 && redeliveryData?.barcode_status == 14 ? 
                        <BtnItem
                            addClass=""
                            contents={"Apply for Return"}
                            disabled={false}
                            func={() => {navigate(`/return/${redeliveryData?.barcode_id}/resend/notice`)}}
                        />
                    : redeliveryData?.status == 2 && redeliveryData?.barcode_status == 15 ? 
                        <BtnItem
                            addClass=""
                            contents={"Return Application Complete"}
                            disabled={true}
                            func={() => {}}
                        />
                    : ""
                :   
                !orderData?.products?.filter((el)=>el.request_status !== null)[0] && !orderData?.products?.filter((el)=>el.is_cancel == 1)[0] && orderData?.status == 1 ? 
                    <BtnItem
                        addClass=""
                        contents={"Cancel Entire Order"}
                        disabled={false}
                        func={() => {
                            setPopupData({
                                addClass:null,
                                title:"Hold on! Do you want to cancel the entire order?",
                                text:"The refund link will be sent to your email. Please check the email you used to sign up. Email delivery may take at least 3 days. Check your spam folder if not received.",
                                closeType:true,
                                closeFunc:(e) => {},
                                btnFunc0:(e)=>{},
                                btn0:"Close",
                                btn0Type:"line",
                                btn1:"Proceed",
                                btn1Type:"",
                                btnFunc1:()=>{
                                    orderCanCel(null,true);
                                }
                            });
                        }}
                    />
                :""}
            </BtnBox>
            :""}
            <RightPopup
                topTitle={"Change Shipping Address"}
                addClass={rightOpenType ? "active bottomBtn sidePadding" : " bottomBtn sidePadding"}
                closePopup={() => {setRightOpenType(false)}}
                openType={rightOpenType}
            >
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Name"
                    placeholder="Enter legal name"
                    value={editName}
                    max={null}
                    regexp={null}
                    func={(value)=>{setEditName(value)}}
                />
                <InputItemBox
                    inputType={null}
                    addClass="inputSubCaptionItem"
                    inputName="Mobile Phone Number"
                    placeholder="6201234567890"
                    value={editPhone}
                    max={16}
                    regexp={"number"}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    errMsg={phoneErr}
                    func={(value)=>{setEditPhone(value)}}
                ><p className="inputSubCaption_text">+</p></InputItemBox>
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName="Province"
                    placeholder="Select Province"
                    value={province ? province?.text : ""}
                    readOnly={true}
                    func={(e) => {}}
                    selItem={true}
                    clickInput={(e) => setSelPopupData({
                        title:"Select Province",
                        addClass:null,
                        closeType:true,
                        items:provinceAddrText,
                        val:provinceAddr,
                        sel:province ? province?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setProvince({
                                id:e.val,
                                text:e.text
                            });
                        }
                    })}
                />
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName="City"
                    placeholder="Select City"
                    value={city ? city?.text : ""}
                    readOnly={true}
                    disabled={province && cityAddr.length > 0 ? false : true}
                    func={(e) => {}}
                    selItem={true}
                    clickInput={(e) => setSelPopupData({
                        title:"Select City",
                        addClass:null,
                        closeType:true,
                        items:cityAddrText,
                        val:cityAddr,
                        sel:city ? city?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setCity({
                                id:e.val,
                                text:e.text
                            });
                        }
                    })}
                />
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName={window.languageType() === "en" ? "District" : "Kecamatan"}
                    placeholder={window.languageType() === "en" ? "Select District" : "Pilih Kecamatan"}
                    value={district ? district?.text : ""}
                    readOnly={true}
                    disabled={city && districtAddr.length > 0 ? false : true}
                    func={(e) => {}}
                    selItem={true}
                    notranslateName={true}
                    notranslateInput={true}
                    clickInput={(e) => setSelPopupData({
                        title: window.languageType() === "en" ? "Select District" : "Pilih Kecamatan",
                        notranslateTitle:true,
                        addClass:null,
                        closeType:true,
                        items:districtAddrText,
                        val:districtAddr,
                        sel:district ? district?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setDistrict({
                                id:e.val,
                                text:e.text
                            });
                        }
                    })}
                />
                <InputItemBox
                    type="selItem"
                    addClass="type2"
                    inputName={window.languageType() === "en" ? "Subdistrict" : "Kelurahan"}
                    placeholder={window.languageType() === "en" ? "Select Subdistrict" : "Pilih Kelurahan"}
                    value={subdistrict ? subdistrict?.text : ""}
                    readOnly={true}
                    disabled={district && subdistrictAddr.length > 0 ? false : true}
                    func={(e) => {}}
                    selItem={true}
                    notranslateName={true}
                    notranslateInput={true}
                    clickInput={(e) => setSelPopupData({
                        title: window.languageType() === "en" ? "Select Subdistrict" : "Pilih Kelurahan",
                        notranslateTitle:true,
                        addClass:null,
                        closeType:true,
                        items:subdistrictAddrText,
                        val:subdistrictAddr,
                        sel:subdistrict ? subdistrict?.text : "",
                        closeFunc:(e) => {},
                        func:(e) => {
                            setSubdistrict({
                                id:e.val,
                                text:e.text
                            });
                        }
                    })}
                />
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="Detailed Address"
                    placeholder="Detailed Address"
                    value={editAddrDetail}
                    max={null}
                    regexp={null}
                    func={(value)=>{setEditAddrDetail(value)}}
                />
                <BtnBox
                    boxType="fixed"
                    addClass="btnTextAdd"
                >
                    <BtnItem
                        addClass=""
                        contents={"Complete"}
                        disabled={btnChkEditAddr}
                        func={() => {reAddrFunc()}}
                    />
                </BtnBox>
            </RightPopup>
            <ToastPopup data={toastPopupData} closeType={true}>
                <ToastBasicContents
                    title={null}
                    text={"Selected item has been added to your cart."}
                />
            </ToastPopup>
            <SelPopup
                data={selPopupData}
            />
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default OrderDetail;